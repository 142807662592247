export default (theme) => ({
	closeModal: {
		position: "absolute",
		top: 1,
		right: 1,
		color: "#b3b3b3",
	},
	dialogPaper: {
		overflow: "hidden",
	},
	dialogContent: {
		paddingBottom: 40,
		height: "500px !important",
	},
	dialogContentAngi: {
		//paddingBottom: 40,
		height: "770px !important",
	},
	dialogContentLiaison: {
		//height: "670px !important",
		//paddingBottom: 40,
	},
	dialogContent2: {
		paddingBottom: 20,
		height: "500px !important",
	},
	dialogActions: {
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		padding: 16,
		justifyContent: "center",
		flexDirection: "column",
	},
	dialogActionsBox: {
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	dialogActionsButton: {
		height: 50,
	},
	dialogTitleRoot: {
		height: 50,
	},
	progressRoot: {
		borderRadius: 5,
		height: 6,
	},
	progressBar: {
		borderRadius: 5,
	},
	progressColorPrimary: {
		borderRadius: 5,
		backgroundColor: theme.palette.grey[100],
	},
	questionHeaderBox: {
		paddingTop: 16,
		paddingBottom: 16,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		minHeight: 110,
	},
	questionHeaderBox2: {
		padding: "12px 40px 32px",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 16px 24px",
		},
	},
	questionHeader: {
		fontWeight: 700,
		fontSize: 24,
		lineHeight: "34px",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			fontSize: 22,
			lineHeight: "32px",
		},
	},
	questionHeader2: {
		fontSize: 30,
		fontWeight: 700,
		textAlign: "center",
		lineHeight: 1.3,
		[theme.breakpoints.down("xs")]: {
			fontSize: 26,
		},
	},
	questionSubHeader: {
		marginTop: 16,
		lineHeight: 1.6,
		textAlign: "center",
		color: theme.palette.grey[700],
	},
	questionSubHeader2: {
		marginTop: 16,
		fontSize: 18,
		lineHeight: 1.6,
		textAlign: "center",
		color: theme.palette.grey[700],
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
	formControl: {
		padding: "6px 24px",
		width: "100%",
	},
	formControl2: {
		"width": "100%",
		"padding": 8,
		"border": "1.5px solid rgb(234 234 234 / 80%)",
		"borderRadius": 8,
		"marginBottom": 8,
		"marginLeft": "unset",
		"marginRight": "unset",
		"&:hover": {
			backgroundColor: "#f7fbfd",
			border: "1.5px solid #0e6be6 !important",
		},
		[theme.breakpoints.down("xs")]: {
			padding: 6,
			marginBottom: 8,
		},
	},
	formControlLabel: {
		width: "100%",
	},
	checked: {
		"border": "1.5px solid #0e6be6 !important",
		"& .MuiFormControlLabel-label": {
			fontWeight: 600,
		},
	},
	inputTextField: {
		width: "100%",
		paddingTop: 4,
		paddingBottom: 4,
	},
	inputBorder: {
		borderBottom: "1px solid rgb(238, 238, 238)",
	},
	inputBorder2: {
		width: 380,
		margin: "0 auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	widthAuto: {
		width: 380,
		margin: "0 auto",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	width: {
		width: 380,
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	height100: {
		height: "100%",
	},
	overflowShow: {
		overflow: "visible !important",
	},
	introAvatar: {
		width: 80,
		height: 80,
	},
	summaryAvatar: {
		width: 64,
		height: 64,
	},
	flexCenter: {
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			alignItems: "center",
		},
	},
	bizName: {
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": 2,
		"wordBreak": "break-word",
	},
	badFitFlex: {
		display: "flex",
		alignItems: "flex-start",
	},
	badFitAvatar: {
		width: 48,
		height: 48,
	},
	bizDetailsBox: {
		marginLeft: 12,
		marginRight: 12,
		flexGrow: 1,
	},
	bizDetailsName: {
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": 2,
		"wordBreak": "break-word",
		"fontWeight": 700,
		"overflow": "hidden",
		"display": "-webkit-box",
	},
	bizDetailsRatingBox: {
		display: "flex",
		alignItems: "center",
		marginBottom: 4,
	},
	bizDetailsRating: {
		marginRight: 4,
		marginTop: 2,
		color: "#ff6474",
		fontSize: 14,
		fontWeight: 700,
	},
	bizDetailsRatingCount: {
		color: theme.palette.grey[600],
		fontSize: 14,
		marginLeft: 4,
	},
	bizDetailsChips: {
		[theme.breakpoints.down("xs")]: {
			marginBottom: 8,
		},
	},
	bizDetailsPrice: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		textAlign: "right",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	bizDetailsPriceMobile: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			alignItems: "center",
			textAlign: "left",
		},
	},
	bizDetailsPriceAmount: {
		fontWeight: 600,
	},
	bizDetailsPriceType: {
		paddingLeft: 4,
		fontSize: 14,
	},
	bizDetailsPriceDesc: {
		color: theme.palette.grey[600],
		fontSize: 12,
		lineHeight: 1.5,
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 8,
		},
	},
	bizDetailsPriceIcon: {
		fontSize: 24,
		[theme.breakpoints.down("xs")]: {
			fontSize: 20,
		},
	},
	badFitTitle: {
		marginBottom: "unset",
		fontWeight: 700,
	},
	moreProsListItem: {
		cursor: "pointer",
		paddingBottom: 0,
	},
	moreProsListItemBorder: {
		border: "2px solid rgb(224, 224, 224)",
		borderRadius: 4,
		marginBottom: 8,
	},
	moreProsListItemIcon: {
		minWidth: 32,
	},
	cardChipRoot: {
		color: "#043256",
		backgroundColor: "#c9e4ff",
		fontSize: 12,
		fontWeight: 700,
		cursor: "pointer",
		marginRight: 4,
	},
	cardChipIcon: {
		color: "#043256",
	},
	avatarGroup: {
		marginLeft: -16,
	},
	selectMoreAvatar: {
		width: 64,
		height: 64,
	},
	doneAvatar: {
		width: 80,
		height: 80,
	},
	proFacts6: {
		marginTop: 4,
		marginBottom: 4,
	},
	proFactsIcon: {
		marginRight: 4,
	},
	proFactsBox: {
		display: "flex",
		alignItems: "center",
		//color: theme.palette.grey[600],
		fontSize: 14,
	},
	proFactsText: {
		marginLeft: 4,
	},
	proFactsTextB: {
		fontWeight: 600,
	},
});
