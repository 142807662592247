import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";

// MUI
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

// Config

import { observer } from "mobx-react";

import { constants } from "@components/Main/Shared/CoursesAndGuides/.config";
import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";
const ProfileLinks = withConstants(constants)(
	observer(() => {
		const classes = useStyles("Profile");
		const CGViewModel = useConstants("CoursesAndGuidesViewModel");
		const { profileLinksStore: store } = useStores();

		if (!CGViewModel.mapping[store.service?.name]) return null;
		const items = (
			CGViewModel.mapping[store.service?.name].relations || []
		).map((relation) => CGViewModel.services[relation]);

		return (
			<Box
				component="section"
				pb={12}
				pt={10}
				pl={{ xs: 4, sm: 10 }}
				pr={{ xs: 4, sm: 10 }}
				bgcolor="grey.50"
				borderTop={"1px solid rgb(231 231 231)"}
			>
				<Container maxWidth="lg" disableGutters>
					<Grid container spacing={4}>
						{store.service?.active && store.nearby?.length > 0 && (
							<Grid item xs={12} sm={3}>
								<Box fontSize={18} component="h3" fontWeight={600} mb={8}>
									In other nearby areas
								</Box>

								<Grid container spacing={3}>
									{store.nearby.map((el, index) => (
										<Grid item xs={12} key={index}>
											<Link
												color={"secondary"}
												underline="hover"
												href={`${el.url}${store.service.url}/`}
												className={classes.footerLink}
											>
												{`${el?.name.ucFirst()} ${store.service?.phrase.ucFirst()}`}{" "}
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}

						{store.city?.active && (
							<Grid item xs={12} sm={3}>
								<Box fontSize={18} component="h3" fontWeight={600} mb={8}>
									Popular in {store.city?.name.ucFirst()}
								</Box>
								<Grid container spacing={3}>
									{store.popular.map((el, index) => (
										<Grid item xs={12} key={index}>
											<Link
												color={"secondary"}
												underline="hover"
												href={`${store.city.url}${el.url}/`}
												className={classes.footerLink}
											>
												{el.phrase + " " + store.city?.name.ucFirst()}
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}

						{store.related.length > 0 && (
							<Grid item xs={12} sm={3}>
								<Box fontSize={18} component="h3" fontWeight={600} mb={8}>
									You might also like
								</Box>

								<Grid container spacing={3}>
									{store.related.map((el, index) => (
										<Grid item xs={12} key={index}>
											<Link
												color={"secondary"}
												underline="hover"
												href={`${el.url}`}
												className={classes.footerLink}
											>
												{el.phrase} near me
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}

						<Grid item xs={12} sm={3}>
							<Box fontSize={18} component="h3" fontWeight={600} mb={8}>
								Related cost guides
							</Box>

							<Grid container spacing={3}>
								{items.length &&
									items.slice(0, 8).map((item, index) => (
										<Grid item xs={12} key={index}>
											<Link
												color={"secondary"}
												underline="hover"
												href={item.link}
												className={classes.footerLink}
											>
												{item.title.ucFirst()}
											</Link>
										</Grid>
									))}
							</Grid>

							{/* <Grid container spacing={3}>
							{Object.values(CGViewModel.services).map((service, index) => (
								<Grid item xs={12} key={index}>
									<Link
										color={"secondary"}
										underline="hover"
										href={service.link}
										className={classes.footerLink}
									>
										{service.title.ucFirst()}
									</Link>
								</Grid>
							))}
						</Grid> */}
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	})
);

ProfileLinks.propTypes = {};
export default ProfileLinks;
