import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "@hooks/useStyles";
import CheckIcon from "@material-ui/icons/Check";

const ProfileInsurance = () => {
	const classes = useStyles("Profile");

	return (
		<Box mb={6}>
			<Paper>
				<Box p={6}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pb={4}
					>
						<Box
							component={"div"}
							fontWeight={700}
							className={classes.profileHeader}
						>
							Accepted insurance
						</Box>

						<Button
							className={classes.profileEditButton}
							to={"/pros/profile/edit-media"}
							size={"large"}
							color="primary"
						>
							Edit
						</Button>
					</Box>

					<Box mb={4}>
						<Box mb={6}>
							Let patients know which insurances and payment methods you accept.
						</Box>
						<Button
							disableElevation
							to={"/pros/profile/edit-media"}
							size={"large"}
							color="primary"
							variant={"contained"}
						>
							Add
						</Button>
					</Box>

					<Box my={2}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										Self-pay
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										Aetna
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										Blue Cross Blue Shield
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										Medicare
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										TRICARE
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<CheckIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										United Healthcare
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

ProfileInsurance.propTypes = {};

export default ProfileInsurance;
