import gql from "graphql-tag";
import { leadFragment, quoteFragment } from "./fragments";

export const quoteByProviderTokenQueryFragment = gql`
	fragment quoteByProviderTokenQueryFragment on Quote {
		${quoteFragment}
	}
`;

export const quoteByClientTokenQueryFragment = gql`
	fragment quoteByClientTokenQueryFragment on Quote {
		${quoteFragment}
	}
`;

export const quoteByProviderTokenQueryFragmentWithLead = gql`
	fragment quoteByProviderTokenQueryFragment on Quote {
		${quoteFragment}
		lead {
			${leadFragment}
		}
	}
`;

export const quoteByClientTokenQueryFragmentWithLead = gql`
	fragment quoteByClientTokenQueryFragment on Quote {
		${quoteFragment}
		lead {
			${leadFragment}
		}
	}
`;

export const leadByTokenQueryFragment = gql`
	fragment leadByTokenQueryFragment on Lead {
		${leadFragment}
	}
`;

export const leadByTokenQueryFragmentWithQuotes = gql`
	fragment leadByTokenQueryFragment on Lead {
		${leadFragment}
		quotes(filter: $quotesFilter) {
			${quoteFragment}
		}
	}
`;
