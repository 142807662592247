import React from "react";
import PropTypes from "prop-types";
import useStores from "@hooks/useStores";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import useStyles from "@hooks/useStyles";
import Divider from "@material-ui/core/Divider";

import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { adjective as proAdjective } from "@language/provider";
import { observer } from "mobx-react-lite";
import ReviewItem from "../../../../Global/Custom/ReviewItem";
import ReviewsMetrics from "../../Shared/ReviewsMetrics";
import StarIcon from "@material-ui/icons/Star";

const { SITE_NAME, BRAND_NAME } = process.env;

const ProfileReviews = observer(({ reviews }) => {
	const classes = useStyles("Profile");
	const stores = useStores();

	const { profileReviewsStore: store } = stores;

	const openDialog = () => {
		stores.reviewsDialogStore.showModal = true;
	};

	const updateReview = (_id, toUpdate) => {
		store.updateReview(_id, toUpdate);
	};

	return (
		<React.Fragment>
			<Paper>
				<Box mb={6} overflow={"hidden"}>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							pb={4}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Reviews
							</Box>
							{store.provider.metrics.reviews_count > 0 && (
								<Button
									className={classes.profileEditButton}
									href={"/pros/reviews"}
									size={"large"}
									color="primary"
								>
									View all
								</Button>
							)}
						</Box>

						<ReviewsMetrics {...store.provider.metrics} />

						<Box pt={5} pb={5}>
							<Divider light={true} />
						</Box>

						{store.provider.metrics.reviews_count > 0 && (
							<React.Fragment>
								<Box mb={4}>
									{store.reviews.map((review, index) => (
										<React.Fragment key={review._id}>
											<ReviewItem
												review={review}
												displayReplyName={false}
												onSubmitResponse={updateReview}
											/>
											{index !== store.reviews.length - 1 && (
												<Box pt={6} pb={6}>
													<Divider light={true} />
												</Box>
											)}
										</React.Fragment>
									))}
								</Box>

								<Button href={"/pros/reviews"} size={"large"} color="primary">
									Read all reviews
								</Button>

								<Box pt={5} pb={5}>
									<Divider light={true} />
								</Box>
							</React.Fragment>
						)}

						<Box
							display={"flex"}
							alignItems={"center"}
							flexWrap={{ xs: "wrap", sm: "nowrap" }}
						>
							<Box
								flex={"1 1 auto"}
								mr={{ xs: 0, sm: 6 }}
								mb={{ xs: 4, sm: 0 }}
							>
								<Box display={"flex"} alignItems={"flex-start"}>
									<Box
										mr={2}
										minWidth={40}
										display={"flex"}
										alignItems={"center"}
									>
										<StarIcon style={{ color: "#fcd53f", fontSize: 36 }} />
									</Box>
									<div>
										<Box fontWeight={700} mb={2}>
											{`Get reviews from past customers, even if they're not from ${BRAND_NAME}.`}
										</Box>
										{/* <Box fontSize={14} color={"grey.700"} lineHeight={1.5}>
											Let us know which customers to ask for a review, and we'll
											send the request.
										</Box> */}
									</div>
								</Box>
							</Box>

							<Box
								width={{ xs: "100%", sm: "auto" }}
								display={"flex"}
								alignItems={"center"}
								height={"100%"}
								flexShrink={0}
								ml={{ xs: 12 }}
							>
								<Button
									disableElevation
									size={"large"}
									color="primary"
									variant={"contained"}
									onClick={openDialog}
								>
									Ask for reviews
								</Button>
							</Box>
						</Box>

						<Box pt={4} pb={4}>
							<Divider light={true} />
						</Box>

						<Box display={"flex"} alignItems={"center"}>
							<Box mr={2} minWidth={40} display={"flex"} alignItems={"center"}>
								<TrendingUpIcon style={{ fontSize: 36 }} />
							</Box>
							<Box fontSize={14} color={"grey.700"}>
								Other {proAdjective.plural} in your market have{" "}
								<strong>14 reviews</strong> on average
							</Box>
						</Box>
					</Box>
				</Box>
			</Paper>
		</React.Fragment>
	);
});

ProfileReviews.propTypes = {
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			rating: PropTypes.number,
			created_at: PropTypes.string,
			message: PropTypes.shape({
				body: PropTypes.string,
				author: PropTypes.string,
				header: PropTypes.string,
			}),
		})
	),
};

export default ProfileReviews;
