import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "@hooks/useStyles";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { sanitize as sanitizeHTML } from "dompurify";

const FAQs = ({ faqs }) =>
	faqs.map(({ question, answer }, index, allFaqs) => {
		return (
			<React.Fragment key={shortid.generate()}>
				<Box fontWeight={600} mb={3} fontSize={17}>
					{question}
				</Box>
				<Box fontSize={15} color={"grey.700"} lineHeight={1.5}>
					<span
						dangerouslySetInnerHTML={{
							__html: answer ? sanitizeHTML(answer) : "Not answered",
						}}
					/>
				</Box>
				{index + 1 !== allFaqs.length && (
					<Box mt={4} mb={4}>
						<Divider light />
					</Box>
				)}
			</React.Fragment>
		);
	});

const ProfileFAQ = ({ qa }) => {
	const classes = useStyles("Profile");

	return (
		<Box mb={6}>
			<Paper>
				<Box p={6}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pb={4}
					>
						<Box
							component={"div"}
							fontWeight={700}
							className={classes.profileHeader}
						>
							Frequently asked questions
						</Box>
						<Button
							component={Link}
							className={classes.profileEditButton}
							to={"/pros/profile/edit-faq"}
							size={"large"}
							color="primary"
						>
							Edit
						</Button>
					</Box>
					<div>
						<FAQs faqs={qa} />
					</div>
				</Box>
			</Paper>
		</Box>
	);
};

ProfileFAQ.propTypes = {
	questions: PropTypes.arrayOf(PropTypes.string),
	qa: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string,
			answer: PropTypes.string,
		})
	),
};

export default ProfileFAQ;
