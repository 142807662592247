import React, { useEffect } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import shortid from "shortid";

import useStores from "@hooks/useStores";

import GreenCheckMark from "@src/styles/SVG/GreenCheckMark";
import DropDownArrow from "@src/styles/SVG/DropDownArrow";

const ProfileTravelPreferences = observer(({ proServiceConfigurations }) => {
	const { profileTravelPreferencesStore: store } = useStores();
	const proServiceConfigurationOnChangeHandler = (e) =>
		store.updateServiceConfigurationId(e.target.value);

	useEffect(() => {
		store.init(proServiceConfigurations);
	}, []);

	return (
		<div className="white-card pad24">
			<div className="flex align-center justify-between mb16">
				<div className="font-20 b8">Location</div>
				<div className="flex cursor b8">
					<a
						href="/business-hours"
						className=""
						data-toggle="modal"
						data-target="#basicInfo"
					>
						Edit
					</a>
				</div>
			</div>
			<div className="mb24 hero-box-drop width-100 bg-white">
				<select
					onChange={proServiceConfigurationOnChangeHandler}
					value={store.currentServiceConfigurationId}
					className="hero-box-dropdown width-100"
					name="service-select"
				>
					{store.serviceConfigurations.map((config) => (
						<option key={shortid.generate()} value={config._id}>
							{config.name.ucFirst()}
						</option>
					))}
				</select>
				<DropDownArrow
					className="hero-box-dropdown-icon"
					height={18}
					width={18}
				/>
			</div>
			{Object.keys(store.currentTravelPreferences).map((questionId) => (
				<div key={shortid.generate()} className="mb16">
					<div className="font-16 b8 mb16">
						{store.currentTravelPreferences[questionId].question.text}
					</div>
					{store.currentTravelPreferences[questionId].answers.map((answer) => (
						<div key={shortid.generate()} className="flex flex-start mb8">
							<div className="mr12">
								<GreenCheckMark width="14px" height="14px" />
							</div>
							<div className="flex-auto">
								<div className="flex align-center">
									<div className="font-14 b8">{answer.value}</div>
								</div>
							</div>
						</div>
					))}
					<div>
						<a href="#" className="font-16 b8">
							Show more
						</a>
					</div>
				</div>
			))}
			{Object.keys(store.currentTravelPreferences).length === 0 && (
				<p align={"center"}>
					No travel preferences available.
					<br />
					Select another service.
				</p>
			)}
		</div>
	);
});

ProfileTravelPreferences.propTypes = {
	proServiceConfigurations: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			travelPreferences: PropTypes.arrayOf(
				PropTypes.shape({
					question: PropTypes.shape({
						_id: PropTypes.string,
						text: PropTypes.string,
					}),
					answer: PropTypes.shape({
						value: PropTypes.string,
					}),
				})
			).isRequired,
		})
	).isRequired,
};

export default ProfileTravelPreferences;
