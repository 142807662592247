import { action, computed, observable, runInAction } from "mobx";

import rootService from "../@data/service";

const { addLeadPicks } = rootService;

class SendProjectStore {
	@observable leadKey;
	@observable providerId;
	@observable onDone = () => {};
	@observable show = false;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action
	onClose() {
		this.show = false;
		this.leadKey = null;
		this.providerId = null;
		this.onDone = () => {};
	}

	async sendProject() {
		try {
			await addLeadPicks({
				leadKey: this.leadKey,
				providers: [this.providerId],
			});
			if (this.onDone.then) {
				await this.onDone();
				return;
			}
			this.onDone();
		} catch (err) {
			throw err;
		}
	}

	@action
	init({ leadKey, providerId, onDone } = {}) {
		this.leadKey = leadKey;
		this.providerId = providerId;
		this.onDone = onDone || (() => {});
		this.show = true;
	}
}

export default SendProjectStore;
