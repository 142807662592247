import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const ProfileIntro = ({ intro }) => {
	const classes = useStyles("Profile");

	return (
		<Box mb={6}>
			<Paper>
				<Box p={6}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pb={4}
					>
						<Box
							component={"div"}
							fontWeight={700}
							className={classes.profileHeader}
						>
							Your introduction
						</Box>
						<Button
							component={Link}
							className={classes.profileEditButton}
							to={"/pros/profile/edit-intro"}
							size={"large"}
							color="primary"
						>
							Edit
						</Button>
					</Box>
					<Box
						display={"-webkit-box"}
						fontSize={{ xs: 14, sm: 16 }}
						lineHeight={1.5}
						whiteSpace={"pre-wrap"}
						overflow={"hidden"}
						className={classes.profileIntro}
					>
						{intro
							.split("\n")
							.filter((el) => el !== "")
							.map((el, index) => (
								<p key={index}>{el}</p>
							))}
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

ProfileIntro.propTypes = {
	intro: PropTypes.string,
};

export default ProfileIntro;
