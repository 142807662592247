import gql from "graphql-tag";

export const updateQuoteByProviderTokenMutationFragment = gql`
	fragment updateQuoteByProviderTokenMutationFragment on UpdateOneQuotePayload {
		error {
			message
		}
	}
`;
export const updateQuoteByClientTokenMutationFragment = gql`
	fragment updateQuoteByClientTokenMutationFragment on UpdateOneQuotePayload {
		error {
			message
		}
	}
`;
