import React, { useEffect } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import {
	Divider,
	Box,
	Button,
	Paper,
	ButtonBase,
	Link,
	Grid,
} from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ProfileLocationHoursDialog from "./ProfileLocationHoursDialog";
import useStores from "@hooks/useStores";
import { observer } from "mobx-react";
import useError from "@hooks/useError";

const ProfileLocationHours = observer(() => {
	const classes = useStyles("Profile");
	const { profileLocationHoursStore: store, profileLocationHoursDialogStore } =
		useStores();
	const [ErrorTemplate, onError] = useError({ _id: "SEOProfile" });
	const handleClickOpen = () => {
		profileLocationHoursDialogStore.show = true;
	};

	const handleLinkClick = (type) => (e) => {
		window.analytics.track(`Client - Profile Interacted`, {
			provider_id: store.provider._id,
			location: "location_hours",
			type: type,
		});
	};

	useEffect(() => {
		store.init().catch(onError);
	}, []);

	return (
		<React.Fragment>
			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<a className="anchor" id="location" />
			<Box mt={6} component="section">
				<h2 className={classes.profileH2}>Location and hours</h2>

				<Box mt={6} mb={8}>
					<Grid container spacing={8}>
						<Grid item xs={12} sm={7}>
							<Paper variant="outlined">
								{store.base64Map && (
									<a href={`${store.directionsMapUrl}`} target="_blank">
										<Box id="map" borderRadius={6} overflow={"hidden"}>
											<img
												src={`data:image/png;base64, ${store.base64Map}`}
												width={"100%"}
											/>
										</Box>
									</a>
								)}
								<Box
									display={"flex"}
									flexDirection={{ xs: "column", sm: "row" }}
									justifyContent={"space-between"}
									p={4}
								>
									{store.proLocation && (
										<div>
											<Box
												component={"h3"}
												fontWeight={600}
												fontSize={16}
												m={0}
												pb={2}
											>
												{store.provider.company_name?.ucFirst()}
											</Box>
											<div className={classes.profileBodyText}>
												{store.proLocation.address}
											</div>
											{store.proLocation.address2 && (
												<div className={classes.profileBodyText}>
													{store.proLocation.address2}
												</div>
											)}
											<div className={classes.profileBodyText}>
												{store.proLocation.city?.ucFirst()},{" "}
												{store.proLocation.state?.toUpperCase()}{" "}
												{store.proLocation.zip}
											</div>
										</div>
									)}
									<Box display={"flex"} justifyContent={"flex-end"} ml={2}>
										<div>
											<Button
												variant="outlined"
												color="secondary"
												href={`${store.directionsMapUrl}`}
												target="_blank"
												onClick={handleLinkClick("get_directions")}
												//size="large"
											>
												Get directions
											</Button>
										</div>
									</Box>
								</Box>

								{/* IF MORE LOCATIONS ADD DIVIDER BETWEEN EACH AND SHOW THIS COMMENTED INFO*/}

								{/* <Divider light={true} />

						<Box
							display={"flex"}
							flexDirection={{ xs: "column", sm: "row" }}
							justifyContent={"space-between"}
							p={4}
						>
							<div>
								<Box
									component={"h3"}
									fontWeight={600}
									fontSize={16}
									m={0}
									pb={2}
								>
									Next Level Urgent Care
								</Box>
								<div className={classes.profileBodyText}>3221 Red River St</div>
								<div className={classes.profileBodyText}>Austin, TX 78705</div>
							</div>
							<Box display={"flex"} justifyContent={"flex-end"}>
								<div>
									<Button
										variant="outlined"
										color="secondary"
										href="https://www.google.com/maps?f=d&ll=30.2915874,-97.7263518&view=map&q=Next+Level+Urgent+Care&daddr=3221+Red+River+St,+Austin,+TX+78705&address=3221+Red+River+St,+Austin,+TX+78705"
										target="_blank"
										size="large"
									>
										Get directions
									</Button>
								</div>
							</Box>
						</Box>

						<Box
							display="flex"
							alignItems="center"
							justifyContent={"center"}
							pb={4}
						>
							<Link
								color="secondary"
								className={classes.showMore}
								onClick={handleClick}
							>
								<Box display="flex" alignItems="center">
									Show {open ? "less" : "more"}{" "}
									{open ? (
										<ExpandLessIcon fontSize="small" />
									) : (
										<ExpandMoreIcon fontSize="small" />
									)}
								</Box>
							</Link>
						</Box> */}
							</Paper>
						</Grid>
						<Grid item xs={12} sm={5}>
							<Box display={"flex"} justifyContent={"space-between"}>
								<Box display={"flex"} flexDirection={"column"} width={110}>
									{profileLocationHoursDialogStore.dayList.map((day, index) => (
										<div
											key={`day-${index}`}
											className={classes.hoursDialogDay}
										>
											{index === 0 ? <strong>{day}</strong> : day}
										</div>
									))}
								</Box>
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									flexGrow={1}
								>
									<Box display={"flex"} flexDirection={"column"} width={175}>
										{profileLocationHoursDialogStore.dayListHours.map(
											(hourRange, index) => (
												<div
													key={`hours-${index}`}
													className={classes.hoursDialogTime}
												>
													{index === 0 ? (
														<strong>
															<div
																dangerouslySetInnerHTML={{ __html: hourRange }}
															/>
														</strong>
													) : (
														<div
															dangerouslySetInnerHTML={{ __html: hourRange }}
														/>
													)}
												</div>
											)
										)}
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>

				{/* <Box mb={8}>
					<Paper variant="outlined">
						<ButtonBase
							className={classes.hoursButton}
							onClick={handleClickOpen}
						>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={"space-between"}
								p={4}
								width={"100%"}
							>
								<Box
									display={"flex"}
									flexDirection={"column"}
									justifyContent={"flex-start"}
								>
									<Box
										component={"h3"}
										fontWeight={600}
										fontSize={16}
										m={0}
										pb={1}
										textAlign={"left"}
									>
										Business hours
									</Box>
									<div className={classes.profileBodyText}>
										{store.isOpen24Hours
											? "Open 24 hours"
											: store.isOpen
											? `Open - Closes ${store.todayHours?.stopStr}`
											: store.isOpenSoon
											? `Closed - Opens ${store.todayHours?.startStr} Today`
											: store.nextOpenDate
											? `Closed - Opens ${store.nextOpenDate?.startStr} ${store.nextOpenDate.dayStr}`
											: "Closed"}
									</div>
								</Box>

								<ChevronRightIcon fontSize="small" />
							</Box>
						</ButtonBase>
					</Paper>
				</Box> */}
			</Box>
			<ErrorTemplate />
			<ProfileLocationHoursDialog />
		</React.Fragment>
	);
});

ProfileLocationHours.propTypes = {};

export default ProfileLocationHours;
