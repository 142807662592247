import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import { useTheme } from "@material-ui/core/styles";
import useStores from "@hooks/useStores";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// MUI
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

// Components
import ProfilePhotosDialog from "./Modals/ProfilePhotosDialog";
import LazyLoad from "@custom/LazyLoad";

const ProfilePhotos = () => {
	const classes = useStyles("Profile");
	const theme = useTheme();
	const {
		profilePhotosStore: store,
		profilePhotosDialogStore,
		imageDialogStore,
	} = useStores();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));

	const viewAllPhotos = () => {
		profilePhotosDialogStore.show = true;
	};

	const viewPhoto = (selectedIndex) => (e) => {
		imageDialogStore.init(undefined, store.media, selectedIndex);
	};

	return (
		<React.Fragment>
			<a className="anchor" id="photos" />

			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<h2 className={classes.profileH2}>Photos and videos</h2>
			<Box pb={8} pt={4}>
				<Box borderRadius={8} overflow="hidden">
					<ImageList
						rowHeight={mobile ? 200 : 240}
						component={"div"}
						cols={3}
						gap={8}
					>
						{!!store.media[0] && (
							<ImageListItem
								rows={mobile ? 1 : 1}
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[0].media_type === "video"
												? store.media[0].thumbnail
												: store.media[0].value
										}
										alt={store.media[0].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(0)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
						{!!store.media[1] && (
							<ImageListItem
								rows={mobile ? 1 : 1}
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[1].media_type === "video"
												? store.media[1].thumbnail
												: store.media[1].value
										}
										alt={store.media[1].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(1)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
						{!!store.media[2] && (
							<ImageListItem
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[2].media_type === "video"
												? store.media[2].thumbnail
												: store.media[2].value
										}
										alt={store.media[2].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(2)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
						{!!store.media[3] && !mobile && (
							<ImageListItem
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[3].media_type === "video"
												? store.media[3].thumbnail
												: store.media[3].value
										}
										alt={store.media[3].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(3)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
						{!!store.media[4] && !mobile && (
							<ImageListItem
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[4].media_type === "video"
												? store.media[4].thumbnail
												: store.media[4].value
										}
										alt={store.media[4].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(4)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
						{!!store.media[5] && !mobile && (
							<ImageListItem
								cols={mobile ? 3 : 1}
								className={classes.photoListItem}
							>
								<LazyLoad>
									<img
										src={
											store.media[4].media_type === "video"
												? store.media[4].thumbnail
												: store.media[4].value
										}
										alt={store.media[4].caption}
										className={classes.photoDialogPic}
										onClick={viewPhoto(4)}
										loading="lazy"
										decoding="async"
										fetchpriority="auto"
									/>
								</LazyLoad>
							</ImageListItem>
						)}
					</ImageList>
				</Box>

				{!!store.media && store.media.length > 5 && (
					<Box mt={8}>
						<Button
							variant="outlined"
							color="secondary"
							size="large"
							className={classes.showAllButton}
							onClick={viewAllPhotos}
						>
							Show all photos
						</Button>

						<ProfilePhotosDialog />
					</Box>
				)}
			</Box>

			{/* <div className="pro-details-block pro-profile">
                <div className="block-images">
                    <div className="my-gallery" itemScope itemType="https://schema.org/ImageGallery">
                        {provider.images.map(picture => (

                            <figure itemProp="associatedMedia"
                                    itemScope
                                    itemType="https://schema.org/ImageObject"
                                    key={shortid.generate()}>

                                <a href={picture.url}
                                   itemProp="contentUrl"
                                   data-size={picture.dimensions || '1024x1024'}
                                   className="clearfix">

                                    <img src="//static.fash.com/assets/images/bg/thumbs/default-s.png"
                                         data-src={picture.url}
                                         itemProp="thumbnail"
                                         className="b-lazy"
                                         alt={picture.caption || ''}/>
                                </a>

                                {picture.caption && <figcaption itemProp="caption description"
                                                                className="hidden">{picture.caption}</figcaption>}
                            </figure>
                        ))}
                    </div>
                </div>
            </div> */}
		</React.Fragment>
	);
};

ProfilePhotos.propTypes = {};

export default ProfilePhotos;
