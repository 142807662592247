import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useConstants from "@hooks/useConstants";

// MUI
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import ScheduleIcon from "@material-ui/icons/Schedule";

// Components
import ProfileBadges from "./ProfileBadges";
import ReviewAvgRating from "@components/Dashboard/Pro/Shared/ReviewsMetrics/ReviewAvgRating";

// Config
const { BRAND_NAME, SITE_NAME } = process.env;

const ProfileBusinessInfo = ({ placeHolderImage }) => {
	const classes = useStyles("Profile");
	const { profileStore, profileReviewsStore, profileLocationHoursStore } =
		useStores();
	const { provider } = profileStore;

	const { showProfileLocationHours } = useConstants("ProfileViewModel");

	return (
		<React.Fragment>
			<Box mb={{ xs: 0, sm: 6 }} display="flex" component="section">
				<Box mr={4}>
					<Avatar
						variant={"rounded"}
						className={classes.profilePhotoImg}
						alt={provider.company_name.ucFirst()}
						title={provider.company_name.ucFirst()}
						src={provider.image.value}
						imgProps={{
							fetchpriority: "high",
						}}
					/>
				</Box>

				<Box display="flex" flexDirection="column" justifyContent="flex-end">
					<Box
						component="h1"
						className={classes.profileH1}
						display={"-webkit-box"}
						overflow={"hidden"}
					>
						{provider.company_name.ucFirst()}
					</Box>
					{/* <Box display={{xs: 'none', sm: 'block'}} mt={1} className={classes.profileLocation}
                     color="grey.600">{provider.location.city.ucFirst()}, {provider.location.state.toUpperCase()}</Box> */}
					{!!provider.metrics.reviews_count && (
						<a href="#reviews">
							<Box
								display="flex"
								alignItems="center"
								mt={0.5}
								flexWrap={"wrap"}
							>
								<Box
									component="span"
									mr={1}
									mt={0.5}
									color="#ff6474"
									fontWeight={800}
								>
									<ReviewAvgRating
										rating={profileReviewsStore.provider?.metrics.reviews_avg}
									/>
								</Box>
								<Rating
									value={parseFloat(profileReviewsStore.avgRating)}
									precision={0.5}
									readOnly
									size="small"
								/>

								{profileReviewsStore.reviewsCount > 4 && (
									<React.Fragment>
										{profileReviewsStore.avgRating >= 4.9 && (
											<span className={classes.proReviewsStatus}>
												Exceptional
											</span>
										)}

										{profileReviewsStore.avgRating >= 4.7 &&
											profileReviewsStore.avgRating < 4.9 && (
												<span className={classes.proReviewsStatus}>
													Excellent
												</span>
											)}

										{profileReviewsStore.avgRating > 4.5 &&
											profileReviewsStore.avgRating < 4.7 && (
												<span className={classes.proReviewsStatus}>
													Awesome
												</span>
											)}
									</React.Fragment>
								)}

								<Box
									component="span"
									ml={1}
									fontSize={14}
									fontWeight={600}
									color="text.secondary"
									style={{ textDecoration: "underline" }}
								>
									{profileReviewsStore.reviewsCount === 1 ? (
										<>{`(${profileReviewsStore.reviewsCount} review)`}</>
									) : (
										<>{`(${profileReviewsStore.reviewsCount} reviews)`}</>
									)}
								</Box>
							</Box>
						</a>
					)}

					{showProfileLocationHours && (
						<Box display={"flex"} alignItems={"center"} mt={2}>
							<ScheduleIcon />
							<Box ml={2}>
								{profileLocationHoursStore.isOpen24Hours ? (
									<span style={{ color: "#188038", fontWeight: 600 }}>
										Open 24 hours
									</span>
								) : profileLocationHoursStore.isOpen ? (
									<React.Fragment>
										<span style={{ color: "#188038", fontWeight: 600 }}>
											Open
										</span>{" "}
										• Closes {profileLocationHoursStore.todayHours.stopStr}
									</React.Fragment>
								) : profileLocationHoursStore.isOpenSoon ? (
									<React.Fragment>
										<span style={{ color: "#b06000", fontWeight: 600 }}>
											Opens soon
										</span>{" "}
										• {profileLocationHoursStore.todayHours.startStr}
									</React.Fragment>
								) : (
									profileLocationHoursStore.nextOpenDate && (
										<React.Fragment>
											<span style={{ color: "#d93025", fontWeight: 600 }}>
												Closed
											</span>{" "}
											• Opens {profileLocationHoursStore.nextOpenDate.startStr}{" "}
											{profileLocationHoursStore.nextOpenDate.dayStr}
										</React.Fragment>
									)
								)}
							</Box>
						</Box>
					)}

					<Box display={{ xs: "none", sm: "flex" }}>
						<ProfileBadges />
					</Box>
				</Box>
			</Box>

			<Box
				display={{ xs: "flex", sm: "none" }}
				justifyContent="flex-start"
				flexWrap="wrap"
				mt={1}
				mb={6}
			>
				<ProfileBadges />
			</Box>
		</React.Fragment>
	);
};

ProfileBusinessInfo.defaultProps = {
	placeholderImage: `//static.${SITE_NAME}.com/assets/images/tmp/no_pic.png`,
};

ProfileBusinessInfo.propTypes = {
	placeholderImage: PropTypes.string,
	provider: PropTypes.shape({
		metrics: PropTypes.shape({
			reviews_avg: PropTypes.number.isRequired,
			reviews_count: PropTypes.number.isRequired,
		}),
		company_name: PropTypes.string.isRequired,
		image: PropTypes.shape({
			value: PropTypes.string,
		}).isRequired,
		verified: PropTypes.shape({
			badge: PropTypes.bool.isRequired,
		}).isRequired,
	}).isRequired,
};

export default ProfileBusinessInfo;
