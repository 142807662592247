import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import shortid from "shortid";
import PropTypes from "prop-types";

import useStores from "@hooks/useStores";

import Select from "@components/Global/Custom/Select";

import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import LinkMaterial from "@material-ui/core/Link";
import useStyles from "@hooks/useStyles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";

const ProfileServiceDetails = observer(({ proServiceConfigurations }) => {
	const classes = useStyles("Profile");
	const { profileServiceDetailsStore: store } = useStores();
	const proServiceConfigurationOnChangeHandler = (e) =>
		store.updateServiceConfigurationId(e.target.value);

	const [open, setOpen] = useState(false);
	const handleClick = () => setOpen(!open);

	useEffect(() => {
		store.init(proServiceConfigurations);
	}, []);

	return (
		<>
			<Box mb={6}>
				<Paper>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							pb={4}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Service settings
							</Box>
							{Object.keys(store.currentJobPreferences).length > 0 && (
								<Button
									component={Link}
									className={classes.profileEditButton}
									to={"/pros/services"}
									size={"large"}
									color="primary"
								>
									Edit
								</Button>
							)}
						</Box>

						<Select
							onChange={proServiceConfigurationOnChangeHandler}
							value={store.currentServiceConfigurationId}
							name="service-select"
						>
							{store.serviceConfigurations.map((config) => (
								<option key={shortid.generate()} value={config._id}>
									{config.name.ucFirst()}
								</option>
							))}
						</Select>

						<Box mt={8}>
							{Object.keys(store.currentJobPreferences).length > 0 && (
								<Collapse in={open} collapsedSize={200} timeout="auto">
									{Object.keys(store.currentJobPreferences).map(
										(questionId) => (
											<div
												key={shortid.generate()}
												className={classes.profileServiceSettingsBox}
											>
												<div className={classes.profileServiceSettingsTitle}>
													{
														store.currentJobPreferences[questionId].question
															.text
													}
												</div>
												{store.currentJobPreferences[questionId].answers.map(
													(answer) => (
														<div
															key={shortid.generate()}
															className={classes.profileServiceSettingsItem}
														>
															<CheckIcon
																className={classes.profileServiceSettingsCheck}
																fontSize="small"
															/>

															<div
																className={
																	classes.profileServiceSettingsItemAnswer
																}
															>
																{answer.value}
															</div>
														</div>
													)
												)}
											</div>
										)
									)}
								</Collapse>
							)}
							{Object.keys(store.currentJobPreferences).length > 0 && (
								<Box display="flex" alignItems="center" mt={6}>
									<LinkMaterial
										color="primary"
										className={classes.showMore}
										onClick={handleClick}
									>
										<Box display="flex" alignItems="center">
											Show {open ? "less" : "more"}{" "}
											{open ? (
												<ExpandLessIcon fontSize="small" />
											) : (
												<ExpandMoreIcon fontSize="small" />
											)}
										</Box>
									</LinkMaterial>
								</Box>
							)}
							{Object.keys(store.currentJobPreferences).length === 0 && (
								<Box mb={4}>
									<Box mb={6}>
										Get the right customers by adding your availability, preferences, and work area.
									</Box>
									<Button
										component={Link}
										disableElevation
										to={"/pros/services"}
										size={"large"}
										color="primary"
										variant={"contained"}
									>
										Add settings
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				</Paper>
			</Box>
		</>
	);
});

ProfileServiceDetails.propTypes = {
	proServiceConfigurations: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			jobPreferences: PropTypes.arrayOf(
				PropTypes.shape({
					question: PropTypes.shape({
						_id: PropTypes.string,
						text: PropTypes.string,
					}),
					answer: PropTypes.shape({
						value: PropTypes.string,
					}),
				})
			).isRequired,
		})
	).isRequired,
};

export default ProfileServiceDetails;
