import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

import useStores from "@hooks/useStores";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import useStyles from "@hooks/useStyles";
import Grid from "@material-ui/core/Grid";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

const { SITE_NAME } = process.env;

const ProfileBusinessHours = ({
	timezone,
	days,
	preBooking,
	advancedBooking,
}) => {
	const classes = useStyles("Profile");
	const { profileBusinessHoursStore: store } = useStores();

	return (
		<React.Fragment>
			<Paper>
				<Box mb={6} overflow={"hidden"}>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Business hours
							</Box>
							<Button
								// component={RouterLink}
								className={classes.profileEditButton}
								//to={"/pro/business-hours?isServiceSetup=true"}
								size={"large"}
								color="primary"
							>
								<a href="/pro/business-hours?isServiceSetup=true">Edit</a>
							</Button>
						</Box>
						<Box mb={6} fontSize={14} color={"grey.700"}>
							{`Time zone: ${timezone}`}
						</Box>
						<Box mb={4}>
							<Grid container spacing={4}>
								{Object.keys(days).map((day) => (
									<React.Fragment key={shortid.generate()}>
										<Grid item xs={6} sm={4}>
											<Box
												display={"flex"}
												alignItems={"center"}
												height={"100%"}
											>
												{day.ucFirst()}
											</Box>
										</Grid>

										<Grid item xs={6} sm={8}>
											<Box
												display={"flex"}
												alignItems={"center"}
												height={"100%"}
											>
												{days[day].active
													? store.getTimeRangeString(days[day])
													: "Closed"}
											</Box>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
						</Box>

						{/* 					<Box mt={6}>
							<Alert severity="info">
								Not available on a specific date or time?{" "}
								<Box ml={2} component={"span"} fontWeight={600}>
									<Link href={"/pros/calendar"}>Block your calendar</Link>
								</Box>
							</Alert>
						</Box> */}

						{["homeguide", "fash", "tutors", "lessons", "thervo"].includes(
							SITE_NAME
						) && (
							<>
								<Box mt={5} mb={5}>
									<Divider light />
								</Box>

								<div>
									<Box
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
										mb={4}
									>
										<Box display={"flex"} alignItems={"flex-start"}>
											<NotificationsOutlinedIcon fontSize={"small"} />
											<Box ml={2}>
												<Box fontWeight={700} mb={1}>
													Notice needed before doing a job:
												</Box>
												<div>
													{preBooking.value} {preBooking.unit}
												</div>
											</Box>
										</Box>
									</Box>

									<Box
										display={"flex"}
										alignItems={"center"}
										justifyContent={"space-between"}
									>
										<Box display={"flex"} alignItems={"flex-start"}>
											<ScheduleOutlinedIcon fontSize={"small"} />
											<Box ml={2}>
												<Box fontWeight={700} mb={1}>
													Customers can book you in advance up to:
												</Box>
												<div>
													{advancedBooking.value} {advancedBooking.unit}
												</div>
											</Box>
										</Box>
									</Box>
								</div>
							</>
						)}
					</Box>
				</Box>
			</Paper>
		</React.Fragment>
	);
};

ProfileBusinessHours.propTypes = {
	timezone: PropTypes.string.isRequired,
	advancedBooking: PropTypes.shape({
		unit: PropTypes.oneOf(["days", "weeks", "months"]).isRequired,
		value: PropTypes.number.isRequired,
	}),
	preBooking: PropTypes.shape({
		unit: PropTypes.oneOf(["days", "weeks", "months"]).isRequired,
		value: PropTypes.number.isRequired,
	}),
	days: PropTypes.shape({
		monday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		tuesday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		wednesday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		thursday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		friday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		saturday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
		sunday: PropTypes.shape({
			active: PropTypes.bool.isRequired,
			start: PropTypes.number.isRequired,
			stop: PropTypes.number.isRequired,
		}),
	}),
};

export default ProfileBusinessHours;
