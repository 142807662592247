import React from "react";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

// MUI Icons
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined";

// Components
import ProfileLeadPanelPrices from "../ProfileLeadPanelPrices";

const OneClickBlastActionPanel = ({ onSuccess }) => {
	const classes = useStyles("ProfileActionPanel");
	const stores = useStores();
	const { profileActionsPanelStore: store, sendProjectStore } = stores;

	// const sendProject = async () => {
	// 	await store.sendProject();
	// 	sendProjectStore.onClose();
	// 	onSuccess({ message: "Project sent successfully" });
	// };

	const openSendProjectDialog = (e) => {
		e.preventDefault();
		stores.sendProjectStore.init({
			leadKey: store.leadKey,
			providerId: store.provider._id,
			onDone: async () => {
				try {
					await store.reload();
				} catch (e) {}
			},
		});
	};

	return (
		<aside className={`${classes.leadPanelBox}`}>
			<ProfileLeadPanelPrices />

			<Box display={"flex"} alignItems={"center"}></Box>

			<Box py={4}>
				<Divider light={true} />
			</Box>

			<Box display={"flex"} alignItems={"flex-start"}>
				<Box mr={2}>
					<OfflineBoltOutlinedIcon className={classes.iconGreen} />
				</Box>
				<div>
					<Box fontWeight={600}>
						Send your saved request details with a single click.
					</Box>
				</div>
			</Box>

			<Box pt={6}>
				<Button
					onClick={openSendProjectDialog}
					fullWidth
					disableElevation
					variant="contained"
					color="primary"
					size="large"
					className={classes.buttonCTA}
				>
					Send request
				</Button>
			</Box>
		</aside>
	);
};

export default OneClickBlastActionPanel;
