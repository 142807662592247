import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@components/Global/Custom/Autocomplete";
import TextField from "@components/Global/Custom/TextField";
import Select from "@components/Global/Custom/Select";

import { Link } from "@material-ui/core";

const { LINK_HELP } = process.env;

const ProfileCredentialsDialog = ({ close, open }) => {
	const classes = useStyles("Profile");

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Dialog
			scroll="paper"
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={true}
			//onClose={close}
			aria-labelledby="editorial integrity"
			aria-describedby="editorial integrity"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={close}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box mb={8} mx={8}>
					<Box pb={8}>
						<Box fontWeight={700} lineHeight={1.4} fontSize={22} mb={2}>
							Add a license, certification, or accreditation
						</Box>

						<Box lineHeight={1.6} color={"grey.600"}>
							We check every credential through the states public licensing
							database or certification association. Valid credentials will be
							displayed on your profile.{" "}
							<Link href={LINK_HELP} target="_blank">
								Contact us
							</Link>{" "}
							for assistance uploading your credentials.
						</Box>
					</Box>

					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Select
							//error={store.selectServiceHasError}
							//value={store.selectedServiceKey}
							//onChange={selectServiceOnChangeHandler}
							>
								<option value={""}>Choose a type</option>
								<option value={"license"}>License</option>
								<option value={"certification"}>Certification</option>
								<option value={"accreditation"}>Accreditation</option>
							</Select>
						</Grid>

						<Grid item xs={12}>
							<Box component="div" mb={2} fontWeight={700}>
								State
							</Box>
							<Autocomplete
								//onSelect={serviceOnSelectHandler}
								//searchOptions={{ active: true, midtail: false }}
								id="service"
								autoHighlight
								autoSelect
								//value={urlService}
								TextFieldProps={{
									groupId: "desktop",
									InputProps: {
										classes: {
											adornedStart: classes.autocompleteAdornedStart,
											input: classes.autocompleteInputRoot,
										},
									},
									inputProps: {
										"autoCorrect": "off",
										"spellCheck": "false",
										"aria-label": "Enter service",
									},
									//placeholder: serviceInputPlaceholder,
									variant: "outlined",
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box component="div" mb={2} fontWeight={700}>
								Credential
							</Box>
							<Autocomplete
								//onSelect={serviceOnSelectHandler}
								//searchOptions={{ active: true, midtail: false }}
								id="service"
								autoHighlight
								autoSelect
								//value={urlService}
								TextFieldProps={{
									groupId: "desktop",
									InputProps: {
										classes: {
											adornedStart: classes.autocompleteAdornedStart,
											input: classes.autocompleteInputRoot,
										},
									},
									inputProps: {
										"autoCorrect": "off",
										"spellCheck": "false",
										"aria-label": "Enter service",
									},
									//placeholder: serviceInputPlaceholder,
									variant: "outlined",
								}}
							/>
						</Grid>
						{/* IF TYPE = LICENSE SHOW THIS */}
						<Grid item xs={12}>
							<Box component="div" mb={2} fontWeight={700}>
								License number
							</Box>
							<TextField
								//inputRef={metaTitleRef}
								//label="Title"
								//defaultValue={store.service?.metatags.title}
								variant="outlined"
								required={false}
								fullWidth
							/>
						</Grid>
						{/* END SHOW THIS */}
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions style={{ padding: "24px", marginRight: "32px" }}>
				<Box mr={2}>
					<Button size={"large"} color={"secondary"} disableElevation>
						Cancel
					</Button>
				</Box>

				<Button
					disableElevation
					size={"large"}
					variant={"contained"}
					color="primary"
					type={"submit"}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ProfileCredentialsDialog.propTypes = {};
export default ProfileCredentialsDialog;
