import React, { useState } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";

const ProfileServicePreferences = ({ questionsAndAnswers }) => {
	const classes = useStyles("Profile");

	return (
		<React.Fragment>
			<>
				<a className="anchor" id="details" />

				<div className={classes.profileDivider}>
					<Divider light={true} />
				</div>

				<h2 className={classes.profileH2}>Specialties</h2>

				<Box my={6}>
					{questionsAndAnswers.map(({ description, answers }) => (
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Box component="h3" className={classes.profileH3} mt={0}>
									{description}
								</Box>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<div className={classes.profileCredential}>
											<CheckIcon
												className={`${classes.iconSuccess} ${classes.profileCredentialIcon}`}
											/>
											<div
												className={`${classes.profileBodyText} ${classes.profileDetailsText}`}
											>
												{answers.map(({ value }) => value).join(", ")}
											</div>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Box>
			</>
		</React.Fragment>
	);
};

ProfileServicePreferences.propTypes = {};

export default ProfileServicePreferences;
