import rootService from "../@data/service";
import { observable, action, runInAction } from "mobx";
import {
	quoteByProviderTokenQueryFragmentWithLead,
	quoteByClientTokenQueryFragmentWithLead,
	leadByTokenQueryFragmentWithQuotes,
} from "../@data/query";

const {
	getQuoteByProviderToken,
	getLeadByToken,
	getQuoteByClientToken,
	updateQuoteByClientToken,
	updateQuoteByProviderToken,
} = rootService;

class LeadDetailsStore {
	@observable quote;
	@observable lead;

	quoteKey;
	leadKey;

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get accountStore() {
		const { accountStore } = this.rootStore.stores;
		return accountStore;
	}

	get isClient() {
		return this.accountStore.isClient;
	}

	get isProvider() {
		return this.accountStore.isProvider;
	}

	@action
	async init({ quoteKey, leadKey } = {}) {
		this.quoteKey = quoteKey || this.quoteKey;
		this.leadKey = leadKey || this.leadKey;
		try {
			if (this.quoteKey && this.leadKey) {
				const lead = await getLeadByToken(
					{
						filter: { key: this.leadKey },
						quotesFilter: {
							key: this.quoteKey,
						},
					},
					{ batch: true, fragment: leadByTokenQueryFragmentWithQuotes }
				);

				runInAction(() => {
					this.lead = lead;
					this.quote = lead.quotes[0];
				});
				return;
			} else if (this.leadKey) {
				const lead = await getLeadByToken(
					{
						filter: { key: this.leadKey },
					},
					{ batch: true }
				);
				const quote = await this.getQuoteByToken({
					filter: { lead: lead._id },
				});

				this.quoteKey = quote?.key;
				runInAction(() => {
					this.lead = lead;
					this.quote = quote;
				});
			} else if (this.quoteKey) {
				const fragment = this.isClient
					? quoteByClientTokenQueryFragmentWithLead
					: quoteByProviderTokenQueryFragmentWithLead;
				const quote = await this.getQuoteByToken(
					{ filter: { key: this.quoteKey } },
					{ batch: true, fragment }
				);

				this.leadKey = quote.lead.key;
				runInAction(() => {
					this.quote = quote;
					this.lead = quote.lead;
				});
			}
		} catch (err) {
			throw err;
		}
	}

	async getQuoteByToken({ filter } = {}, options = { batch: true }) {
		try {
			const fn = this.isClient
				? getQuoteByClientToken
				: getQuoteByProviderToken;
			const quote = await fn({ filter }, options);
			return quote;
		} catch (err) {
			throw err;
		}
	}

	@action
	async updateQuotePhoneClicked() {
		try {
			if (!this.quote) return;

			const fn = this.isClient
				? updateQuoteByClientToken
				: updateQuoteByProviderToken;

			const updateField = this.isClient
				? "phone_clicked_by_client"
				: "phone_clicked_by_pro";

			await fn(
				{
					filter: { key: this.quote.key },
					record: {
						[updateField]: true,
					},
				},
				{ batch: false }
			);
		} catch (err) {
			throw err;
		}
	}

	get isTargetingService() {
		if (!this.lead) return true;
		if (!this.quote) return true;
		if (!this.quote.provider) return true;
		return !!this.quote.provider.services.find(
			(service) => this.name === service.name
		);
	}

	get location() {
		if (!this.lead) return;
		return `${this.lead.location.city}, ${this.lead.location.state} ${this.lead.location.zip}`;
	}

	get base64Map() {
		if (!this.lead) return;
		return this.lead.boundary_base64_w420_h140;
	}

	get phone() {
		return this.quote?.client_phone;
	}

	get leadAnswers() {
		if (!this.lead) return [];
		return this.lead.answers.map(
			({ question: questionId, answers: answerIds, ...leadAnswer }) => {
				if (!answerIds) answerIds = [];
				if (!this.quote) {
					return Object.assign({}, leadAnswer, {
						amounts: new Array(answerIds.length),
						question: questionId,
						answers: answerIds,
					});
				}
				const costs = this.quote.cost.filter(
					(cost) => cost.question.parent_questionMongoID === questionId
				);
				const amounts = answerIds.map(
					(answerId) =>
						(
							costs.find(
								(cost) => cost.answer.parent_answerMongoID === answerId
							) || {}
						).amount
				);
				return Object.assign({}, leadAnswer, {
					amounts,
					question: questionId,
					answers: answerIds,
				});
			}
		);
	}
	get preferredStartDates() {
		if (!this.lead) return;
		const answer = this.lead.answers.find(
			({ description }) => description.toLowerCase() === "preferred start date"
		);
		if (!answer) return;
		return answer.values.join(", ");
	}

	get timesAvailable() {
		if (!this.lead) return;
		const answer = this.lead.answers.find(
			({ description }) => description.toLowerCase() === "times available"
		);
		if (!answer) return;
		return answer.values.join(", ");
	}

	get paid() {
		if (!this.quote) return;
		return this.quote.paid;
	}

	get isDirect() {
		return this.quote?.type === "direct";
	}

	get isAccepted() {
		return this.quote?.accepted;
	}

	get isPaid() {
		return this.quote?.paid.value;
	}

	/**
	 * Phone
	 */
	get clientPhone() {
		return this.quote?.client_phone;
	}

	get phoneClicked() {
		if (this.isProvider) return this.quote?.phone_clicked_by_pro;
		if (this.isClient) return this.quote?.phone_clicked_by_client;
	}

	get showPhone() {
		if (!this.clientPhone) return false;
		if (!this.isDirect && !this.isPaid) {
			return false;
		}
		return true;
	}

	get showPhoneMask() {
		if (this.phoneClicked) return false;
		return true;
	}

	get phoneMask() {
		if (this.isDirect && !this.isAccepted)
			return { value: this.clientPhone, link: false };
		return { value: "Click to show phone number", link: true };
	}

	/**
	 * Sensitive answer
	 */

	get showSensitiveValue() {
		if (this.isClient) return true;
		if (this.isDirect && (!this.isAccepted || !this.isPaid)) return false;
		if (!this.isDirect && !this.isPaid) {
			return false;
		}
		return true;
	}

	/**
	 * Price
	 */

	get final_price() {
		if (!this.quote) return;
		let { unit, total } = this.quote.final_price;

		let totalStr = "";
		let unitStr = "";

		if (total) totalStr = "$" + total;

		if (unit) unitStr = unit;

		return (totalStr + " " + unitStr).trim();
	}
}

export default LeadDetailsStore;
