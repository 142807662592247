import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Box from "@material-ui/core/Box";

import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

const ProfilePhotosFeatured = ({}) => {
	const classes = useStyles("Profile");
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<React.Fragment>
			<Box py={4}>
				<Box borderRadius={8} overflow="hidden">
					<ImageList rowHeight={192} cols={4} gap={8} component={"div"}>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/b7a4dab9-dd9c-4ccc-9bb9-b030f12ff012.jpeg?im_w=1200"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/e923bc1f-87d0-44b8-aa1f-cede67b2ffd8.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/9fdecb59-d575-4276-a919-59cccb746f35.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/2ea371a8-6368-4587-a390-0e541cef5407.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/7f20b91e-0b25-4bb0-bf32-d6540011af24.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/e923bc1f-87d0-44b8-aa1f-cede67b2ffd8.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/b7a4dab9-dd9c-4ccc-9bb9-b030f12ff012.jpeg?im_w=1200"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
						<ImageListItem cols={1}>
							<img
								src="https://a0.muscache.com/im/pictures/miso/Hosting-51849893/original/e923bc1f-87d0-44b8-aa1f-cede67b2ffd8.jpeg?im_w=720"
								alt={"CAPTION"}
								className={classes.photoDialogPic}
								loading="lazy"
								decoding="async"
							/>
						</ImageListItem>
					</ImageList>
				</Box>
			</Box>
		</React.Fragment>
	);
};

ProfilePhotosFeatured.propTypes = {};

export default ProfilePhotosFeatured;
