const chatMaxWidth = 800;

export default (theme) => ({
	inboxWrapper: {
		top: 65,
		[theme.breakpoints.down("xs")]: {
			top: 56,
		},
	},
	inboxWrapperNavCollapse: {
		top: 65,
		[theme.breakpoints.down("xs")]: {
			top: 56,
		},
	},
	chatNav: {
		left: 255,
		[theme.breakpoints.down("sm")]: {
			left: 0,
		},
	},
	chatNavCollapse: {
		left: 56,
		[theme.breakpoints.down("sm")]: {
			left: 0,
		},
	},

	listPanel: {
		padding: "32px 16px 32px 0",
		flex: "0 0 270px",
		[theme.breakpoints.down("md")]: {
			flex: "0 0 270px",
		},
		[theme.breakpoints.down("xs")]: {
			flex: "0 1 0%",
		},
	},
	chatPanel: {
		flex: "1 1 0%",
		[theme.breakpoints.down("sm")]: {
			borderLeft: "0px solid",
		},
		[theme.breakpoints.down("xs")]: {
			flex: "1 1 0%",
			borderLeft: "0px solid",
		},
	},
	detailsPanel: {
		flex: "0 0 360px",
		[theme.breakpoints.down("sm")]: {
			flex: "0 0 300px",
		},
		[theme.breakpoints.down("xs")]: {
			flex: "0 1 0%",
		},
	},
	leadAcceptPanel: {
		flex: "0 0 400px",
	},
	leadAcceptBox: {
		width: 400,
	},
	inboxChat: {
		width: "auto",
		[theme.breakpoints.down("xs")]: {
			width: "100vw",
		},
	},
	inboxDetails: {
		width: 360,
		[theme.breakpoints.down("sm")]: {
			width: 300,
		},
	},

	inboxTitleBox: {
		height: 65,
		paddingRight: 4,
		borderBottom: "1px solid rgb(224, 224, 224)",
		[theme.breakpoints.down("md")]: {
			paddingRight: 4,
		},
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 0,
			paddingRight: 0,
			height: 60,
		},
	},
	inboxTitleWrap: {
		"-webkit-box-flex": 1,
		"fontSize": 16,
		"flexGrow": 1,
		"lineHeight": "20px",
		"maxHeight": 20,
		"textOverflow": "ellipsis",
		"display": "-webkit-box",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
		"fontWeight": 700,
	},
	chatBox: {
		[theme.breakpoints.down("xs")]: {
			//paddingTop: 24,
		},
	},
	chatBoxWrapper: {
		margin: "0px auto",
		maxWidth: chatMaxWidth,
	},
	chatBoxH3: {
		padding: "24px 22px 0px",
		textAlign: "center",
		color: "#717171",
		fontWeight: 700,
		fontSize: 12,
		lineHeight: "16px",
		marginTop: 0,
		marginBottom: 0,
	},
	chatAlertText: {
		color: "#717171",
		fontWeight: 600,
		fontSize: 12,
		lineHeight: "16px",
		marginLeft: 12,
	},
	chatBoxAlertIcon: {
		color: "#717171",
		fontSize: 16,
	},
	chatBoxAlertTime: {
		fontWeight: 400,
		whiteSpace: "nowrap",
		fontSize: 10,
		lineHeight: "12px",
		display: "inline-block",
	},
	chatAlertBlock: {
		display: "inline-block",
	},
	chatBoxAlertDot: {
		display: "inline-block",
		paddingLeft: 4,
		paddingRight: 4,
	},
	chatBoxAlertLink: {
		paddingLeft: 5,
		color: "#222",
		textDecoration: "underline",
	},
	truncateText: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	ml12: {
		marginLeft: 12,
	},
	font700: {
		fontWeight: 700,
	},
	chatBoxImg: {
		width: 36,
		height: 36,
		fontSize: 14,
	},
	chatBoxMessageReadCheck: {
		color: theme.palette.primary.main,
		fontSize: 18,
	},
	chatInfoBubble: {
		maxWidth: 330,
		whiteSpace: "pre-line",
		fontSize: 14,
		lineHeight: 1.6,
		borderRadius: 10,
		overflow: "hidden",
		marginTop: 8,
		[theme.breakpoints.down("sm")]: {
			maxWidth: "100%",
		},
	},
	chatInfoBubbleOutlined: {
		border: "1px solid rgb(224, 224, 224)",
	},
	chatBubble: {
		"whiteSpace": "pre-line",
		"fontSize": 14,
		"lineHeight": 1.6,
		"borderRadius": 10,
		"overflow": "hidden",
		"padding": "8px 12px",
		"& a": {
			fontWeight: 600,
		},
		"& a:hover": {
			textDecoration: "underline",
		},
	},
	chatBubbleBlue: {
		backgroundColor: theme.palette.primary.main,
		color: "#FFF",
	},
	chatBubbleGrey: {
		"backgroundColor": "#e6e5eb",
		"overflow": "hidden",
		"& a": {
			color: theme.palette.primary.main,
		},
	},
	chatBubbleBox: {
		maxWidth: chatMaxWidth,
		margin: "22px 22px 0",
	},
	chatBubbleRight: {
		display: "flex",
		alignItems: "flex-end",
		flexDirection: "column",
	},
	chatBubbleLeft: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
	},
	chatBubbleRelative: {
		position: "relative",
	},
	chatBubbleAvatarBox: {
		position: "absolute",
		left: -46,
		bottom: 0,
	},
	chatBubbleWidth: {
		paddingLeft: 40,
		maxWidth: "70%",
		[theme.breakpoints.down("md")]: {
			maxWidth: "100%",
		},
	},
	chatBubbleFileWrapper: {
		overflow: "hidden",
		borderRadius: 10,
		width: "100%",
		display: "flex",
		marginTop: 8,
	},
	chatBubbleFileUploaded: {
		overflow: "hidden",
		borderRadius: 10,
		width: "100%",
		marginTop: 8,
	},
	chatBubbleFileUploadedBox: {
		border: "1px solid rgb(158, 158, 158)",
		borderRadius: 10,
		display: "flex",
		backgroundColor: "#FAFAFA",
		padding: "8px 12px",
		alignItems: "center",
		fontSize: 14,
	},
	chatBubbleFileImage: {
		marginTop: 4,
		borderRadius: 10,
		width: "100%",
	},
	chatBubbleFileText: {
		display: "block",
		fontSize: 12,
		lineHeight: 1.6,
	},
	chatAlert: {
		maxWidth: chatMaxWidth,
		margin: "16px 22px 10px",
	},
	chatAlertBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		borderRadius: 36,
		padding: "12px 20px",
		alignItems: "center",
	},
	chatTimeBox: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",
		paddingTop: 8,
		paddingRight: 12,
	},
	chatTimeSent: {
		marginRight: 4,
		color: theme.palette.grey[600],
		fontSize: 12,
		lineHeight: 1.5,
	},
	chatTimeBoxLeft: {
		display: "flex",
		alignItems: "center",
		paddingTop: 8,
	},
	chatTimeSentLeft: {
		marginRight: 4,
		paddingLeft: 12,
		fontSize: 12,
		color: theme.palette.grey[600],
		lineHeight: 1.5,
	},
	inboxChatSendBox: {
		padding: "12px 16px 12px 8px",
		[theme.breakpoints.down("xs")]: {
			padding: "12px 16px 0px 8px",
		},
	},
	sendButton: {
		height: 45,
	},
	listPanelWrapper: {
		[theme.breakpoints.down("sm")]: {
			width: 250,
			paddingRight: 8,
		},
	},
	listPanelItem: {
		color: "#222",
		borderRadius: "0px 24px 24px 0px",
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 26,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
		},
	},
	listPanelItemActive: {
		color: "#2586d9",
		borderRadius: "0px 24px 24px 0px",
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 26,
		backgroundColor: "#e8f0fe !important",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: 16,
		},
	},
	listPanelText: {
		fontWeight: 600,
	},
	listPanelTextActive: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	listPanelItemIcon: {
		minWidth: 40,
		color: "#585858",
	},
	listPanelItemIconActive: {
		minWidth: 40,
		color: theme.palette.primary.main,
	},
	unreadCount: {
		fontWeight: 600,
		fontSize: 14,
		color: "inherit",
		paddingRight: 6,
	},
	messageContent: {
		padding: "32px 16px 32px",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 0px 32px",
		},
	},
	inputRoot: {
		paddingBottom: 8,
		[theme.breakpoints.down("xs")]: {
			padding: "8px 16px",
		},
	},
	formUnderline: {
		"&:before": {
			borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		},
	},
	cardTextHead: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: 0,
		minHeight: 0,
		flex: "1 1 auto",
		//display: "flex",
		//flexDirection: "column",
		marginLeft: 16,
	},
	cardTextHeadBox: {
		display: "flex",
		alignItems: "center",
	},
	cardTextSubHead: {
		fontSize: 12,
		lineHeight: 1.5,
		color: theme.palette.grey[600],
		marginBottom: 8,
	},
	cardTextName: {
		fontSize: 16,
		lineHeight: "24px",
	},
	cardTextBody: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		minWidth: 0,
		minHeight: 0,
		flex: "1 1 auto",
		fontSize: 14,
	},
	cardRightOptions: {
		display: "flex",
		flexShrink: 0,
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "flex-end",
		marginLeft: 24,
		minWidth: 60,
	},
	cardRightTimeDate: {
		fontSize: 14,
	},
	cardRightIconsBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	},
	cardIconArchive: {
		"color": "#aaaaaa",
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	cardIconStar: {
		"color": "#aaaaaa",
		"&:hover": {
			color: "#ff6474",
		},
	},
	cardIconUnArchive: {
		"color": theme.palette.primary.main,
		"&:hover": {
			color: "#aaaaaa",
		},
	},
	cardIconUnStar: {
		"color": "#ff6474",
		"&:hover": {
			color: "#aaaaaa",
		},
	},
	cardUnread: {
		"backgroundColor": "#FFF",
		"fontWeight": 700,
		"borderBottom": "1px solid rgba(0, 0, 0, 0.08)",
		"&:hover": {
			boxShadow:
				"inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
			zIndex: 150,
		},
	},
	cardPadding: {
		display: "flex",
		justifyContent: "space-between",
		padding: "24px 16px",
	},
	cardRead: {
		"backgroundColor": "rgba(242,245,245,0.8)",
		"borderBottom": "1px solid rgba(0, 0, 0, 0.08)",
		"&:hover": {
			boxShadow:
				"inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
			zIndex: 150,
		},
	},
	cardStatus: {
		marginTop: 12,
	},
	cardChipRoot: {
		"marginLeft": 8,
		"color": "#043256",
		"backgroundColor": "#e8f4fd",
		"cursor": "pointer",
		"&:first-child": {
			marginLeft: 0,
		},
	},
	cardChipIcon: {
		color: "#043256",
	},
	notPaidChip: {
		//fontWeight: 700,
	},
	paidChip: {
		color: "#FFF",
		backgroundColor: theme.palette.success.main,
		//fontWeight: 700,
	},
	statusButton: {
		"backgroundColor": "#dbfbf3",
		"color": "#005747",
		"&:hover": {
			backgroundColor: "#b4e8dc",
		},
	},
	statusMenuItem: {
		"&:focus": {
			"backgroundColor": theme.palette.primary.main,
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.common.white,
			},
		},
	},
	statusMenuIcon: {
		minWidth: 34,
	},
	competitionImg: {
		height: 64,
		width: 64,
	},
	linearProgressRoot: {
		height: 8,
		borderRadius: 5,
		backgroundColor: "#e1e1e1",
	},
	linearProgressBar: {
		borderRadius: 5,
		backgroundColor: "rgb(33 33 33 / 55%)",
	},
	competitionIcon: {
		color: "#808080",
	},
	jobFormControl: {
		padding: "6px 24px",
		width: "100%",
	},
	jobInputBorder: {
		borderBottom: "1px solid rgb(238, 238, 238)",
	},

	// SHARED
	inboxHeader: {
		borderBottom: "1px solid rgb(224, 224, 224)",
		height: 50,
	},
	chipGreen: {
		color: "#0d8d33",
		backgroundColor: "#e2f9e9",
		marginBottom: 8,
		marginRight: 6,
		cursor: "pointer",
	},
	iconGreen: {
		color: "#0d8d33",
	},
	chipPurple: {
		color: "#5405bc",
		backgroundColor: "#f1e7ff",
		marginBottom: 8,
		marginRight: 6,
		cursor: "pointer",
	},
	iconPurple: {
		color: "#5405bc",
	},
	chipBlue: {
		color: "#0b05bc",
		backgroundColor: "#e7f4ff",
		marginBottom: 8,
		marginRight: 6,
		cursor: "pointer",
	},
	iconBlue: {
		color: "#0b05bc",
	},
	slide: {},
	slideExit: { transform: "translateX(0%)" },
	slideExitActive: {
		transform: "translateX(100%)",
		transition: "transform 500ms ease-in-out",
	},
	hideOverflow: { overflow: "hidden" },
	leadDetailsDivider: {
		margin: "24px -24px",
		height: 8,
		backgroundColor: "#ebebeb",
	},
	cardHeader: {
		fontSize: 16,
		lineHeight: 1.5,
		fontWeight: 700,
		marginBottom: 8,
	},
	subText: {
		fontSize: 14,
		lineHeight: 1.5,
		color: theme.palette.grey[700],
	},
	subTextButton: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	scrollbar: {
		"&::-webkit-scrollbar-track": {
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar": {
			width: 18,
			//backgroundColor: "#F5F5F5",
			borderRadius: 8,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgb(218 218 218 / 75%)",
			borderRadius: 8,
			border: "6px solid rgba(0, 0, 0, 0)",
			backgroundClip: "padding-box",
			borderRadius: 9999,
		},
	},
});
