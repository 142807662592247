import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Box from "@material-ui/core/Box";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";

// MUI Icons
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";

// Components
import LoadingButton from "@components/Global/Custom/Button";

// Language
import { adjective as proAdjective } from "@language/provider";
import FinalPriceInline from "@components/Global/Custom/FinalPriceInline";
import { observer } from "mobx-react";

const { BRAND_NAME, SITE_NAME } = process.env;

const ProfileScrollUp = observer(({ onSuccess }) => {
	const classes = useStyles("Profile");
	const stores = useStores();
	const {
		leadFunnelModalStore,
		profileStore,
		profileReviewsStore,
		profileActionPanelSwitchStore,
		profileActionsPanelStore,
		sendProjectStore,
	} = stores;

	const startLeadHandler = (type) => (e) => {
		leadFunnelModalStore.leadActionType = type;
		leadFunnelModalStore.show = true;
	};

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 600,
	});

	// const sendProject = async () => {
	// 	try {
	// 		await profileActionsPanelStore.sendProject();
	// 		onSuccess({ message: "Request sent!" });
	// 	} catch (err) {
	// 		throw err;
	// 	}
	// };

	const openSendProjectDialog = (e) => {
		e.preventDefault();
		sendProjectStore.init({
			leadKey: profileStore.leadKey,
			providerId: profileStore.provider._id,
			onDone: async () => {
				try {
					await profileActionsPanelStore.reload();
				} catch (e) {}
			},
		});
	};

	const hasLeadOrBid = !!profileActionsPanelStore.quote;
	const oneClickBlast =
		profileActionsPanelStore.currentPanelState === "oneClickBlast";
	return (
		<Slide direction={"up"} in={trigger}>
			<Box
				display={{ xs: "block", sm: "block", md: "none" }}
				position={"fixed"}
				px={6}
				py={4}
				left={0}
				minHeight={80}
				right={0}
				bottom={-1}
				width={"100%"}
				bgcolor={"#FFF"}
				boxShadow={4}
				borderTop={1}
				borderColor="grey.200"
				style={{ zIndex: 100 }}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexDirection={"row"}
				>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"flex-start"}
						justifyContent={"flex-start"}
						mr={4}
					>
						<FinalPriceInline
							total={
								profileActionsPanelStore.quote?.final_price.total ||
								profileStore.estimated_price?.total
							}
							unit={
								profileActionsPanelStore.quote
									? profileActionsPanelStore.quote?.final_price.unit
									: profileStore.estimated_price?.unit
							}
							description={
								profileActionsPanelStore.quote
									? profileActionsPanelStore.quote?.final_price.description
									: profileStore.estimated_price?.description
							}
						/>

						{!!profileReviewsStore.reviewsCount ? (
							<Box
								mt={1}
								display={"flex"}
								flexDirection={"row"}
								alignItems={"center"}
							>
								<Rating
									name="half-rating-read"
									value={1}
									precision={0.5}
									max={1}
									readOnly
									size="small"
								/>
								<Box paddingLeft={1} fontWeight={600} fontSize={12}>
									{profileReviewsStore.avgRating}
								</Box>
								<Box
									ml={1}
									fontSize={12}
									fontWeight={600}
									color="text.secondary"
									style={{ textDecoration: "underline" }}
								>
									<a href="#reviews">
										{profileReviewsStore.reviewsCount === 1 ? (
											<>{`(${profileReviewsStore.reviewsCount} review)`}</>
										) : (
											<>{`(${profileReviewsStore.reviewsCount} reviews)`}</>
										)}
									</a>
								</Box>
							</Box>
						) : (
							<Box
								mt={1}
								display={"flex"}
								flexDirection={"row"}
								alignItems={"center"}
							>
								<Rating max={1} value={1} readOnly size="small" />

								<Box component={"span"} fontSize={12} ml={1}>
									{`New on ${BRAND_NAME}`}
								</Box>
							</Box>
						)}
					</Box>
					{!hasLeadOrBid && !oneClickBlast && (
						<Button
							onClick={startLeadHandler("requested_availability")}
							size={"large"}
							variant="contained"
							color="primary"
							disableElevation
							className={classes.buttonCTA}
						>
							{SITE_NAME === "homeguide" ? (
								<>Request a quote</>
							) : (
								<>Check availability</>
							)}
						</Button>
					)}
					{hasLeadOrBid && (
						<Button
							href={`/client/inbox/${profileStore.leadKey}/${profileActionsPanelStore.quote.key}`}
							disableElevation
							variant="contained"
							color="primary"
							size="large"
							className={classes.buttonCTA}
							startIcon={<SmsOutlinedIcon />}
						>
							{`Message ${proAdjective.singular}`}
						</Button>
					)}

					{oneClickBlast && (
						<Button
							onClick={openSendProjectDialog}
							disableElevation
							variant="contained"
							color="primary"
							size="large"
							className={classes.buttonCTA}
						>
							Send request
						</Button>
					)}
				</Box>
			</Box>
		</Slide>
	);
});

ProfileScrollUp.propTypes = {};
export default ProfileScrollUp;
