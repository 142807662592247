import React from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react-lite";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";

import { adjective as proAdjective } from "@language/provider";

const { BRAND_NAME } = process.env;

const ProfileReviewsDialog = observer(() => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const { profileReviewsDialogStore: store } = useStores();
	const classes = useStyles("Profile");

	const handleClose = () => {
		store.showModal = false;
	};

	return (
		<Dialog
			scroll={fullScreen ? "paper" : "body"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={store.show}
			onClose={handleClose}
			aria-labelledby="Review guidelines"
			aria-describedby="Review guidelines"
			className={classes.reviewsDialog}
		>
			<DialogTitle disableTypography style={{ minHeight: 30 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box component={"div"} maxWidth={250} height={130} m="0 auto 12px">
					<Box
						component={"img"}
						width={"100%"}
						alt={"Lead prices"}
						src="//static.homeguide.com/assets/images/desktop/pro-reviews-icon.png"
					/>
				</Box>
				<Box mb={12}>
					<Box
						display="flex"
						justifyContent={"center"}
						alignItems="center"
						fontWeight={700}
						lineHeight={1.4}
						fontSize={{ xs: 22, sm: 24 }}
						pb={4}
					>
						Our review guidelines
					</Box>

					<Box component={"div"} mb={5} color={"grey.700"} lineHeight={1.6}>
						Our community is built on trust. We work hard to maintain that trust
						and make {BRAND_NAME} truly helpful for everyone. We're here to help
						you find the best {proAdjective.singular}, and reviews are an
						essential part of your search.
					</Box>

					<Box component={"div"} mb={5} color={"grey.700"} lineHeight={1.6}>
						We'll remove any reviews that:
					</Box>

					<Box component={"ul"} mb={6} color={"grey.700"} lineHeight={1.6}>
						<li>
							Are not related to the {proAdjective.singular}'s performance,
							interactions, and professionalism
						</li>
						<li>Misrepresents or doesn't address the consumer experience</li>
						<li>
							Endorses illegal, violent, discriminatory, vulgar, threats,
							harassment, lewdness, or other displays of bigotry
						</li>
						<li>Discloses people's private information</li>
						<li>Are not posted within 60 days after the job is done</li>
						<li>Promotes commercial products or services</li>
						<li>Infringes on other's intellectual property</li>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
});

ProfileReviewsDialog.propTypes = {};
export default ProfileReviewsDialog;
