const { SITE_NAME, BRAND_NAME } = process.env;

export default ({ provider, reviews, cityServiceStat }) => {
	let breadCrumbList = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
					"@id": `https://${SITE_NAME}.com/`,
					"name": BRAND_NAME,
					"url": `https://${SITE_NAME}.com/`,
				},
			},

			provider.profile?.city.active && {
				"@type": "ListItem",
				"position": 2,
				"item": {
					"@id": `https://${SITE_NAME}.com/${provider.profile?.city.state.toLowerCase()}/`,
					"name": `${provider.profile.city.state.toUpperCase()}`,
					"url": `https://${SITE_NAME}.com/${provider.profile?.city.state.toLowerCase()}/`,
				},
			},

			provider.profile?.city.active && {
				"@type": "ListItem",
				"position": 3,
				"item": {
					"@id": `https://${SITE_NAME}.com${provider.profile?.city.url}/`,
					"name": `${provider.profile?.city.name.ucFirst()}`,
					"url": `https://${SITE_NAME}.com${provider.profile?.city.url}/`,
				},
			},

			cityServiceStat.active && {
				"@type": "ListItem",
				"position": 4,
				"item": {
					"@id": `https://${SITE_NAME}.com${provider.profile?.city.url}${provider.profile?.service.url}/`,
					"name": `${provider.profile.service.phrase.ucFirst()}`,
					"url": `https://${SITE_NAME}.com${provider.profile?.city.url}${provider.profile?.service.url}/`,
				},
			},

			{
				"@type": "ListItem",
				"position": 5,
				"item": {
					"@id": `https://${SITE_NAME}.com${provider.profile?.city.url}${provider.profile?.service.url}/${provider.profile.slug}`,
					"name": `${provider.company_name.ucFirst()}`,
					"url": `https://${SITE_NAME}.com${provider.profile?.city.url}${provider.profile?.service.url}/${provider.profile.slug}`,
				},
			},
		].filter(Boolean),
	};

	const localBusiness = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"address": {
			"@type": "PostalAddress",
			"addressLocality": `${provider.location.city.ucFirst()}`,
			"addressRegion": `${provider.location.state.toUpperCase()}`,
			"postalCode": `${provider.location.zip}`,
		},
		"name": `${provider.company_name.ucFirst()}`,
		//TODO ADD PRICE RANGE IF SET & SAMEAS = ANY SOCIAL LINKS SET
		//"priceRange": "",
		// "sameAs": Object.values(provider.social),
		//"openingHours": "Mo,Tu,We,Th 09:00-12:00",
		// "paymentAccepted": provider.accepted_payment_types.join(","),
		// "numberOfEmployees": `${provider.company_employees}`,
		"image": `${provider.image?.value}`,
	};

	if (provider.company_employees) {
		localBusiness["numberOfEmployees"] = provider.company_employees;
	}

	if (provider.social) {
		localBusiness["sameAs"] = Object.values(provider.social);
	}

	if (provider.accepted_payment_types) {
		localBusiness["paymentAccepted"] =
			provider.accepted_payment_types.join(", ");
	}

	// if (provider.phone) localBusiness["telephone"] = provider.phone;

	if (provider.metrics.reviews_count > 0) {
		localBusiness["aggregateRating"] = {
			"@type": "AggregateRating",
			"ratingValue": `${provider.metrics.reviews_avg.toFixed(2)}`,
			"reviewCount": `${provider.metrics.reviews_count}`,
			"bestRating": `${provider.best_review?.rating?.toFixed(0) || 0}`,
			"worstRating": `${provider.worst_review?.rating?.toFixed(0) || 5}`,
		};

		localBusiness["review"] = (reviews || []).map((review) => {
			const published = new Date(review.created_at);
			const publishedYear = published.getFullYear();
			const publishedMonth =
				published.getMonth() + 1 > 9
					? published.getMonth() + 1
					: "0" + (published.getMonth() + 1);
			const publishedDay =
				published.getDate() > 9
					? published.getDate()
					: "0" + published.getDate();
			const authorName = review.author;

			return [
				{
					"@type": "Review",
					"datePublished": `${publishedYear}-${publishedMonth}-${publishedDay}`,
					"description": `${review.message.body
						.replace(/"/g, "")
						.replace(/'/g, "")}`,
					"author": {
						"@type": "Person",
						"name": `${authorName}`,
					},
					"reviewRating": {
						"@type": "Rating",
						"ratingValue": `${review.rating}`,
					},
				},
			];
		});
	}

	if (
		!provider.profile?.city ||
		!provider.profile?.service ||
		!provider.profile.city.active
	)
		breadCrumbList = {
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			"itemListElement": [
				{
					"@type": "ListItem",
					"position": 1,
					"item": {
						"@id": `https://${SITE_NAME}.com/`,
						"name": BRAND_NAME,
						"url": `https://${SITE_NAME}.com/`,
					},
				},
			],
		};

	return [breadCrumbList, localBusiness];
};
