import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "@hooks/useStyles";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

import GoogleIcon from "@components/Global/Custom/Icons/GoogleIcon";
import YelpIcon from "@components/Global/Custom/Icons/YelpIcon";
import HouzzIcon from "@components/Global/Custom/Icons/HouzzIcon";
import { Link } from "react-router-dom";
import UrlFormater from "@src/libs/UrlFormater";

const socialMedia = [
	{
		key: "facebook",
		label: "Facebook",
		addLabel: "Add Facebook",
		to: "/pros/profile/edit-info",
		icon: <FacebookIcon />,
	},
	{
		key: "twitter",
		label: "Twitter",
		addLabel: "Add Twitter",
		to: "/pros/profile/edit-info",
		icon: <TwitterIcon />,
	},
	{
		key: "instagram",
		label: "Instagram",
		addLabel: "Add Instagram",
		to: "/pros/profile/edit-info",
		icon: <InstagramIcon />,
	},
	{
		key: "youtube",
		label: "YouTube",
		addLabel: "Add YouTube",
		to: "/pros/profile/edit-info",
		icon: <YouTubeIcon />,
	},
	{
		key: "linkedin",
		label: "LinkedIn",
		addLabel: "Add LinkedIn",
		to: "/pros/profile/edit-info",
		icon: <LinkedInIcon />,
	},
	{
		key: "google",
		label: "Google",
		addLabel: "Add Google",
		to: "/pros/profile/edit-info",
		icon: <GoogleIcon color={false} />,
	},
	{
		key: "yelp",
		label: "Yelp",
		addLabel: "Add Yelp",
		to: "/pros/profile/edit-info",
		icon: <YelpIcon />,
	},
];

const ProfileSocialMedia = ({ social }) => {
	const hasSocialLinks = !!Object.values(social).find((el) => !!el);
	const classes = useStyles("Profile");

	return (
		<Box mb={6}>
			<Paper>
				<Box p={6}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pb={4}
					>
						<Box
							component={"div"}
							fontWeight={700}
							className={classes.profileHeader}
						>
							Social media
						</Box>
						{hasSocialLinks && (
							<Button
								component={Link}
								className={classes.profileEditButton}
								to={"/pros/profile/edit-info"}
								size={"large"}
								color="primary"
							>
								Edit
							</Button>
						)}
					</Box>
					<Box mb={6}>Add links to your social media profiles.</Box>

					<Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
						{socialMedia.map(({ key, label, addLabel, to, icon }) => (
							<Box key={key} mr={3} mb={3}>
								<Button
									component={!social[key] ? Link : "a"}
									disableElevation
									to={!social[key] ? to : undefined}
									href={
										!!social[key]
											? UrlFormater.withHttp(social[key])
											: undefined
									}
									size={"large"}
									variant={!social[key] ? "outlined" : undefined}
									startIcon={icon}
									target={!!social[key] ? "_blank" : undefined}
									color={!!social[key] ? "primary" : "default"}
								>
									{!!social[key] ? label : addLabel}
								</Button>
							</Box>
						))}
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

ProfileSocialMedia.propTypes = {};

export default ProfileSocialMedia;
