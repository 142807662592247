import React from "react";
import { observer } from "mobx-react-lite";
import useStyles from "@hooks/useStyles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import useStores from "@libs/framework/src/hooks/useStores";

const ProfileResume = observer(() => {
	const classes = useStyles("Profile");
	const { profileResumeStore: store } = useStores();

	return (
		<React.Fragment>
			<>
				<a className="anchor" id="resume" />

				<div className={classes.profileDivider}>
					<Divider light={true} />
				</div>

				<h2 className={classes.profileH2}>Resume</h2>

				<Box my={6}>
					<Grid container spacing={6}>
						{store.hasWorkExperiences && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Work Experience
									</Box>
									{store.workExperiences.map((workExperience) => {
										return (
											<Grid
												container
												spacing={10}
												key={`${workExperience.name}-${workExperience.title}`}
											>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{workExperience.title}
													</div>
													<div className={classes.profileResumeDetail}>
														{workExperience.name}
													</div>
													<div className={classes.profileResumeDetail}>
														{workExperience.work_experience?.year_range}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}
						{store.hasEducations && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Education
									</Box>
									{store.educations.map((education) => {
										return (
											<Grid container spacing={10} key={education.organization}>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{education.organization}
													</div>
													<div className={classes.profileResumeDetail}>
														{education.education.major}
													</div>
													<div className={classes.profileResumeDetail}>
														{store.dateFormatString(education.issuedAt, "YYYY")}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}

						{store.hasAffiliations && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Associations & Affiliations
									</Box>
									{store.affiliations.map((affiliation) => {
										return (
											<Grid container spacing={10} key={affiliation.name}>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{affiliation.name}
													</div>
													<div className={classes.profileResumeDetail}>
														{affiliation.title}
													</div>
													<div className={classes.profileResumeDetail}>
														{affiliation.year_range}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}

						{store.hasAwards && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Honors & Awards
									</Box>
									{store.awards.map((award) => {
										return (
											<Grid container spacing={10} key={award.name}>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{award.name}
													</div>
													{award.type && (
														<div className={classes.profileResumeDetail}>
															{award.type}
														</div>
													)}
													<div className={classes.profileResumeDetail}>
														{store.dateFormatString(award.issuedAt, "YYYY")}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}

						{store.hasPublications && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Publications
									</Box>
									{store.publications.map((publication) => {
										return (
											<Grid container spacing={10} key={publication.name}>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{publication.name}
													</div>
													<div className={classes.profileResumeDetail}>
														{publication.validationUrl ? (
															<Link
																href={`${publication.validationUrl}`}
																target="_blank"
																color="primary"
															>
																{publication.title || publication.name}
															</Link>
														) : (
															publication.title
														)}
													</div>
													<div className={classes.profileResumeDetail}>
														{store.dateFormatString(
															publication.issuedAt,
															"YYYY",
														)}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}

						{store.hasSpeakingEngagements && (
							<Grid item xs={12} md={6}>
								<div className={classes.profileResumeBorder}>
									<Box component="h3" className={classes.profileResumeH3}>
										Speaking Engagements
									</Box>
									{store.speakingEngagements.map((speakingEngagement) => {
										return (
											<Grid
												container
												spacing={10}
												key={`${speakingEngagement.name}-${speakingEngagement.title}`}
											>
												<Grid item xs={12}>
													<div className={classes.profileResumeTitle}>
														{speakingEngagement.name}
													</div>
													<div className={classes.profileResumeDetail}>
														{speakingEngagement.validationUrl ? (
															<Link
																href={speakingEngagement.validationUrl}
																target="_blank"
																color="primary"
															>
																{speakingEngagement.title ||
																	speakingEngagement.name}
															</Link>
														) : (
															`${
																speakingEngagement.title ||
																speakingEngagement.name
															}`
														)}
													</div>
													<div className={classes.profileResumeDetail}>
														{store.dateFormatString(
															speakingEngagement.issuedAt,
															"YYYY",
														)}
													</div>
												</Grid>
											</Grid>
										);
									})}
								</div>
							</Grid>
						)}
					</Grid>
				</Box>
			</>
		</React.Fragment>
	);
});

ProfileResume.propTypes = {};

export default ProfileResume;
