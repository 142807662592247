import React from "react";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Link } from "@material-ui/core";
import useStores from "@libs/framework/src/hooks/useStores";

const ProfileLicensingDialog = observer(({ close, open }) => {
	const classes = useStyles("Profile");
	const { profileLicenseStore: store, profileReviewsDialogStore } = useStores();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Dialog
			scroll="paper"
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={close}
			aria-labelledby="editorial integrity"
			aria-describedby="editorial integrity"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={close}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box mb={12} mx={8}>
					<Box display="flex" alignItems="center" pb={8}>
						<Box fontWeight={700} lineHeight={1.4} fontSize={22}>
							License and credential details
						</Box>
					</Box>

					<Grid container spacing={8}>
						{store.licenses.map((license) => {
							return (
								<Grid item xs={12} key={license.number}>
									<Paper variant="outlined" className={classes.licensePaper}>
										<div className={classes.licenseHead}>
											<VerifiedUserIcon
												fontSize="small"
												className={classes.licenseIcon}
											/>
											<div className={classes.licenseTitle}>License</div>
										</div>
										{license.name && (
											<div className={classes.licenseFact}>
												<span>Holder: </span>
												{license.name}
											</div>
										)}
										{(license.type || license.state) && (
											<div className={classes.licenseFact}>
												<span>Type:</span> {license.type ? license.type : ""}{" "}
												{license.state ? `(${license.state})` : ""}
											</div>
										)}
										{license.number && (
											<div className={classes.licenseFact}>
												<span>Number:</span> {license.number}
											</div>
										)}
										{license.issuedAt && (
											<div className={classes.licenseFact}>
												<span>Issued:</span>{" "}
												{store.dateFormatString(license.issuedAt)}
											</div>
										)}
										{license.expiresAt && (
											<div className={classes.licenseFact}>
												<span>Valid through:</span>{" "}
												{store.dateFormatString(license.expiresAt)}
											</div>
										)}
										{license.verifiedAt && (
											<div className={classes.licenseFact}>
												<span>Verified:</span>{" "}
												{store.dateFormatString(license.verifiedAt)}
											</div>
										)}
										{license.capacity && (
											<div className={classes.licenseFact}>
												<span>Capacity:</span> {license.capacity}
											</div>
										)}
										{license.validationUrl && (
											<div className={classes.licenseFact}>
												<Link
													color="primary"
													target="_blank"
													href={profileReviewsDialogStore.absoluteUrl(
														license.validationUrl,
													)}
												>
													<span>Verify license</span>
												</Link>
											</div>
										)}
									</Paper>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
});

ProfileLicensingDialog.propTypes = {};
export default ProfileLicensingDialog;
