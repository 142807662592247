import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let GoogleIcon = ({ color = true, ...props }) => (
	<SvgIcon {...props} viewBox={"0 0 28 28"}>
		<path
			d="M25.52 14.272c0-.85-.076-1.669-.218-2.454H14v4.641h6.458a5.52 5.52 0 01-2.394 3.622v3.011h3.878c2.269-2.089 3.578-5.165 3.578-8.82z"
			fill={color ? "#4285F4" : undefined}
			fillRule="evenodd"
		/>
		<path
			d="M14 26.001c3.24 0 5.956-1.074 7.942-2.907l-3.879-3.01c-1.074.72-2.449 1.145-4.063 1.145-3.126 0-5.771-2.111-6.715-4.948H3.276v3.11A11.995 11.995 0 0014 26z"
			fill={color ? "#34A853" : undefined}
			fillRule="evenodd"
		/>
		<path
			d="M7.285 16.281a7.213 7.213 0 01-.376-2.28c0-.79.136-1.56.376-2.28V8.612H3.276A11.996 11.996 0 002 14.002c0 1.935.464 3.768 1.276 5.388l4.01-3.109z"
			fill={color ? "#FBBC05" : undefined}
			fillRule="evenodd"
		/>
		<path
			d="M14 6.773c1.761 0 3.343.605 4.587 1.794l3.442-3.442C19.95 3.19 17.234 2 13.999 2 9.31 2 5.252 4.69 3.277 8.61l4.01 3.11C8.228 8.884 10.873 6.773 14 6.773z"
			fill={color ? "#EA4335" : undefined}
			fillRule="evenodd"
		/>
	</SvgIcon>
);
GoogleIcon = memo(GoogleIcon);
GoogleIcon.displayName = "GoogleIcon";
GoogleIcon.muiName = "GoogleIcon";

export default GoogleIcon;
