import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import ProfileReviewsDialog from "./ProfileReviewsDialog";
import Alert from "@material-ui/lab/Alert";

import useStores from "@hooks/useStores";

import ReviewItem from "@components/Global/Custom/ReviewItem";
import ReviewsMetrics from "@components/Dashboard/Pro/Shared/ReviewsMetrics";

import LanguageIcon from "@material-ui/icons/Language";
import { observer } from "mobx-react-lite";

import { adjective as proAdjective } from "@language/provider";

const { LINK_HELP } = process.env;

const ProfileReviews = observer(({ provider }) => {
	const classes = useStyles("Profile");
	const {
		profileReviewsStore: store,
		profileReviewsListStore,
		profileReviewsDialogStore,
	} = useStores();

	const openReviewsList = () => {
		profileReviewsListStore.ModalStore.showModal = true;
	};

	const openReviewsInfo = () => {
		profileReviewsDialogStore.showModal = true;
	};

	return (
		<React.Fragment>
			<a className="anchor" id="reviews" />

			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			{store.reviewsCount > 0 && (
				<React.Fragment>
					<section>
						<h2 className={classes.profileH2}>Reviews</h2>

						{/* <Box component="p" mb={6} className={classes.profileBodyText}>
							Customers rated this tutor highly for <b>work quality</b>,{" "}
							<b>responsiveness</b>, and <b>value</b>.
						</Box> */}

						<Box mb={7}>
							<Alert severity="info">
								<Box component={"span"} fontWeight={600}>
									Your trust is our top concern
								</Box>
								, so businesses can't pay to alter or remove their
								reviews.&nbsp;&nbsp;
								<Link
									color="primary"
									style={{
										fontWeight: 600,
										display: "inline-block",
										cursor: "pointer",
									}}
									onClick={openReviewsInfo}
									aria-label="Customer reviews policy"
								>
									Learn more
								</Link>
								.
							</Alert>
						</Box>

						<ReviewsMetrics {...store.provider.metrics} />

						<Box pt={8} pb={8}>
							<Divider light={true} />
						</Box>

						<div>
							{store.reviewsCount > 0 &&
								store.reviews.slice(0, 6).map((review, index, arr) => (
									<React.Fragment key={review._id}>
										<ReviewItem review={review} showReplyButton={false} />
										{index !== arr.length - 1 && (
											<div className={classes.reviewDivider}>
												<Divider light={true} />
											</div>
										)}
									</React.Fragment>
								))}

							{store.reviewsCount > 6 && (
								<Box mb={4} mt={10}>
									<Button
										variant="outlined"
										color="secondary"
										size="large"
										className={classes.showAllButton}
										onClick={openReviewsList}
									>
										Show all {store.reviewsCount} reviews
									</Button>
								</Box>
							)}
						</div>
					</section>
				</React.Fragment>
			)}

			<ProfileReviewsDialog />

			{store.reviewsCount === 0 && (
				<React.Fragment>
					<section>
						<h2 className={classes.profileH2}>No reviews (yet)</h2>

						<Box
							display="flex"
							flexDirection="row"
							justifyContent="flex-start"
							pt={3}
							pb={6}
							maxWidth={{ xs: "100%", sm: "100%", md: "70%" }}
						>
							<LanguageIcon />
							<Box ml={4} lineHeight={1.5} fontSize={16}>
								Ask this {proAdjective.singular} for references. There's no
								obligation to hire and we’re{" "}
								<Link underline="hover" target="_blank" href={LINK_HELP}>
									here to help
								</Link>{" "}
								your booking go smoothly.
							</Box>
						</Box>
					</section>
				</React.Fragment>
			)}
		</React.Fragment>
	);
});

ProfileReviews.propTypes = {
	provider: PropTypes.shape({
		metrics: PropTypes.shape({
			reviews_count: PropTypes.number.isRequired,
			reviews_avg: PropTypes.number.isRequired,
		}),
		key: PropTypes.string.isRequired,
		reviews: PropTypes.arrayOf(
			PropTypes.shape({
				message: PropTypes.shape({
					author: PropTypes.string,
					body: PropTypes.string,
				}).isRequired,
				rating: PropTypes.number.isRequired,
				created_at: PropTypes.string.isRequired,
			})
		),
	}).isRequired,
	reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfileReviews;
