import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import shortid from "shortid";
import { useHistory, useLocation } from "react-router";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useError from "@hooks/useError";
import useLoading from "@hooks/useLoading";
import useStaticContext from "@hooks/useStaticContext";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";
import CheckIcon from "@material-ui/icons/Check";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

import { styles, stores } from "./.config";
import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";
import {compose} from "recompose";
import stepId from "@components/Dashboard/Pro/Funnel/Templates/ApertureProgress/stepId";
import { StopOutlined } from "@material-ui/icons";

const LeadDetails = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(
	({
		quoteKey,
		leadKey,
		displayPriceEstimate = true,
		displayServiceName = false,
	}) => {
		const classes = useStyles("LeadDetails");
		const { leadDetailsStore: store } = useStores();
		const [ErrorTemplate, onError] = useError();
		const [LoadingTemplate, onLoading] = useLoading({ _id: "LeadDetails" });
		const history = useHistory();
		const location = useLocation();

		const [open2, setOpen2] = useState(false);
		const handleClick2 = () => setOpen2(!open2);

		useEffect(() => {
			store
				.init({ quoteKey, leadKey })
				.then(() => onLoading(false))
				.catch(onError);
		}, []);

		const handleClick = () => {
			store
				.updateQuotePhoneClicked()
				.then(() => history.push(location))
				.catch(onError);
		};

		return (
			<React.Fragment>
				<LoadingTemplate>
					{/* {!store.isTargetingService && (
					<Box component={"div"} display={"flex"} alignItems={"center"} mb={4}>
						<Alert
							icon={<EmojiObjectsOutlinedIcon fontSize="inherit" />}
							severity="warning"
						>
							You're not targeting jobs like this, so make sure it's a good fit.
						</Alert>
					</Box>
				)} */}
					<Box mb={6}>
						{displayPriceEstimate && (
							<>
								<Box
									component={"div"}
									display={"flex"}
									justifyContent={"space-between"}
									pb={5}
									pt={2}
								>
									<Box
										component={"div"}
										fontSize={16}
										lineHeight={"24px"}
										fontWeight={300}
									>
										Price estimate
									</Box>
									<Box
										color={"#2ac389"}
										fontSize={16}
										lineHeight={"24px"}
										fontWeight={600}
									>
										{store.final_price}
									</Box>
								</Box>
								<Box mb={6}>
									<Divider light />
								</Box>
							</>
						)}

						{/* <Box
					component={"div"}
					display={"flex"}
					justifyContent={"space-between"}
					pb={5}
				>
					<Box
						component={"div"}
						fontSize={18}
						lineHeight={"24px"}
						fontWeight={300}
					>
						Price estimate
					</Box>
					<Box
						color={"#2ac389"}
						fontSize={18}
						lineHeight={"24px"}
						fontWeight={700}
					>
						${store.inboxStore.estimate}
					</Box>
				</Box> */}
						{/* <Box
					component={"div"}
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"flex-start"}
					pb={4}
				>
					<Box display={"flex"} flexDirection={"column"}>
						<Box fontWeight={700} mb={2}>
							2 bedrooms/3+ bathrooms
						</Box>
						<Box fontWeight={600} ml={4}>
							Includes:
						</Box>
						<Box ml={4} fontSize={14}>
							Cleaning Supplies
						</Box>
						<Box ml={4} fontSize={14}>
							Trash bags
						</Box>
					</Box>
					<Box fontWeight={600}>$150</Box>
				</Box>
				<Box
					component={"div"}
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"flex-start"}
					pb={4}
				>
					<Box display={"flex"} flexDirection={"column"}>
						<Box fontWeight={700} mb={2}>
							Add-ons
						</Box>
						<Box fontSize={14}>Fridge cleaning</Box>
					</Box>
					<Box fontWeight={600}>+$15</Box>
				</Box> */}
						{/* <Box mb={6} mt={4}>
					<Divider light />
				</Box> */}

						{displayServiceName && (
							<Box
								display={"flex"}
								justifyContent={"space-between"}
								alignItems={"center"}
								mb={2}
							>
								<Box fontSize={18} lineHeight={1.4} fontWeight={600} mb={3}>
									{!!store.lead?.service.name_proper
										? store.lead?.service.name_proper.ucFirst()
										: store.lead?.service.name.ucFirst()}
								</Box>
							</Box>
						)}

						{/* {messagesChatRoomInfoStore &&
					store.quote &&
					store.lead &&
					store.quote.paid.value && (
						<Box
							component={"div"}
							display={"flex"}
							justifyContent={"space-between"}
							pb={5}
						>
							<Box
								component={"div"}
								fontSize={18}
								lineHeight={"24px"}
								fontWeight={300}
							>
								Project details
							</Box>
						</Box>
					)} */}

						{/* <Box
					component={"div"}
					display={{ xs: "flex"}}
					alignItems={"center"}
				>
					<PhoneOutlinedIcon className={classes.proDetailsIcon} />
					<Box component={"div"} ml={1}>
						<Button color="primary" onClick={handleClick}>
							Show phone
						</Button>
					</Box>
				</Box> */}

						<Box component={"div"} display={"flex"} alignItems={"center"}>
							<LocationOnOutlinedIcon />
							<Box ml={2} mr={3} fontSize={14}>
								{store.location}
							</Box>
							<Box fontSize={14}>
								<Link underline="hover" color="primary" onClick={handleClick2}>
									{open2 ? "Hide map" : "Show map"}
								</Link>
							</Box>
						</Box>

						<Collapse in={open2} collapsedSize={0} timeout="auto">
							<Box width={"100%"} maxWidth={450} mt={2}>
								<Box
									component={"img"}
									src={`data:image/png;base64, ${store.base64Map}`}
									width={"100%"}
									loading="lazy"
									decoding="async"
									borderRadius={8}
								/>
							</Box>
						</Collapse>

						{store.showPhone && (
							<div className={classes.leadDetailsBox}>
								<PhoneOutlinedIcon className={classes.proDetailsIcon} />

								{store.isProvider && (
									<div className={classes.leadDetailsItemList}>
										<div className={classes.leadDetailsItem}>
											{store.showPhoneMask ? (
												<React.Fragment>
													{store.phoneMask.link ? (
														<Link color="primary" onClick={handleClick}>
															{store.phoneMask.value}
														</Link>
													) : (
														store.phoneMask.value
													)}
												</React.Fragment>
											) : (
												<Link
													color="primary"
													href={`tel:${store.clientPhone.replace(/\D/g, "")}`}
												>
													{store.clientPhone}
												</Link>
											)}
										</div>
									</div>
								)}

								{store.isClient && store.clientPhone && (
									<div className={classes.leadDetailsItemList}>
										{store.clientPhone}
									</div>
								)}
							</div>
						)}

						{/* {(store.preferredStartDates || store.timesAvailable) && (
							<div className={classes.leadDetailsBox}>
								<EventNoteIcon />
								<div className={classes.leadDetailsItemList}>
									{store.preferredStartDates && (
										<div className={classes.leadDetailsItem}>
											<div>{store.preferredStartDates}</div>
										</div>
									)}
									{store.timesAvailable && (
										<div className={classes.leadDetailsItem}>
											<div>{store.timesAvailable}</div>
										</div>
									)}
								</div>
							</div>
						)} */}

						{/* {store.phone && (
					<div className={classes.leadDetailsBox}>
						<PhoneOutlinedIcon />
						<div className={classes.leadDetailsItemList}>
							<div className={classes.leadDetailsItem}>
								<Box
									color="primary"
									component={"a"}
									href={`tel:${store.phone.replace(/\D/g, "")}`}
								>
									{store.phone}
								</Box>
							</div>
						</div>
					</div>
				)} */}

						{/* <div className={classes.leadDetailsBox}>
				<PhotoOutlinedIcon />
				<div className={classes.leadDetailsItemList}>
					<Box display="flex" alignItems="center">
						<Avatar
							className={classes.leadOpPic}
							variant="rounded"
							alt="Malcolm McKinsey, M.Ed."
							src="https://static.homeguide.com/assets/images/content/homeguide-flat-plastic-pvc-screen-gutter-guards-installed.jpg"
							imgProps={{
								loading: "lazy",
								decoding: "async",
							}}
						/>
					</Box>
				</div>
			</div> */}
						{store.leadAnswers.map((leadAnswer) => (
							<div key={shortid.generate()} className={classes.leadDetailsBox}>
								<CheckIcon className={classes.leadDetailsCheck} />
								<div className={classes.leadDetailsItemList}>
									<div className={classes.leadDetailsBoxTitle}>
										{leadAnswer.description}
									</div>
									{leadAnswer.subHeader && (
										<div className={classes.leadDetailsPrice}>
											{leadAnswer.subHeader}
										</div>
									)}
									{leadAnswer.values.map((value, index) => (
										<div
											key={shortid.generate()}
											className={classes.leadDetailsItem}
										>
											<div>
												{leadAnswer.sensitive
													? store.showSensitiveValue
														? value
														: "Known after purchase"
													: value}
											</div>
											{leadAnswer.amounts[index] && (
												<div className={classes.leadDetailsPrice}>
													${leadAnswer.amounts[index]}
												</div>
											)}
										</div>
									))}
								</div>
								{/* <div className={classes.leadDetailsPrice}>$360</div> */}
							</div>
						))}
					</Box>
				</LoadingTemplate>
				<ErrorTemplate />
			</React.Fragment>
		);
	}
);

LeadDetails.propTypes = {
	quoteKey: PropTypes.string,
	leadKey: PropTypes.string,
	displayPriceEstimate: PropTypes.bool,
	displayServiceName: PropTypes.bool,
};
export default LeadDetails;
