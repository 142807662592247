import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";

// Hooks
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

// MUI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import LazyLoad from "@custom/LazyLoad";

// MUI Icons
import CloseIcon from "@material-ui/icons/Close";

const ProfilePhotosDialog = observer(() => {
	const classes = useStyles("Profile");
	const {
		profilePhotosDialogStore: store,
		profilePhotosStore,
		profileStore,
		imageDialogStore,
	} = useStores();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const viewPhoto = (selectedIndex) => (e) => {
		imageDialogStore.init(undefined, profilePhotosStore.media, selectedIndex);
	};

	const handleClose = () => {
		store.onClose();
	};

	return (
		<Dialog
			scroll={"paper"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"md"}
			open={store.show}
			onClose={handleClose}
			aria-labelledby="Pictures and videos"
			aria-describedby="Pictures and videos"
		>
			<DialogTitle
				disableTypography
				style={{
					minHeight: 58,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box fontSize={18} fontWeight={600}>
					{profileStore.provider.company_name}
				</Box>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Container
					maxWidth={false}
					style={{ maxWidth: 712, paddingTop: 20, paddingBottom: 60 }}
					disableGutters
				>
					<ImageList rowHeight={236} cols={2} gap={8}>
						{profilePhotosStore.media.map((media, index) => (
							<ImageListItem
								key={media.key}
								cols={fullScreen ? 2 : 1}
								onClick={viewPhoto(index)}
							>
								<LazyLoad>
									<img
										src={
											media.media_type === "video"
												? media.thumbnail
												: media.value
										}
										alt={media.caption}
										className={classes.photoDialogPic}
										loading="lazy"
										decoding="async"
									/>
								</LazyLoad>
							</ImageListItem>
						))}
					</ImageList>
				</Container>
			</DialogContent>
			{/* <DialogActions style={{ justifyContent: "center", padding: 16 }}>
				<Button
					color="primary"
					variant="contained"
					size="large"
					disableElevation
				>
					Check availability
				</Button>
			</DialogActions> */}
		</Dialog>
	);
});

ProfilePhotosDialog.propTypes = {};
export default ProfilePhotosDialog;
