import React from "react";
import PropTypes from "prop-types";

import Carousel from "@components/Global/Custom/Carousel";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";

import useStyles from "@hooks/useStyles";
import { adjective as proAdjective } from "@language/provider";
import { Link } from "react-router-dom";

const ProfileMedia = ({ media }) => {
	const classes = useStyles("Profile");

	return (
		<React.Fragment>
			<Box mb={6}>
				<Paper>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							pb={4}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Photos and videos
							</Box>

							{media.length > 0 && (
								<Button
									className={classes.profileEditButton}
									component={Link}
									to={"/pros/profile/edit-media"}
									size={"large"}
									color="primary"
								>
									Edit
								</Button>
							)}
						</Box>

						{media.length > 0 && (
							<div>
								<Box
									display={"flex"}
									alignItems={"center"}
									overflow={"hidden"}
									mb={4}
								>
									<Box mr={2}>
										<ButtonBase
											component={Link}
											to={"/pros/profile/edit-media"}
											style={{ borderRadius: 8 }}
										>
											<Box
												width={100}
												height={100}
												display={"flex"}
												alignItems={"center"}
												justifyContent={"center"}
												border={2}
												borderColor={"grey.200"}
												borderRadius={8}
											>
												<AddCircleOutlineOutlinedIcon color={"primary"} />
											</Box>
										</ButtonBase>
									</Box>

									{media.map((item) => (
										<Box key={item.key} mr={2}>
											<Avatar
												className={classes.profileMediaThumbs}
												variant={"rounded"}
												imgProps={{ loading: "lazy", decoding: "async" }}
												src={
													item[item.media_type].value ||
													item[item.media_type].thumbnail
												}
											/>
										</Box>
									))}
								</Box>

								<div>
									<Button
										component={Link}
										to={"/pros/profile/edit-media"}
										size={"large"}
										color="primary"
									>
										View all
									</Button>
								</div>
							</div>
						)}

						{media.length === 0 && (
							<Box mb={4}>
								<Box mb={6}>
									Include photos of your work, team, workspace, or equipment.
								</Box>
								<Button
									component={Link}
									disableElevation
									to={"/pros/profile/edit-media"}
									size={"large"}
									color="primary"
									variant={"contained"}
								>
									Add
								</Button>
							</Box>
						)}

						<Box pt={4} pb={4}>
							<Divider light={true} />
						</Box>

						<Box display={"flex"} alignItems={"center"}>
							<Box mr={2} minWidth={40} display={"flex"} alignItems={"center"}>
								<TrendingUpIcon style={{ fontSize: 36 }} />
							</Box>
							<Box fontSize={14} color={"grey.700"}>
								Other {proAdjective.plural} in your market have{" "}
								<strong>21 photos and videos</strong> on average
							</Box>
						</Box>
					</Box>
				</Paper>
			</Box>
		</React.Fragment>
	);
};

ProfileMedia.propTypes = {
	media: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			media_type: PropTypes.oneOf(["picture", "video"]).isRequired,
			picture: PropTypes.shape({
				value: PropTypes.string.isRequired,
			}),
			video: PropTypes.shape({
				thumbnail: PropTypes.string.isRequired,
			}),
		})
	),
};

export default ProfileMedia;
