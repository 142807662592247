const { httpRegex } = require("./regex");

class UrlFormater {
	cosntructor(url) {
		this._url = url;
	}

	static withHttp(url) {
		const hasHttp = httpRegex.test(url);
		return hasHttp ? url : "http://" + url;
	}
}

export default UrlFormater;
