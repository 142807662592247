import loadable from "@loadable/component";
import React, { useEffect } from "react";
import { observer } from "mobx-react";

// Hooks
import useStores from "@hooks/useStores";
import useSuccess from "@hooks/useSuccess";
import useError from "@hooks/useError";
import useConstants from "@hooks/useConstants";
import useStaticContext from "@hooks/useStaticContext";

// Components
import Footer from "@components/Main/Shared/Footer";
import HeaderSearchBar from "@components/Main/Shared/HeaderSearchBar";
import SchemaOrg from "@components/Global/Custom/SchemaOrg";
import { LeadFunnelModal } from "@components/Main/Shared/LeadFunnel";
import ProfileBackground from "./ProfileBackground";
import ProfileMainSection from "./ProfileMainSection";
import ProfileBusinessInfo from "./ProfileBusinessInfo";
import ProfileCredentials from "./ProfileCredentials";
import ProfileIntroduction from "./ProfileIntroduction";
import ProfilePhotos from "./ProfilePhotos";
import ProfilePhotosFeatured from "./ProfilePhotosFeatured";
import ProfileNav from "./ProfileNav";
import ProfileReviews from "./ProfileReviews";
import ProfileQA from "./ProfileQA";
import ProfileLocationHours from "./ProfileLocationHours";
import ProfileServicesOffered from "./ProfileServicesOffered";
import ProfileFooter from "./ProfileFooter";
import ProfileLinks from "./ProfileLinks";
import ProfileScrollUp from "./ProfileScrollUp";
import ProfileReviewsList from "./ProfileReviewsList";
import ProfileActionsPanel from "./ProfileActionsPanel";
import ImageDialog from "@components/Global/Custom/ImageDialog";
import SendProjectDialog from "@components/Global/Custom/SendProject";

// import ProfilePhotosFeatured from "./ProfilePhotosFeatured";
// import ProfileProjects from "./ProfileProjects";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// SEO
import schema from "./@data/schema.org";
import ProfileLicensing from "./ProfileLicensing";
import ProfileInsurance from "./ProfileInsurance";
import ProfileServicePreferences from "./ProfileServicePreferences";
import ProfileResume from "./ProfileResume";

const Profile = observer(() => {
	const stores = useStores();
	const {
		profileStore: store,
		profileActionsPanelStore,
		profilePhotosStore,
		profileLicenseStore,
		profileResumeStore,
	} = stores;

	const { midtail } = useStaticContext();

	const [SuccessTemplate, onSuccess] = useSuccess({ _id: "SEOProfile" });
	const [ErrorTemplate, onError] = useError({ _id: "SEOProfile" });

	useEffect(() => {
		profileActionsPanelStore.init().catch(onError);
	}, [store.service]);

	const {
		showProfileActionsPanel,
		showProfileNav,
		showProfileIntroduction,
		showProfileCredentials,
		showProfileLocationHours,
		showProfileQA,
		showProfileServicesOffered,
		showProfileLicensing,
		showProfileInsurance,
		showProfileServicePreferences,
		showProfileResume,
	} = useConstants("ProfileViewModel");

	return (
		<React.Fragment>
			<HeaderSearchBar />

			<ImageDialog />
			<ProfileReviewsList />
			<ProfileBackground>
				{/* <ProfilePhotosFeatured /> */}
				<ProfileMainSection service={store.service} zipcode={store.zipcode}>
					{store.provider && (
						<React.Fragment>
							<ProfileBusinessInfo provider={store.provider} />

							<Box display={{ xs: "block", sm: "block", md: "none" }}>
								<ProfileActionsPanel onSuccess={onSuccess} onError={onError} />
							</Box>

							{showProfileNav && <ProfileNav />}

							{showProfileIntroduction && (
								<ProfileIntroduction provider={store.provider} />
							)}

							{showProfileCredentials && (
								<ProfileCredentials provider={store.provider} />
							)}

							{store.shouldShowDetails && (
								<ProfileServicePreferences
									questionsAndAnswers={store.provider_questions_answers}
								/>
							)}

							{showProfileLocationHours && <ProfileLocationHours />}

							{profilePhotosStore.media.length !== 0 &&
								!profilePhotosStore.hidePicturesVideos(store.provider) && (
									<ProfilePhotos />
								)}

							{showProfileInsurance && (
								<ProfileInsurance provider={store.provider} />
							)}

							<ProfileReviews
								provider={store.provider}
								reviews={store.reviews}
							/>
							{showProfileResume && profileResumeStore.hasResumes && (
								<ProfileResume />
							)}
							{showProfileLicensing && profileLicenseStore.hasLicenses && (
								<ProfileLicensing />
							)}

							{showProfileQA && <ProfileQA provider={store.provider} />}

							{showProfileServicesOffered && (
								<ProfileServicesOffered provider={store.provider} />
							)}

							<SchemaOrg
								schema={schema({
									provider: store.provider,
									reviews: store.reviews,
									cityServiceStat: store.cityServiceStat,
								})}
							/>
						</React.Fragment>
					)}
				</ProfileMainSection>
				<Grid item xs={12} sm={12} md={4}>
					<Box
						display={{ xs: "none", sm: "none", md: "block" }}
						height="100%"
						flex={"1 1 auto"}
					>
						<ProfileActionsPanel onSuccess={onSuccess} onError={onError} />
					</Box>
				</Grid>
			</ProfileBackground>

			{store.service && (
				<LeadFunnelModal
					midtail={midtail}
					provider={store.provider}
					zipcode={store.zipcode}
					service={store.service}
				/>
			)}
			<ProfileFooter provider={store.provider} />
			<ProfileLinks />

			<ProfileScrollUp onSuccess={onSuccess} />
			<Footer />

			<SuccessTemplate
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				autoHideDuration={2000}
			/>
			<ErrorTemplate />

			<SendProjectDialog />
		</React.Fragment>
	);
});

export default Profile;

export const loader = (route) =>
	loadable(() => import("./Profile.component"), { ssr: !!route.ssr });
