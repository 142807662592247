export default (theme) => ({
	root: {
		flexGrow: 1,
	},
	AppBar: {
		color: theme.palette.secondary.main,
		backgroundColor: "#FFF",
		//borderBottom: "1px solid #e8eaec",
		boxShadow: "none",
	},
	Toolbar: {
		paddingLeft: "16px",
		paddingRight: "16px",
	},
	iconBox: {
		borderRight: "1px solid #e8eaec",
		[theme.breakpoints.down("xs")]: {
			borderRight: 0,
		},
	},
	iconWrap: {
		margin: 0,
	},
	icon: {
		width: "35px",
		borderRadius: "50%",
	},
	funnelContainer: {
		paddingLeft: 16,
		paddingRight: 16,
	},

	funnelWrapper: {
		"minHeight": "calc(100vh - 68px)",
		"padding": "40px 0px 120px",
		"backgroundColor": "#F6F6F6",
		[theme.breakpoints.down("xs")]: {
			padding: "16px 0px 200px",
			backgroundColor: "#FFF",
		},
		"& li": {
			marginBottom: 4,
		},
	},
	funnelPaper: {
		padding: "56px 72px 72px",
		borderTop: "4px solid #222222",
		[theme.breakpoints.down("xs")]: {
			//padding: "24px 32px 40px",
			boxShadow: "unset",
			borderTop: "unset !important",
			padding: 0,
		},
	},
	funnelPaperConfirm: {
		marginTop: 32,
		position: "relative",
		[theme.breakpoints.down("xs")]: {
			marginTop: 40,
		},
	},

	funnelPaperJobPreference: {
		padding: "42px 72px 30px",
		borderTop: "4px solid #222222 !important",
		marginBottom: 18,
		borderWidth: 2,
		[theme.breakpoints.down("xs")]: {
			//padding: "24px 32px 40px",
			boxShadow: "unset",
			border: "unset",
			borderTop: "unset !important",
			padding: 0,
		},
	},
	funnelPaperJobPreferenceQ: {
		padding: "30px 72px 20px",
		marginBottom: 18,
		borderWidth: 2,
		[theme.breakpoints.down("xs")]: {
			//padding: "24px 32px 40px",
			boxShadow: "unset",
			border: "unset",
			padding: 0,
		},
	},
	funnelHeader: {
		fontSize: 30,
		lineHeight: 1.3,
		paddingBottom: 16,
		fontWeight: 600,
		[theme.breakpoints.down("xs")]: {
			fontSize: 24,
		},
	},
	funnelSubHeader: {
		fontSize: 16,
		lineHeight: 1.5,
		[theme.breakpoints.down("xs")]: {
			fontSize: 14,
		},
	},
	apertureIcon: {
		fontSize: 50,
		[theme.breakpoints.down("xs")]: {
			fontSize: 40,
		},
	},
	textFieldLabel: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 8,
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
		},
	},
	packageBox: {
		padding: 24,
		[theme.breakpoints.down("xs")]: {
			padding: "24px 16px",
		},
	},
	packageLabel: {
		fontWeight: 700,
	},
	chipGreen: {
		color: "#0d8d33",
		backgroundColor: "#e2f9e9",
		fontSize: 12,
		fontWeight: 700,
		marginBottom: 8,
		marginRight: 6,
		//cursor: "pointer",
	},
	iconGreen: {
		color: "#0d8d33",
	},
	chipBlue: {
		color: "#0e6be6",
		backgroundColor: "#e8f0fe",
		fontSize: 12,
		fontWeight: 700,
		marginBottom: 8,
		marginRight: 6,
		//cursor: "pointer",
	},
	chipRed: {
		color: theme.palette.error.main,
		backgroundColor: "#f9e2e2",
		fontSize: 12,
		fontWeight: 700,
		marginBottom: 8,
		marginRight: 6,
		//cursor: "pointer",
	},
	iconRed: {
		color: theme.palette.error.main,
	},
	listPadding: {
		paddingTop: 22,
		paddingBottom: 22,
		paddingRight: 100,
		borderBottom: "1px solid #eee",
	},
	listItemIcon: {
		minWidth: 32,
	},
	disableGuttersMobile: {
		[theme.breakpoints.down("xs")]: {
			marginLeft: -16,
			marginRight: -16,
		},
	},
	hoursDone: {
		[theme.breakpoints.up("sm")]: {
			order: 3,
		},
	},
	hoursSelect: {
		[theme.breakpoints.up("sm")]: {
			order: 2,
		},
	},
	hoursApply: {
		[theme.breakpoints.up("sm")]: {
			order: 5,
		},
	},
	hoursApplySpacer: {
		order: 4,
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	toolTip: {
		fontSize: 12,
		lineHeight: 1.5,
	},
	exitModalHeader: {
		fontSize: 24,
		lineHeight: 1.6,
		[theme.breakpoints.down("xs")]: {
			fontSize: 22,
		},
	},
	profilePicture: {
		cursor: "pointer",
		width: 140,
		height: 140,
	},
	profileHeader: {
		fontSize: 20,
		lineHeight: 1.5,
		[theme.breakpoints.down("xs")]: {
			fontSize: 18,
		},
	},
	//FAQ
	accordionRoot: {
		"boxShadow": "unset",
		"borderBottom": "1px solid #dddddd",
		"&:before": {
			height: 0,
		},
		"&:last-child": {
			borderBottom: 0,
		},
	},
	accordionSRoot: {
		padding: 0,
	},
	accordionSContent: {
		margin: "24px 0px",
		fontSize: 16,
		fontWeight: 600,
	},
	accordionDetailsRoot: {
		padding: "0px 0px 36px",
		fontSize: 14,
		lineHeight: 1.6,
		color: theme.palette.grey[700],
	},
	listPrimary: {
		fontWeight: 600,
	},
	bold600: {
		fontWeight: 600,
	},
});
