import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import { Divider, Box, Button, Paper, Grid, Link } from "@material-ui/core";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ProfileLicensingDialog from "./ProfileLicensingDialog";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import useStores from "@libs/framework/src/hooks/useStores";

const { BRAND_NAME, LINK_HELP } = process.env;

const ProfileLicensing = observer(() => {
	const classes = useStyles("Profile");
	const stores = useStores();

	const { profileLicenseStore: store } = stores;

	const [openDialog, setOpenopenDialog] = useState(false);

	const handleClickOpen = () => {
		setOpenopenDialog(true);
	};

	const handleClose = () => {
		setOpenopenDialog(false);
	};

	return (
		<React.Fragment>
			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<a className="anchor" id="license" />
			<Box my={6} component="section">
				<h2 className={classes.profileH2}>Licensing and credentials</h2>

				{!store.hasLicenses && (
					<section>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="flex-start"
							pt={3}
							pb={6}
						>
							<VerifiedUserOutlinedIcon />
							<Box ml={4} lineHeight={1.5} fontSize={16}>
								<strong>
									{BRAND_NAME} has not verified this business license.
								</strong>{" "}
								We strongly encourage you to verify their license,
								qualifications, and credentials by contacting this provider or
								the state licensing department directly. Any other information,
								including accreditation, hours, and cost, may not reflect its
								current status. We’re{" "}
								<Link underline="hover" target="_blank" href={LINK_HELP}>
									here to help
								</Link>{" "}
								your search go smoothly.
							</Box>
						</Box>
					</section>
				)}

				{store.hasLicenses && (
					<>
						<Box mt={8}>
							<Grid container spacing={8}>
								{store.licenses.map((license) => {
									return (
										<Grid item xs={12} sm={12} md={6} key={license.number}>
											<Paper
												variant="outlined"
												className={classes.licensePaper}
											>
												<div className={classes.licenseHead}>
													<VerifiedUserIcon
														fontSize="small"
														className={classes.licenseIcon}
													/>
													<div className={classes.licenseTitle}>License</div>
												</div>
												{license.type && (
													<div className={classes.licenseFact}>
														<span>Type:</span> {license.type} ({license.state})
													</div>
												)}
												{license.issuedAt && (
													<div className={classes.licenseFact}>
														<span>Issued:</span>{" "}
														{store.dateFormatString(license.issuedAt)}
													</div>
												)}
												{license.expiresAt && (
													<div className={classes.licenseFact}>
														<span>Valid through:</span>{" "}
														{store.dateFormatString(license.expiresAt)}
													</div>
												)}
												{license.verifiedAt && (
													<div className={classes.licenseFact}>
														<span>Verified:</span>{" "}
														{store.dateFormatString(license.verifiedAt)}
													</div>
												)}
												{license.capacity && (
													<div className={classes.licenseFact}>
														<span>Capacity:</span> {license.capacity}
													</div>
												)}
											</Paper>
										</Grid>
									);
								})}
								{store.hasCertificates && (
									<Grid item xs={12} sm={12} md={6}>
										<Paper variant="outlined" className={classes.licensePaper}>
											<div className={classes.licenseHead}>
												<VerifiedUserIcon
													fontSize="small"
													className={classes.licenseIcon}
												/>
												<div className={classes.licenseTitle}>
													Certification
												</div>
											</div>
											<div className={classes.licenseFact}>
												<span>Type:</span> Professional Golfers' Association
												(PGA) (TX)
											</div>
											{/* wrap in if... */}
											<div className={classes.licenseFact}>
												<span>Issued:</span> 8/17/2022
											</div>
											{/* wrap in if... */}
											<div className={classes.licenseFact}>
												<span>Valid through:</span> 8/17/2025
											</div>
											{/* wrap in if... */}
											<div className={classes.licenseFact}>
												<span>Verified:</span> 5/17/2023
											</div>
											<br />
											<img
												src="https://res.cloudinary.com/pgahq/image/upload/v1580922160/front-end-assets/pgaMemberLogo300x300.png"
												width="100"
											/>
										</Paper>
									</Grid>
								)}
							</Grid>
						</Box>
						<Box mt={6}>
							<Button
								color="primary"
								size="large"
								className={classes.showAllButton}
								onClick={handleClickOpen}
							>
								View details
							</Button>

							{openDialog && (
								<ProfileLicensingDialog
									close={handleClose}
									open={handleClickOpen}
								/>
							)}
						</Box>
					</>
				)}
			</Box>
		</React.Fragment>
	);
});

ProfileLicensing.propTypes = {};

export default ProfileLicensing;
