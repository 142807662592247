import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";

// Utils
import { replaceUrlParam, getParameterByName } from "@utils/Browser";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// MUI Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Config
import { adjective as proAdjective } from "@language/provider";

const ProfileMainSection = ({ service, zipcode, ...props }) => {
	const classes = useStyles("Profile");
	const location = useLocation();
	const [moreProsLink, setMoreProsLink] = useState("");

	useEffect(() => {
		if (getParameterByName("leadKey"))
			return setMoreProsLink(
				`/client/projects/${getParameterByName("leadKey")}`
			);

		if (!service) return;
		let url = replaceUrlParam(
			`/search${location.search}`,
			"service",
			service.key
		);
		url = replaceUrlParam(url, "zipcode", zipcode);
		url = replaceUrlParam(url, "leadKey", "");
		setMoreProsLink(url);
	}, []);

	return (
		<Grid item xs={12} sm={12} md={8}>
			<Box id="back-to-top-anchor" className={classes.profileMain}>
				<Box mb={5}>
					<a href={moreProsLink}>
						<Button color="primary" startIcon={<ArrowBackIcon />}>
							View all {proAdjective.plural}
						</Button>
					</a>
				</Box>
				{props.children}
			</Box>
		</Grid>
	);
};

ProfileMainSection.propTypes = {
	service: PropTypes.shape({
		key: PropTypes.string.isRequired,
	}),
	zipcode: PropTypes.string,
};

export default ProfileMainSection;
