import React from "react";
import PropTypes from "prop-types";

import ReviewsDialog from "../../Shared/ReviewsDialog/ReviewsDialog";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Carousel from "@components/Global/Custom/Carousel";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";

import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import IconButton from "@material-ui/core/IconButton";

import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import { adjective as proAdjective } from "@language/provider";

const { SITE_NAME, BRAND_NAME } = process.env;

const tutorialSteps = [
	{
		body: `${proAdjective.plural.ucFirst()} with at least 10 reviews have a much better chance of getting hired. It’s ok to ask customers who are not on ${BRAND_NAME}.`,
		cta: "Get reviews",
		url: null,
		onClick: null,
		icon: <StarBorderOutlinedIcon fontSize={"inherit"} />,
	},
	{
		body: `Become a certified ${proAdjective.singular} and get hired more. Add the ${BRAND_NAME} badge to your website to show off your credibility.`,
		cta: "Get badge",
		url: "/pros/settings/badges",
		onClick: null,
		icon: <StarsIcon fontSize={"inherit"} />,
	},
	{
		body: `Customers love photos. Add at least 10 to show off your skills, team, or workspace.`,
		cta: "Add photos",
		url: "/pros/profile/edit-media",
		onClick: null,
		icon: <PhotoOutlinedIcon fontSize={"inherit"} />,
	},
	{
		body: `Get a background check badge to build customer trust and get hired more.`,
		cta: "Get checked",
		url: "/pros/profile/authorize-background-check",
		onClick: null,
		icon: <VerifiedUserOutlinedIcon fontSize={"inherit"} />,
	},
];

const ProfileHeadCarousel = () => {
	const classes = useStyles("Profile");
	const theme = useTheme();
	const stores = useStores();

	tutorialSteps[0].onClick = () => {
		stores.reviewsDialogStore.showModal = true;
	};

	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = tutorialSteps.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<React.Fragment>
			<Box mb={6}>
				<Paper>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							pb={4}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Profile strength: Needs work
							</Box>
						</Box>
						<Box width={"100%"}>
							<LinearProgress
								variant="determinate"
								value={30}
								classes={{
									root: classes.profileProgressRoot,
									bar: classes.profileProgressBar,
									colorPrimary: classes.profileProgressColorPrimary,
								}}
							/>
						</Box>
					</Box>
					<div>
						<Divider light={true} />
					</div>
					<Box p={{ xs: "24px 0px", sm: "24px 8px" }}>
						<Box display={"flex"} alignItems={"center"}>
							<IconButton
								color={"secondary"}
								aria-label="Left"
								onClick={handleBack}
								disabled={activeStep === 0}
							>
								<KeyboardArrowLeft />
							</IconButton>

							<Box
								fontSize={40}
								display={{ xs: "none", sm: "flex" }}
								alignItems={"center"}
								justifyContent={"center"}
							>
								{tutorialSteps[activeStep].icon}
							</Box>

							<Box
								display={"flex"}
								alignItems={{ xs: "flex-start", sm: "center" }}
								flex={1}
								flexDirection={{ xs: "column", sm: "row" }}
								pl={{ xs: 0, sm: 4 }}
								pr={{ xs: 0, sm: 2 }}
							>
								<Box
									fontSize={{ xs: 12, sm: 14 }}
									lineHeight={1.5}
									pr={{ xs: 0, sm: 4 }}
									pb={{ xs: 2, sm: 0 }}
								>
									{tutorialSteps[activeStep].body}
								</Box>
								<Box flexShrink={0}>
									<Button
										href={tutorialSteps[activeStep].url}
										onClick={tutorialSteps[activeStep].onClick}
										disableElevation
										variant="contained"
										color="primary"
									>
										{tutorialSteps[activeStep].cta}
									</Button>
								</Box>
							</Box>
							<IconButton
								color={"secondary"}
								aria-label="Right"
								onClick={handleNext}
								disabled={activeStep === maxSteps - 1}
							>
								<KeyboardArrowRight />
							</IconButton>
						</Box>
					</Box>
				</Paper>
			</Box>
		</React.Fragment>
	);
};

ProfileHeadCarousel.propTypes = {};

export default ProfileHeadCarousel;
