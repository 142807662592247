import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let HouzzIcon = (props) => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<path d="M1.27 0V24H9.32V16.44H14.68V24H22.73V10.37L6.61 5.75V0H1.27Z" />
	</SvgIcon>
);
HouzzIcon = memo(HouzzIcon);
HouzzIcon.displayName = "HouzzIcon";
HouzzIcon.muiName = "HouzzIcon";

export default HouzzIcon;
