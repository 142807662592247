import React from "react";
import PropTypes from "prop-types";

const GreenCheckMark = ({className, height, width, ...props}) => {
    return (
        <svg className={className}
             xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
             version="1.1"
             id="Layer_1" x="0px" y="0px" viewBox="0 0 426.67 426.67"
             style={{enableBackground: "new 0 0 426.67 426.67"}}
             xmlSpace="preserve"
             width={width}
             height={height}
             {...props}>
            <g>
                <path
                    d="M153.504,366.839c-8.657,0-17.323-3.302-23.927-9.911L9.914,237.265  c-13.218-13.218-13.218-34.645,0-47.863c13.218-13.218,34.645-13.218,47.863,0l95.727,95.727l215.39-215.386  c13.218-13.214,34.65-13.218,47.859,0c13.222,13.218,13.222,34.65,0,47.863L177.436,356.928  C170.827,363.533,162.165,366.839,153.504,366.839z"
                    data-original="#F05228" className="active-path" data-old_color="#00A85E"
                    fill="#00A85D"/>
            </g>
        </svg>
    );
};

GreenCheckMark.defaultProps = {
    className: ""
};

GreenCheckMark.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default GreenCheckMark;