import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";

// Hooks
import withStyles from "@libs/framework/src/libs/withStyles";

// MUI
import Box from "@material-ui/core/Box";

// MUI Icons
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";

// Config
import { styles } from "./.config";

const FinalPriceInline = compose(withStyles(styles))(
	({ total, unit, description }) => {
		const displayChatBubble = !total;
		const subHeader = description;
		const displayUnitSpan = !!unit;
		return (
			<Box
				display={displayChatBubble ? "flex" : ""}
				alignItems={displayChatBubble ? "center" : ""}
				flexDirection={displayChatBubble ? "row" : ""}
			>
				{displayChatBubble && <ChatBubbleOutlineOutlinedIcon />}
				{!!total && (
					<>
						<Box component={"span"} fontSize={18} fontWeight={600}>
							${total}
						</Box>

						{displayUnitSpan && (
							<Box component={"span"} fontSize={14}>
								{" "}
								{unit}
							</Box>
						)}
					</>
				)}
				{subHeader && displayChatBubble ? (
					<Box component={"span"} fontSize={14} ml={2} display={"block"}>
						{subHeader}
					</Box>
				) : (
					<Box
						component={"span"}
						fontSize={14}
						ml={2}
						display={{ xs: "none", sm: "block" }}
					>
						{subHeader}
					</Box>
				)}
			</Box>
		);
	}
);

FinalPriceInline.propTypes = {
	unit: PropTypes.string,
	total: PropTypes.number,
	description: PropTypes.string,
};

export default FinalPriceInline;
