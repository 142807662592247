import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Grow from "@material-ui/core/Grow";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";

import Popper from "@material-ui/core/Popper";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import { adjective as clientAdjective } from "@language/client";

const { BRAND_NAME } = process.env;

const ProfileHeader = ({ profile, hidden }) => {
	const classes = useStyles("Profile");

	const empty = false;

	const [openMenu, setOpenMenu] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	return (
		<React.Fragment>
			<Box component="main" pt={4} pb={12}>
				<Box
					display={"flex"}
					alignItems={{ xs: "flex-start", sm: "center" }}
					justifyContent={"space-between"}
					flexDirection={{ xs: "column", sm: "row" }}
				>
					<Box display={"flex"} flexDirection={"column"}>
						<Box display={"flex"} alignItems={"center"}>
							<Box fontWeight={600} fontSize={{ xs: 28, sm: 32 }} mr={4}>
								Marketplace profile
							</Box>
						</Box>
						<Box mt={1} maxWidth={650} lineHeight={1.5}>
							{`Your ${BRAND_NAME} profile is your gateway to attracting millions of new ${clientAdjective.plural} and showcasing your services.`}
						</Box>
					</Box>
					<Box display={"flex"} alignItems={"center"} mt={{ xs: 4, sm: 0 }}>
						<Button
							size={"large"}
							color={"secondary"}
							variant="outlined"
							disableElevation
							endIcon={<ExpandMoreIcon />}
							ref={anchorRef}
							aria-controls={openMenu ? "menu-list-grow" : undefined}
							aria-haspopup="true"
							onClick={handleToggle}
							style={{ backgroundColor: "#FFF" }}
						>
							Manage
						</Button>
					</Box>
				</Box>

				<Popper
					open={openMenu}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					placement={"bottom-end"}
					style={{ zIndex: 1500 }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom-end" ? "bottom-end" : "bottom-end",
							}}
						>
							<Paper elevation={6} style={{ width: 330 }}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
										<MenuItem
											component={"a"}
											href={`/pros/services/`}
											onClick={handleClose}
										>
											<ListItemIcon className={classes.adminListItemIcon}>
												<FormatListBulletedOutlinedIcon />
											</ListItemIcon>

											<ListItemText
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
												primary={"Manage lead services"}
											/>
										</MenuItem>

										<MenuItem
											component={"a"}
											href={`/${profile.url}`}
											target="_blank"
											disabled={!profile.url}
											onClick={handleClose}
										>
											<ListItemIcon className={classes.adminListItemIcon}>
												<AccountCircleOutlinedIcon />
											</ListItemIcon>

											<ListItemText
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
												primary={"View live profile"}
											/>
										</MenuItem>

										<MenuItem
											component={"a"}
											href={`/pros/services/hide-business`}
											onClick={handleClose}
										>
											<ListItemIcon className={classes.adminListItemIcon}>
												<VisibilityOffOutlinedIcon />
											</ListItemIcon>

											<ListItemText
												classes={{
													primary: classes.listPrimary,
													secondary: classes.listSecondary,
												}}
												primary={
													hidden
														? "Unhide my business"
														: "Hide my business temporarily"
												}
											/>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Box>
		</React.Fragment>
	);
};

ProfileHeader.propTypes = {};
export default ProfileHeader;
