import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

// Hooks
import useStores from "@hooks/useStores";

// MUI
import Box from "@material-ui/core/Box";

// MUI Icons
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";

const ProfileLeadPanelPrices = observer(({}) => {
	const { profileLeadPanelPricesStore: store } = useStores();

	return (
		<div>
			<Box mb={2}>
				{store.displayChatBubble && (
					<ChatBubbleOutlineOutlinedIcon style={{ fontSize: 28 }} />
				)}
				{!!store.total && (
					<Box component={"span"} fontSize={22} fontWeight={600}>
						{`$${store.total}`}
					</Box>
				)}
				{store.displayUnitSpan && (
					<Box component={"span"} pl={1}>
						{store.unit}
					</Box>
				)}
			</Box>
			{store.subHeader && (
				<Box fontSize={12} color="grey.700">
					{store.subHeader.toLowerCase()}
				</Box>
			)}
		</div>
	);
});

ProfileLeadPanelPrices.propTypes = {};

export default ProfileLeadPanelPrices;
