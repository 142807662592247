import SharedStyles from "@components/Dashboard/Pro/Messages/@styles/Messages.styles";

export default (theme) => {
	const sharedStyles = SharedStyles(theme);
	return Object.assign(sharedStyles, {
		leadContent: {
			padding: "32px 16px 140px",
			display: "flex",
			backgroundColor: "#FAFAFA",
			flexDirection: "column",
			minHeight: "calc(100vh - 65px)",
			[theme.breakpoints.down("xs")]: {
				minHeight: "calc(100vh - 106px)",
			},
		},
		leadOpBox: {
			maxWidth: 800,
			[theme.breakpoints.down("md")]: {
				maxWidth: "100%",
			},
		},
		leadOpCard: {
			"borderRadius": 8,
			"marginBottom": 24,
			"backgroundColor": "#FFF",
			"boxShadow": theme.shadows[3],
			"&:hover": {
				boxShadow: theme.shadows[5],
				cursor: "pointer",
			},
		},
		leadOpHidden: {
			marginBottom: 24,
			display: "flex",
			justifyContent: "space-between",
		},
		leadOpCardPadding: {
			position: "relative",
			padding: 24,
		},
		leadOpDivider: {
			marginTop: 16,
			marginBottom: 16,
		},
		leadOpTimeBox: {
			position: "absolute",
			right: 24,
			top: 24,
			display: "flex",
			alignItems: "center",
		},
		leadOpTimeDot: {
			height: 8,
			width: 8,
			borderRadius: "50%",
			marginRight: 8,
			backgroundColor: theme.palette.primary.main,
		},
		leadOpViewDot: {
			borderRadius: "50%",
			height: 8,
			width: 8,
			backgroundColor: theme.palette.primary.main,
			position: "absolute",
			left: 12,
			top: 32,
		},
		leadOpTime: {
			fontWeight: 700,
			fontSize: 14,
			color: theme.palette.primary.main,
		},
		leadOpTimeViewed: {
			fontWeight: 700,
			fontSize: 14,
			color: theme.palette.secondary.main,
		},
		// leadOpIcon: {
		// 	fontSize: 40,
		// },
		leadClientName: {
			textTransform: "capitalize",
		},
		leadOpTitle: {
			fontWeight: 700,
			fontSize: 24,
			lineHeight: 1.5,
			marginTop: 4,
			marginBottom: 16,
			[theme.breakpoints.down("xs")]: {
				fontSize: 22,
			},
		},
		leadOpContacted: {
			fontSize: 16,
			lineHeight: "24px",
			marginBottom: 8,
		},
		leadOpBold: {
			fontWeight: 700,
		},
		leadOpTimeElapsed: {
			fontSize: 12,
			lineHeight: "18px",
			color: theme.palette.grey[600],
		},
		leadOpDetailsBox: {
			marginBottom: 24,
		},
		leadOpDetailsFont: {
			fontSize: 14,
			lineHeight: "22px",
		},
		leadOpCityBox: {
			display: "flex",
			alignItems: "center",
		},
		leadOpDateBox: {
			display: "flex",
			alignItems: "flex-start",
			marginTop: 12,
		},
		leadOpService: {
			fontSize: 18,
			fontWeight: 600,
			marginBottom: 16,
		},
		leadOpIconSpacing: {
			marginLeft: 20,
			overflow: "hidden",
		},
		leadOpArticle: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			flex: "1 1 100%",
			fontSize: 14,
			lineHeight: "22px",
		},
		leadOpHead: {
			flexBasis: "auto",
			padding: "24px 28px",
			position: "relative",
			order: 1,
			width: "60%",
			borderBottom: "1px solid rgb(224, 224, 224)",
			[theme.breakpoints.down("xs")]: {
				width: "100%",
				flexBasis: "100%",
			},
		},
		leadOpButtonPosition: {
			position: "absolute",
			bottom: 24,
			right: 24,
		},
		leadOpCTA: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexBasis: "auto",
			order: 2,
			width: "40%",
			paddingRight: 24,
			borderBottom: "1px solid rgb(224, 224, 224)",
			[theme.breakpoints.down("xs")]: {
				order: 3,
				flexBasis: "100%",
				width: "100%",
				paddingRight: 0,
				alignItems: "flex-end",
				borderBottom: "none",
				borderTop: "1px solid rgb(224, 224, 224)",
			},
		},
		leadOpDetails: {
			padding: 24,
			width: "100%",
			order: 3,
			[theme.breakpoints.down("xs")]: {
				order: 2,
			},
		},
		leadOpButtonView: {
			marginLeft: 16,
			[theme.breakpoints.down("xs")]: {
				flex: 1,
				marginLeft: 0,
				borderLeft: "1px solid rgb(224, 224, 224)",
				borderRadius: 0,
			},
		},
		leadOpButton: {
			[theme.breakpoints.down("xs")]: {
				flex: 1,
				borderRadius: 0,
				textAlign: "center",
			},
		},
		leadOpPic: {
			width: 70,
			height: 70,
			marginRight: 10,
			[theme.breakpoints.down("xs")]: {
				width: 60,
				height: 60,
			},
		},
		leadOpChipRoot: {
			fontWeight: 600,
			marginBottom: 8,
			marginRight: 6,
			height: "inherit",
			padding: "6px 0px",
			whiteSpace: "normal",
			cursor: "pointer",
		},
		leadOpChipLabel: {
			whiteSpace: "normal",
		},
		leadDetailsBox: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			marginTop: 16,
		},
		leadDetailsBoxTitle: {
			fontSize: 14,
			lineHeight: 1.5,
			fontWeight: 600,
		},
		leadDetailsItemList: {
			display: "flex",
			flexDirection: "column",
			marginLeft: 8,
			flexGrow: 1,
		},
		leadDetailsItem: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			fontSize: 14,
			lineHeight: 1.5,
			color: "#616161",
		},
		leadDetailsPrice: {
			fontSize: 14,
			fontWeight: 600,
			color: theme.palette.grey[600],
		},
		leadDetailsCheck: {
			color: theme.palette.success.main,
		},
		leadDetailsBolt: {
			color: theme.palette.warning.main,
		},
		leadDetailsDivider: {
			margin: "24px -24px",
			height: 8,
			backgroundColor: "#ebebeb",
		},
		notpaidChip: {
			fontWeight: 700,
		},
		paidChip: {
			color: "#FFF",
			backgroundColor: theme.palette.success.main,
			fontWeight: 700,
		},
		wrapper: {
			margin: theme.spacing(1),
			position: "relative",
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		leadsNavBadgeRoot: {
			marginLeft: 6,
		},
		leadsNavBadge: {
			position: "relative",
			transform: "unset",
			transformOrigin: "unset",
		},
	});
};
