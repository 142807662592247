import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";
import useConstants from "@hooks/useConstants";

// MUI
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
//import Divider from "@material-ui/core/Divider";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
//import ProfileCredentials from "./ProfileCredentials";

const ProfileIntroduction = observer(({ provider }) => {
	const stores = useStores();
	const { profileActionsPanelStore } = stores;
	const classes = useStyles("Profile");

	const { showProfileActionsPanel } = useConstants("ProfileViewModel");

	const [open, setOpen] = useState(false);
	const handleClick = () => setOpen(!open);

	const showActionButtons =
		profileActionsPanelStore.currentPanelState === "default";

	const startLeadHandler = (type) => (e) => {
		stores.leadFunnelModalStore.leadActionType = type;
		stores.leadFunnelModalStore.show = true;
	};

	const intro = provider.intro
		?.split("\n")
		.filter((el) => el !== "")
		.map((el, index) => <p key={index}>{el}</p>);

	return (
		<React.Fragment>
			<Box pb={5} component="section" position={"relative"}>
				<h2 className={classes.profileH2}>About</h2>

				{provider.intro?.length > 660 ? (
					<React.Fragment>
						<Collapse
							in={open}
							collapsedSize={200}
							timeout="auto"
							classes={{
								root: clsx({
									[classes.profileAboutCollapse]: !open,
								}),
							}}
						>
							<Box className={`${classes.intro} ${classes.profileBodyText}`}>
								{intro}
							</Box>
						</Collapse>

						<Box display="flex" alignItems="center" mt={3}>
							<Link
								color="secondary"
								className={classes.showMore}
								onClick={handleClick}
							>
								<Box display="flex" alignItems="center">
									Show {open ? "less" : "more"}{" "}
									{open ? (
										<ExpandLessIcon fontSize="small" />
									) : (
										<ExpandMoreIcon fontSize="small" />
									)}
								</Box>
							</Link>
						</Box>
					</React.Fragment>
				) : (
					<Box className={`${classes.intro} ${classes.profileBodyText}`}>
						{intro}
					</Box>
				)}

				{showProfileActionsPanel && (
					<>
						{showActionButtons && (
							<Box
								display={{ xs: "none", sm: "flex" }}
								justifyContent="space-between"
								flexDirection="row"
								mt={8}
							>
								<div className={classes.flexBasis}>
									<Button
										onClick={startLeadHandler("requested_message")}
										fullWidth
										variant="outlined"
										color="primary"
										size="large"
										startIcon={<SmsOutlinedIcon />}
										className={classes.buttonCTA}
									>
										Message
									</Button>
								</div>
								<div className={classes.flexBasis}>
									<Button
										onClick={startLeadHandler("requested_call")}
										fullWidth
										variant="outlined"
										color="primary"
										size="large"
										startIcon={<CallOutlinedIcon />}
										className={classes.buttonCTA}
									>
										Request a call
									</Button>
								</div>
							</Box>
						)}

						{showActionButtons && (
							<Box
								display={{ xs: "flex", sm: "none" }}
								justifyContent="space-between"
								flexDirection="row"
								mt={8}
							>
								<div className={classes.flexBasis}>
									<Button
										onClick={startLeadHandler("requested_message")}
										fullWidth
										variant="outlined"
										color="primary"
										startIcon={<SmsOutlinedIcon />}
										className={classes.buttonCTA}
									>
										Message
									</Button>
								</div>
								<div className={classes.flexBasis}>
									<Button
										onClick={startLeadHandler("requested_call")}
										fullWidth
										variant="outlined"
										color="primary"
										startIcon={<CallOutlinedIcon />}
										className={classes.buttonCTA}
									>
										Request a call
									</Button>
								</div>
							</Box>
						)}
					</>
				)}
			</Box>
		</React.Fragment>
	);
});

ProfileIntroduction.propTypes = {
	provider: PropTypes.shape({
		intro: PropTypes.string.isRequired,
	}).isRequired,
};

export default ProfileIntroduction;
