import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

// MUI Icons
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import StarIcon from "@material-ui/icons/Star";

// MUI
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

// Components
import ProfileLeadPanelPrices from "../ProfileLeadPanelPrices";

// Language
import { adjective as proAdjective } from "@language/provider";

const BidActionPanel = () => {
	const classes = useStyles("ProfileActionPanel");
	const { profileActionsPanelStore: store } = useStores();

	return (
		<aside className={`${classes.leadPanelBox}`}>
			<Box
				display={"flex"}
				alignItems={"flex-start"}
				justifyContent="space-between"
				mb={2}
			>
				<div>
					<ProfileLeadPanelPrices />
				</div>
				<Box ml={2} pt={1}>
					<Chip
						size="small"
						icon={<ThumbUpOutlinedIcon className={classes.iconGreen} />}
						label="Interested"
						className={classes.chipGreen}
					/>
				</Box>
			</Box>

			<Box py={4}>
				<Divider light={true} />
			</Box>

			<Box display={"flex"} alignItems={"flex-start"}>
				<div>
					<Box fontWeight={600}>Personalized pitch:</Box>
					<Box
						className={`${classes.profileBodyText} ${classes.profileActionsText}`}
						mt={2}
					>
						{store.quote.first_provider_message?.message}
					</Box>
				</div>
			</Box>

			<Box pt={6}>
				<Button
					href={`/client/inbox/${store.leadKey}/${store.quote.key}`}
					fullWidth
					disableElevation
					variant="contained"
					color="primary"
					size="large"
					className={classes.buttonCTA}
					startIcon={<SmsOutlinedIcon />}
				>
					{`Message ${proAdjective.singular}`}
				</Button>
			</Box>

			{/* 			<Box py={6}>
				<Divider light={true} />
			</Box>

			<Box display={"flex"} alignItems={"flex-start"}>
				<Box mr={2}>
					<StarIcon className={classes.iconStar} />
				</Box>
				<Box fontWeight={700} fontSize={18} lineHeight={1.3}>
					{`How was working with this ${proAdjective.singular}?`}
				</Box>
			</Box>

			<Box mt={4}>
				<Button
					href="/xxx"
					target={"_blank"}
					fullWidth
					disableElevation
					variant="outlined"
					color="primary"
					size="large"
					className={classes.buttonCTA}
				>
					Write a review
				</Button>
			</Box> */}
		</aside>
	);
};

export default BidActionPanel;
