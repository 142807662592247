import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

// Hooks
import useStores from "@hooks/useStores";

// Components
import DefaultActionPanel from "./Templates/DefaultActionPanel";
import BidActionPanel from "./Templates/BidActionPanel";
import DirectActionPanel from "./Templates/DirectActionPanel";
import OneClickBlastActionPanel from "./Templates/OneClickBlastActionPanel";
import DeclinedActionPanel from "./Templates/DeclinedActionPanel";

const ProfileActionPanel = observer(({ onError, onSuccess }) => {
	const { profileActionsPanelStore: store } = useStores();

	switch (store.currentPanelState) {
		case "hasDirect":
			return <DirectActionPanel />;
		case "hasBid":
			return <BidActionPanel />;
		case "oneClickBlast":
			return <OneClickBlastActionPanel onSuccess={onSuccess} />;
		case "declined":
			return <DeclinedActionPanel />;
		default:
			return <DefaultActionPanel />;
	}
});

export default ProfileActionPanel;
