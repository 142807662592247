import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import useStyles from "@hooks/useStyles";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import { adjective as proAdjective } from "@language/provider";
import { Link } from "react-router-dom";

const ProfileCredentials = () => {
	const classes = useStyles("Profile");

	return (
		<React.Fragment>
			<Paper>
				<Box mb={6} overflow={"hidden"}>
					<Box p={6}>
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"space-between"}
							pb={4}
						>
							<Box
								component={"div"}
								fontWeight={700}
								className={classes.profileHeader}
							>
								Credentials
							</Box>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							flexWrap={{ xs: "wrap", sm: "nowrap" }}
						>
							<Box
								flex={"1 1 auto"}
								mr={{ xs: 0, sm: 6 }}
								mb={{ xs: 4, sm: 0 }}
							>
								<Box display={"flex"} alignItems={"flex-start"}>
									<Box
										mr={2}
										minWidth={40}
										display={"flex"}
										alignItems={"center"}
									>
										<VerifiedUserOutlinedIcon style={{ fontSize: 36 }} />
									</Box>
									<div>
										<Box fontWeight={700} mb={2}>
											Background check
										</Box>
										<Box fontSize={14} color={"grey.700"} lineHeight={1.5}>
											{`Add a background check badge to your profile to help win customer's trust and get hired more.`}
										</Box>
									</div>
								</Box>
							</Box>

							<Box
								width={{ xs: "100%", sm: "auto" }}
								display={"flex"}
								alignItems={"center"}
								height={"100%"}
								flexShrink={0}
								ml={{ xs: 12 }}
							>
								<Button
									to={"/pros/profile/authorize-background-check"}
									component={Link}
									disableElevation
									size={"large"}
									color="primary"
									variant={"contained"}
								>
									Start
								</Button>
							</Box>
						</Box>

						<Box pt={4} pb={4}>
							<Divider light={true} />
						</Box>

						<Box
							display={"flex"}
							alignItems={"center"}
							flexWrap={{ xs: "wrap", sm: "nowrap" }}
						>
							<Box
								flex={"1 1 auto"}
								mr={{ xs: 0, sm: 6 }}
								mb={{ xs: 4, sm: 0 }}
							>
								<Box display={"flex"} alignItems={"flex-start"}>
									<Box
										mr={2}
										minWidth={40}
										display={"flex"}
										alignItems={"center"}
									>
										<FeaturedPlayListOutlinedIcon style={{ fontSize: 36 }} />
									</Box>
									<div>
										<Box fontWeight={700} mb={2}>
											Licenses and certifications
										</Box>
										<Box fontSize={14} color={"grey.700"} lineHeight={1.5}>
											{`Customers prefer to hire ${proAdjective.plural} who are licensed or certified in their profession.`}
										</Box>
									</div>
								</Box>
							</Box>

							<Box
								width={{ xs: "100%", sm: "auto" }}
								display={"flex"}
								alignItems={"center"}
								height={"100%"}
								flexShrink={0}
								ml={{ xs: 12 }}
							>
								<Button
									disableElevation
									size={"large"}
									color="primary"
									variant={"contained"}
								>
									Add
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Paper>
		</React.Fragment>
	);
};

ProfileCredentials.propTypes = {};

export default ProfileCredentials;
