import React from "react";
import PropTypes from "prop-types";

import useStores from "@libs/framework/src/hooks/useStores";

import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";

const { SITE_NAME } = process.env;

const ProfileFooter = ({}) => {
	const { profileFooterStore: store } = useStores();

	let serviceAndCity, service;
	if (
		store.service.active &&
		store.city.active &&
		store.cityServiceStat.active
	) {
		serviceAndCity = {
			label: `${store.service.phrase} ${store.city?.name?.ucFirst()}`,
			href: `${store.city?.url}${store.service?.url}/`,
			linkable: true,
		};

		service = {
			label: `${store.service.phrase} near me`,
			href: `${store.service.url}`,
			linkable: !store.service.hide,
		};
	} else if (store.service.active) {
		serviceAndCity = {
			label: `${store.service.phrase} near me`,
			href: `${store.service?.url}`,
			linkable: !store.service.hide,
		};
	} else if (store.city.active) {
		serviceAndCity = {
			label: `${store.city?.name?.ucFirst()}`,
			href: `${store.city?.url}/`,
			linkable: true,
		};
	}

	return (
		<Box
			component="section"
			py={8}
			pl={{ xs: 4, sm: 10 }}
			pr={{ xs: 4, sm: 10 }}
		>
			<Container maxWidth="lg" disableGutters>
				<Box display={"flex"} alignItems={"center"}>
					{serviceAndCity && (
						<Breadcrumbs aria-label="breadcrumb" color={"secondary"}>
							<Link color="inherit" href="/" underline={"always"}>
								{SITE_NAME.ucFirst()}
							</Link>
							{service && (
								<Link
									color="inherit"
									href={service.linkable ? service.href : undefined}
									underline={"always"}
								>
									{service.label}
								</Link>
							)}
							<Link
								color="inherit"
								href={serviceAndCity.linkable ? serviceAndCity.href : undefined}
								underline={"always"}
							>
								{serviceAndCity.label}
							</Link>
						</Breadcrumbs>
					)}
				</Box>
			</Container>
		</Box>
	);
};
ProfileFooter.propTypes = {};
export default ProfileFooter;
