import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useStyles from "@hooks/useStyles";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { adjective as proAdjective } from "@language/provider";

const { BRAND_NAME } = process.env;

const ProfilePaymentMethods = ({ accepted_payment_types }) => {
	const hasPaymentMethods = !!accepted_payment_types.length;
	const classes = useStyles("Profile");

	return (
		<Box mb={6}>
			<Paper>
				<Box p={6}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pb={4}
					>
						<Box
							component={"div"}
							fontWeight={700}
							className={classes.profileHeader}
						>
							Payments
						</Box>
						{/* <Button
							component={Link}
							className={classes.profileEditButton}
							to={"/pros/profile/edit-info#payment-types"}
							size={"large"}
							color="primary"
						>
							Edit
						</Button> */}
					</Box>

					<Box
						display={"flex"}
						alignItems={"center"}
						flexWrap={{ xs: "wrap", sm: "nowrap" }}
					>
						<Box flex={"1 1 auto"} mr={{ xs: 0, sm: 6 }} mb={{ xs: 4, sm: 0 }}>
							<Box display={"flex"} alignItems={"flex-start"}>
								<Box
									mr={2}
									minWidth={40}
									display={"flex"}
									alignItems={"center"}
								>
									<PaymentIcon style={{ fontSize: 36 }} />
								</Box>
								<div>
									<Box fontWeight={700} mb={2}>
										Payment methods accepted
									</Box>
									<Box fontSize={14} color={"grey.700"} lineHeight={1.5}>
										{hasPaymentMethods ? (
											<>
												{accepted_payment_types.map((el, index, arr) => {
													return index !== arr.length - 1
														? el.ucFirst() + ", "
														: el.ucFirst();
												})}
											</>
										) : (
											<>
												{`Let customers know the types of payments you accept.`}
											</>
										)}
									</Box>
								</div>
							</Box>
						</Box>

						<Box
							width={{ xs: "100%", sm: "auto" }}
							display={"flex"}
							alignItems={"center"}
							height={"100%"}
							flexShrink={0}
							ml={{ xs: 12 }}
						>
							{hasPaymentMethods ? (
								<Button
									to={"/pros/profile/edit-info#payment-types"}
									component={Link}
									disableElevation
									size={"large"}
									color="primary"
									variant={"outlined"}
								>
									Edit
								</Button>
							) : (
								<Button
									to={"/pros/profile/edit-info#payment-types"}
									component={Link}
									disableElevation
									size={"large"}
									color="primary"
									variant={"contained"}
								>
									Add
								</Button>
							)}
						</Box>
					</Box>

					{/* <Box pt={4} pb={4}>
						<Divider light={true} />
					</Box>

					<Box
						display={"flex"}
						alignItems={"center"}
						flexWrap={{ xs: "wrap", sm: "nowrap" }}
					>
						<Box flex={"1 1 auto"} mr={{ xs: 0, sm: 6 }} mb={{ xs: 4, sm: 0 }}>
							<Box display={"flex"} alignItems={"flex-start"}>
								<Box
									mr={2}
									minWidth={40}
									display={"flex"}
									alignItems={"center"}
								>
									<AccountBalanceIcon style={{ fontSize: 36 }} />
								</Box>
								<div>
									<Box fontWeight={700} mb={2}>
										{`Get paid through ${BRAND_NAME}`}
									</Box>
									<Box fontSize={14} color={"grey.700"} lineHeight={1.5}>
										{`Request and receive payments from customers in the app. Learn more`}
									</Box>
								</div>
							</Box>
						</Box>

						<Box
							width={{ xs: "100%", sm: "auto" }}
							display={"flex"}
							alignItems={"center"}
							height={"100%"}
							flexShrink={0}
							ml={{ xs: 12 }}
						>
							<Button
								disableElevation
								size={"large"}
								color="primary"
								variant={"contained"}
							>
								Set up
							</Button>
						</Box>
					</Box> */}
				</Box>
			</Paper>
		</Box>
	);
};

ProfilePaymentMethods.propTypes = {};

export default ProfilePaymentMethods;
