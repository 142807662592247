import React, { useState } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Grid from "@material-ui/core/Grid";

const ProfileServicesOffered = ({ provider }) => {
	const classes = useStyles("Profile");
	const [open, setOpen] = useState(false);
	const handleClick = () => setOpen(!open);

	return (
		<React.Fragment>
			<a className="anchor" id="services" />

			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<h2 className={classes.profileH2}>Services offered</h2>

			<Box mt={6}>
				<Collapse in={open} collapsedSize={180} timeout="auto">
					<Grid container spacing={3}>
						{/*TODO: on click pop lead modal for that service and that pro*/}

						{provider.services.map((service, index) => {
							return (
								<Grid item xs={6} key={shortid.generate()}>
									<div
										className={`${classes.profileTextGrey} ${classes.profileServicesText}`}
									>
										{(service.name || "").ucFirst()}
									</div>
								</Grid>
							);
						})}
					</Grid>
				</Collapse>
			</Box>

			{provider.services && provider.services.length > 10 && (
				<Box display="flex" alignItems="center" mt={3}>
					<Button
						color="secondary"
						endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={handleClick}
					>
						{`Show ${open ? "less" : "more"}`}
					</Button>
				</Box>
			)}

			{/*<Box className={classes.serviceChips} mb={3} mt={2}>*/}

			{/*    {provider.services.map((service, index) => {*/}
			{/*        return (*/}

			{/*            <Chip variant="outlined" component="a" color="primary" size="small"*/}
			{/*                  label={(service.phrase || service.name || '').ucFirst()} href={`/${service.url}`}*/}
			{/*                  clickable className={`${index > 5 ? 'hidden' : ''}`} key={shortid.generate()}/>*/}

			{/*        );*/}
			{/*    })}*/}

			{/*</Box>*/}

			{/*{provider.services && provider.services.length > 5 &&*/}
			{/*<Button color="primary" endIcon={<ExpandMoreIcon/>}>*/}
			{/*    Show more*/}
			{/*</Button>}*/}
		</React.Fragment>
	);
};

ProfileServicesOffered.propTypes = {
	provider: PropTypes.shape({
		services: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				phrase: PropTypes.string,
				name: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
};

export default ProfileServicesOffered;
