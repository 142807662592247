import React, { useEffect, useState } from "react";
import ProTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";

// Libs
import withStores from "@libs/framework/src/libs/withStores";
import withStyles from "@libs/framework/src/libs/withStyles";

// Hooks
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import useSuccess from "@hooks/useSuccess";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// MUI
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";

// MUI Icons
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

// Components
import LoadingButton from "@components/Global/Custom/Button";

// Language
import { adjective as proAdjective } from "@language/provider";
import { styles, stores } from "./.config";
import LeadDetails from "@components/Global/Custom/LeadDetails";

const SendProject = compose(
	withStores(stores),
	withStyles(styles),
	observer
)(() => {
	const classes = useStyles("SendProject");
	const { sendProjectStore: store } = useStores();
	const [ErrorTemplate, onError] = useError({ _id: "SendProject" });
	const [SuccessTemplate, onSuccess] = useSuccess({ _id: "SendProject" });

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const sendProjectOnClickHandler = () =>
		store
			.sendProject()
			.then(() => {
				onSuccess({ message: "Project successfully sent!" });
				setTimeout(() => {
					store.onClose();
				}, 1500);
			})
			.catch(onError);

	return (
		<Dialog
			style={{ overflow: "hidden" }}
			scroll={"paper"}
			fullScreen={isMobile}
			fullWidth={isMobile}
			maxWidth={"md"}
			open={store.show}
			onClose={() => store.onClose()}
			aria-labelledby="Confirm send request"
			aria-describedby="Confirm send request"
		>
			<DialogTitle disableTypography className={classes.dialogTitleRoot}>
				<IconButton
					aria-label="close"
					className={classes.closeModal}
					onClick={() => store.onClose()}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent className={classes.dialogContent}>
				<Grid container style={{ height: "100%" }}>
					<Grid item xs={false} sm={1} />
					<Grid item xs={12} sm={10}>
						<Box
							component={"div"}
							height={"100%"}
							width={"100%"}
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							pb={10}
						>
							<Box className={classes.questionHeader} mb={8}>
								Confirm your request details
							</Box>
							<Box
								p={"24px 32px"}
								boxShadow={1}
								width={"100%"}
								borderRadius={8}
								height={"100%"}
							>
								<LeadDetails
									leadKey={store.leadKey}
									displayPriceEstimate={false}
									displayServiceName
								/>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>

			{/* <DialogContent className={classes.dialogContent}>
				<Grid container style={{ height: "100%" }}>
					<Grid item xs={false} sm={1} />
					<Grid item xs={12} sm={10}>
						<Box
							component={"div"}
							height={"100%"}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Box mb={4}>
								<Badge
									badgeContent={<CheckIcon style={{ fontSize: 12 }} />}
									color={"primary"}
								>
									<Avatar
										variant={"rounded"}
										alt="Pro Biz Name"
										src="https://static.fash.com/profile-pro/5cbf3968829cfc42f7ce046b_profile.jpg"
										className={classes.introAvatar}
									/>
								</Badge>
							</Box>
							<Box
								fontSize={16}
								fontWeight={700}
								lineHeight={1.6}
								textAlign={"center"}
							>
								Elena's Cleaning Services
							</Box>
							<div className={classes.questionHeaderBox}>
								<div className={classes.questionHeader}>
									You sent your project details to another{" "}
									{proAdjective.singular}!
								</div>
							</div>
						</Box>
					</Grid>
					<Grid item xs={false} sm={1} />
				</Grid>
			</DialogContent> */}

			<DialogActions className={classes.dialogActions}>
				<Box
					display={"flex"}
					minWidth={"50%"}
					flexDirection={"row-reverse"}
					className={classes.dialogActionsBox}
				>
					<Box flexGrow={1}>
						<LoadingButton
							disableElevation
							label={"Confirm"}
							fullWidth
							size={"large"}
							variant={"contained"}
							color="primary"
							onClick={sendProjectOnClickHandler}
						/>
					</Box>
				</Box>
			</DialogActions>
			<ErrorTemplate />
			<SuccessTemplate />
		</Dialog>
	);
});

SendProject.propTypes = {};

export default SendProject;
