import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useStyles from "@hooks/useStyles";

const ProfileBackground = (props) => {
	const classes = useStyles("Profile");

	return (
		<Container maxWidth="lg" disableGutters={true}>
			<main className={classes.backgroundMain}>
				<Grid container spacing={4}>
					{props.children}
				</Grid>
			</main>
		</Container>
	);
};

export default ProfileBackground;
