import React, { useEffect, useRef, useState } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

// Hooks
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useLoading from "@hooks/useLoading";
import useConstants from "@hooks/useConstants";

// MUI
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Linkify from "react-linkify";

// MUI Icons
//import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
//import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import LaunchIcon from "@material-ui/icons/Launch";
import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";

// Components
import ProfileLeadPanelPrices from "../ProfileLeadPanelPrices";
import QuickPickQuestion from "@components/Main/Shared/QuickPickQuestion";
import ZipcodeTextField from "@components/Global/Custom/TextField/types/ZipcodeTextField";
//import OnlinePulse from "@components/Global/Custom/OnlinePulse";
import Select from "@components/Global/Custom/Select";
//import MaterialSkeleton from "@material-ui/lab/Skeleton";
import { isBrowser, scrollTo } from "@utils/Browser";

const { SITE_NAME } = process.env;

// const LoadingTemplate = ({ boxProps = {}, skeletonProps = {} }) => (
// 	<Box {...boxProps}>
// 		<MaterialSkeleton
// 			variant="rect"
// 			style={{ borderRadius: 8 }}
// 			{...skeletonProps}
// 		/>
// 	</Box>
// );

const DefaultActionPanel = observer(({ onError }) => {
	const {
		profileStore,
		leadFunnelModalStore,
		profileLocationHoursStore,
		profileActionsPanelStore: store,
	} = useStores();
	const [hash] = useState(shortid.generate());
	const classes = useStyles("Profile");

	const { provider } = profileStore;

	const [LoadingTemplate, onLoading, loadingStore] = useLoading({
		_id: "DefaultActionPanelLoading" + hash,
		//Component: Skeleton,
	});
	const isLoading = loadingStore.show;
	const zipcodeRef = useRef();
	const startLeadHandler = (type) => (e) => {
		leadFunnelModalStore.leadActionType = type;
		leadFunnelModalStore.zipcode = zipcodeRef.current.value;
		leadFunnelModalStore.show = true;
	};

	const handleServiceSelectChange = (e) => {
		store.updateService(e.target.value);
	};

	const handleLinkClick = (type) => (e) => {
		window.analytics.track(`Client - Profile Interacted`, {
			provider_id: provider._id,
			location: "action_panel",
			type: type,
		});
	};

	useEffect(() => {
		onLoading(true);
		store
			.setQuickPickQuestions()
			.then(() => onLoading(false))
			.catch(onError);
	}, [store.service]);

	const {
		DefaultActionPanelButton,
		DefaultActionPanelType,
		showProfileWebsitePhone,
		showProfileLocationHours,
		showProfileActionsPanel,
	} = useConstants("ProfileViewModel");

	const shouldShowDropDown =
		!store.serviceKey && store.servicesForDropDown.length > 0;
	const shouldShow = store.service || store.serviceKey || shouldShowDropDown;

	return (
		<React.Fragment>
			<aside
				className={`${classes.leadPanelBox} ${classes.leadPanelBoxMobile}`}
			>
				{/* <LoadingTemplate
					boxProps={{ width: "50%" }}
					skeletonProps={{ height: 28 }}
				/> */}

				{!isLoading && <ProfileLeadPanelPrices />}

				<div className={classes.leadPanelBoxHeight}>
					<LoadingTemplate>
						<React.Fragment>
							<Box pt={4}>
								<Divider light={true} />
							</Box>
							{showProfileActionsPanel && (
								<>
									{shouldShowDropDown && (
										<Box mt={5} mb={3} width="100%">
											<Select
												disabled={isLoading}
												id={"service_select"}
												defaultValue={store.service?._id}
												onChange={handleServiceSelectChange}
											>
												{store.servicesForDropDown.map((service) => (
													<option value={service._id} key={service._id}>
														{service.name_proper?.ucFirst() ||
															service.name.ucFirst()}
													</option>
												))}
											</Select>
										</Box>
									)}

									{!shouldShowDropDown && !shouldShow && (
										<Box mt={5} mb={3} width="100%">
											This pro isn't taking new clients right now.
										</Box>
									)}

									{shouldShow && (
										<Box mt={store.serviceKey ? 5 : 3} mb={3} width="100%">
											<ZipcodeTextField
												disabled={isLoading}
												inputRef={zipcodeRef}
												variant={"outlined"}
												fullWidth
												id={"zip"}
												name="zip"
												defaultValue={store.zipcode}
												InputProps={{
													style: {
														fontWeight: "600",
													},
												}}
											/>
										</Box>
									)}

									{shouldShow &&
										store.quickPickQuestions.map((question) => (
											<QuickPickQuestion
												key={shortid.generate()}
												question={question}
											/>
										))}

									{shouldShow && (
										<>
											<Box pt={2}>
												{SITE_NAME === "homeguide" ? (
													<Button
														disabled={isLoading}
														onClick={startLeadHandler("requested_quote")}
														fullWidth
														disableElevation
														variant="contained"
														color="primary"
														size="large"
														className={classes.buttonCTA}
													>
														Request a quote
													</Button>
												) : (
													<Button
														disabled={isLoading}
														onClick={startLeadHandler("requested_availability")}
														fullWidth
														disableElevation
														variant="contained"
														color="primary"
														size="large"
														className={classes.buttonCTA}
													>
														Check availability
													</Button>
												)}
											</Box>
											{SITE_NAME === "trustedcare" && (
												<Box pt={3}>
													<Button
														disabled={isLoading}
														onClick={startLeadHandler("requested_quote")}
														fullWidth
														disableElevation
														variant="outlined"
														color="primary"
														size="large"
														className={classes.buttonCTA}
													>
														Request pricing
													</Button>
												</Box>
											)}
										</>
									)}
								</>
							)}
							{shouldShow && showProfileWebsitePhone ? (
								<Box mt={8}>
									<Grid container spacing={4}>
										{provider.company_website && (
											<Grid item xs={12}>
												<Box
													component={"div"}
													display={"flex"}
													alignItems={"center"}
												>
													<LaunchIcon className={classes.proDetailsIcon} />
													<Box component={"div"} ml={3}>
														<Linkify
															componentDecorator={(
																decoratedHref,
																decoratedText,
																key
															) => (
																<Link
																	target="blank"
																	color="primary"
																	rel={"noopener noreferrer"}
																	aria-label={`Visit website.`}
																	href={
																		decoratedHref +
																		`?utm_campaign=${SITE_NAME}&utm_source=${SITE_NAME}&utm_medium=${SITE_NAME}`
																	}
																	key={key}
																	onClick={handleLinkClick("visit_website")}
																>
																	{decoratedText}
																</Link>
															)}
														>
															{provider.company_website
																.replace("http://", "")
																.replace("https://", "")
																.replace("www.", "")}
														</Linkify>
													</Box>
												</Box>
											</Grid>
										)}

										{["vety"].includes(SITE_NAME) && (
											<Grid item xs={12}>
												<Box
													component={"div"}
													display={"flex"}
													alignItems={"center"}
												>
													<CallOutlinedIcon
														className={classes.proDetailsIcon}
													/>
													<Box component={"div"} ml={3}>
														<Link
															href={`tel:${provider.phone}`}
															color="primary"
															target="_blank"
															onClick={handleLinkClick("phone_call")}
														>
															{`(${provider.phone.slice(
																0,
																3
															)}) ${provider.phone.slice(
																3,
																6
															)}-${provider.phone.slice(6)}`}
														</Link>
													</Box>
												</Box>
											</Grid>
										)}
										{showProfileLocationHours && (
											<Grid item xs={12}>
												<Box
													component={"div"}
													display={"flex"}
													alignItems={"center"}
												>
													<DirectionsOutlinedIcon
														className={classes.proDetailsIcon}
													/>
													<Box
														component={"div"}
														display={"flex"}
														ml={3}
														flexDirection={"column"}
													>
														<Link
															href={`${profileLocationHoursStore.directionsMapUrl}`}
															color="primary"
															target="_blank"
															onClick={handleLinkClick("get_directions")}
														>
															Get directions
														</Link>
														<Box component={"div"} fontSize={14}>
															{`${
																profileLocationHoursStore.proLocation.address
															} ${
																profileLocationHoursStore.proLocation.address2
															} ${profileLocationHoursStore.proLocation.city?.ucFirst()}, ${profileLocationHoursStore.proLocation.state?.toUpperCase()} ${
																profileLocationHoursStore.proLocation.zip
															}`}
														</Box>
													</Box>
												</Box>
											</Grid>
										)}
									</Grid>
								</Box>
							) : (
								<Box
									disabled={isLoading}
									fontSize={14}
									mt={5}
									align="center"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									{/* if provider.online */}
									{/* <div className={classes.profileChip}>
						<OnlinePulse />
						<Box component="span" fontWeight={600} fontSize={14}>
							Online now
						</Box>
					</div> */}
									{/* end if */}
									{/* if responds.in */}
									{/* <SmsOutlinedIcon />
					<Box ml={2}>
						Responds in about{" "}
						<Box component="span" fontWeight={600}>
							3 hours
						</Box>
					</Box> */}
									{/* end if */}
									It's free, with no obligation to hire.
								</Box>
							)}
						</React.Fragment>
					</LoadingTemplate>
				</div>

				{/* {isBrowser && isLoading && (
					<LoadingTemplate
						boxProps={{ width: "100%", mt: 3 }}
						skeletonProps={{ height: 56 }}
					/>
				)} */}
				{/* <LoadingTemplate
					boxProps={{ width: "100%", mt: 4 }}
					skeletonProps={{ height: 56 }}
				/> */}
			</aside>

			<Box mb={4} display={{ xs: "block", sm: "none" }}>
				<Divider light={true} />
			</Box>
		</React.Fragment>
	);
});

DefaultActionPanel.propTypes = {};

export default DefaultActionPanel;
