const req = require.context("./", true, /\.(styles)\.(js)$/);
const paths = req.keys().filter((path) => !!~path.indexOf("/@styles/"));

export default paths.reduce(
	(result, path) =>
		Object.assign(
			{},
			result,
			req(path).default
				? {
						[`${path.split("/").pop().replace(".styles.js", "")}`]:
							req(path).default,
				  }
				: {}
		),
	{}
);
