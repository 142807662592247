import loadable from "@loadable/component";
import React, { useEffect } from "react";

import useStaticContext from "@hooks/useStaticContext";
import useConstants from "@hooks/useConstants";

import Background from "../../Shared/Background/Background";

import ProfileHeadCarousel from "./ProfileHeadCarousel";
import ProfileDetails from "./ProfileDetails";
import ProfileServiceDetails from "./ProfileServiceDetails";
import ProfileCredentials from "./ProfileCredentials";
import ProfileMedia from "./ProfileMedia";
import ProfileSocialMedia from "./ProfileSocialMedia";
import ProfilePaymentMethods from "./ProfilePaymentMethods";
import ProfileIntro from "./ProfileIntro";
import ProfileReviews from "./ProfileReviews";
import ProfileTravelPreferences from "./ProfileTravelPreferences";
import ProfileBusinessHours from "./ProfileBusinessHours";
import ProfileFAQ from "./ProfileFAQ";
import ReviewsDialog from "../../Shared/ReviewsDialog/ReviewsDialog";
import ImageDialog from "@components/Global/Custom/ImageDialog";

import useStyles from "@hooks/useStyles";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";
import styles from "@components/Dashboard/Pro/Funnel/.styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import useSuccess from "@libs/framework/src/hooks/useSuccess";
import { useHistory, useLocation } from "react-router";
import ProfileCredentialsDialog from "./ProfileCredentialsDialog";
import ProfileInsurance from "./ProfileInsurance";
import ProfileHeader from "./ProfileHeader";

const Profile = compose(withStyles(styles))(() => {
	const classes = useStyles("Profile");
	const [SuccessTemplate, onSuccess] = useSuccess({ _id: "Profile" });
	const location = useLocation();
	const history = useHistory();
	const { provider, proServiceConfigurations, proBusinessHours, reviews } =
		useStaticContext();

	const { ProfileFAQViewModel } = useConstants();

	useEffect(() => {
		if (!!location.state && location.state.profileEdited) {
			onSuccess({ message: "Profile edited!" });
			history.replace({
				state: { ...location.state, profileEdited: undefined },
			});
		}

		return () => {};
	}, [location]);
	return (
		<React.Fragment>
			<Background>
				{/*<Header/>*/}
				<SuccessTemplate
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					autoHideDuration={2000}
				/>

				<Box
					component={"div"}
					className={classes.profileWrapper}
					bgcolor={"#fcfcfc"}
				>
					<Container
						maxWidth={"md"}
						disableGutters
						//style={{ maxWidth: 750 }}
						className={classes.profileContainer}
					>
						<ProfileHeader {...provider} />
						{/* <ProfileHeadCarousel /> */}
						<ProfileDetails {...provider} />
						<ProfileIntro {...provider} />
						{/* <ProfileCredentials /> */}
						{/* <ProfileCredentialsDialog /> */}
						<ProfileMedia {...provider} />
						<ProfileServiceDetails
							proServiceConfigurations={proServiceConfigurations}
						/>
						{/* <ProfileInsurance {...provider} /> */}

						{provider.qa?.length > 0 && (
							<ProfileFAQ {...ProfileFAQViewModel} {...provider} />
						)}
						<ProfileBusinessHours {...proBusinessHours} />

						<ProfilePaymentMethods {...provider} />

						<ProfileReviews reviews={reviews} />

						<ProfileSocialMedia {...provider} />

						{/*<ProfileTravelPreferences proServiceConfigurations={proServiceConfigurations}/>*/}

						<ReviewsDialog />
						<ImageDialog />
					</Container>
				</Box>
			</Background>
		</React.Fragment>
	);
});

Profile.propTypes = {};

export default Profile;
export const loader = (route) =>
	loadable(() => import("./Profile.component"), { ssr: !!route.ssr });
