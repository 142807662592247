import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStores from "@hooks/useStores";
import { observer } from "mobx-react";

const ProfileLocationHoursDialog = observer(() => {
	const classes = useStyles("Profile");
	const { profileLocationHoursDialogStore: store } = useStores();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const onCloseHandler = () => store.onClose();

	return (
		<Dialog
			scroll="paper"
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"sm"}
			open={store.show}
			onClose={onCloseHandler}
			aria-labelledby="editorial integrity"
			aria-describedby="editorial integrity"
		>
			<DialogTitle disableTypography style={{ minHeight: 50 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton aria-label="close" onClick={onCloseHandler}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box mb={12} mx={12}>
					<Box display="flex" alignItems="center" pb={8}>
						<Box fontWeight={700} lineHeight={1.4} fontSize={22}>
							Business hours
						</Box>
					</Box>

					<Box display={"flex"} justifyContent={"space-between"}>
						<Box display={"flex"} flexDirection={"column"} width={110}>
							{store.dayList.map((day, index) => (
								<div key={`day-${index}`} className={classes.hoursDialogDay}>
									{index === 0 ? <strong>{day}</strong> : day}
								</div>
							))}
						</Box>
						<Box display={"flex"} justifyContent={"space-between"} flexGrow={1}>
							<Box display={"flex"} flexDirection={"column"} width={175}>
								{store.dayListHours.map((hourRange, index) => (
									<div
										key={`hours-${index}`}
										className={classes.hoursDialogTime}
									>
										{index === 0 ? (
											<strong>
												<div dangerouslySetInnerHTML={{ __html: hourRange }} />
											</strong>
										) : (
											<div dangerouslySetInnerHTML={{ __html: hourRange }} />
										)}
									</div>
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
});

ProfileLocationHoursDialog.propTypes = {};
export default ProfileLocationHoursDialog;
