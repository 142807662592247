import React, { useState } from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";

const LeftArrowComponent = ({ onClick }) => {
	return (
		<div className="mr16">
			<a onClick={onClick}>
				<i className="fa fa-chevron-left gray-300" />
			</a>
		</div>
	);
};

LeftArrowComponent.propTypes = {
	onClick: PropTypes.func.isRequired,
};

const RightArrowComponent = ({ onClick }) => {
	return (
		<div>
			<a onClick={onClick}>
				<i className="fa fa-chevron-right gray-300" />
			</a>
		</div>
	);
};

RightArrowComponent.propTypes = {
	onClick: PropTypes.func.isRequired,
};

const CarouselItem = ({ active, direction, component }) => {
	return (
		<div hidden={!active}>
			<Slide
				timeout={{ enter: 100, exit: 0 }}
				in={active}
				direction={direction}
			>
				<div>{component}</div>
			</Slide>
		</div>
	);
};

CarouselItem.propTypes = {
	active: PropTypes.bool.isRequired,
	direction: PropTypes.oneOf(["right", "left"]).isRequired,
	component: PropTypes.node.isRequired,
};

const Carousel = ({
	groupLimit,
	className,
	leftArrowComponent: LeftArrowComponent,
	leftArrowOnClick,
	rightArrowComponent: RightArrowComponent,
	rightArrowOnClick,
	direction,
	infiniteLoop,
	startAt,
	children,
}) => {
	const items = React.Children.toArray(children);
	const batchesOf = Math.ceil(items.length / groupLimit);

	const groupedItems = [...Array(batchesOf)]
		.map((_, multiplier) =>
			items.slice(multiplier * groupLimit, (multiplier + 1) * groupLimit)
		)
		.filter((array) => array.length > 0);

	const iterable = groupLimit === 1 ? items : groupedItems;
	const [itemsProps, setItemsProps] = useState(
		iterable.map((_, index) => ({
			active: index === startAt,
			direction,
		}))
	);
	const currentIndex = itemsProps.findIndex((props) => props.active === true);

	const nextItem = () => {
		if (rightArrowOnClick) rightArrowOnClick();
		const currentIndex = itemsProps.findIndex((props) => props.active === true);
		if (currentIndex === -1) return;
		if (!infiniteLoop && currentIndex === itemsProps.length - 1) return;
		itemsProps[currentIndex] = Object.assign({}, itemsProps[currentIndex], {
			direction: "right",
			active: false,
		});
		if (!iterable[currentIndex + 1]) {
			itemsProps[0] = Object.assign({}, itemsProps[0], {
				direction: "right",
				active: true,
			});
			setItemsProps([...itemsProps]);
			return;
		}
		itemsProps[currentIndex + 1] = Object.assign(
			{},
			itemsProps[currentIndex + 1],
			{
				direction: "right",
				active: true,
			}
		);
		setItemsProps([...itemsProps]);
	};

	const prevItem = () => {
		if (leftArrowOnClick) leftArrowOnClick();
		const currentIndex = itemsProps.findIndex((props) => props.active === true);
		if (currentIndex === -1) return;
		if (!infiniteLoop && currentIndex === 0) return;
		itemsProps[currentIndex] = Object.assign({}, itemsProps[currentIndex], {
			direction: "left",
			active: false,
		});
		if (currentIndex === 0) {
			itemsProps[itemsProps.length - 1] = Object.assign(
				{},
				itemsProps[itemsProps.length - 1],
				{
					direction: "left",
					active: true,
				}
			);
			setItemsProps([...itemsProps]);
			return;
		}
		itemsProps[currentIndex - 1] = Object.assign(
			{},
			itemsProps[currentIndex - 1],
			{
				direction: "left",
				active: true,
			}
		);
		setItemsProps([...itemsProps]);
	};

	return (
		<div className={className}>
			{!infiniteLoop && currentIndex > 0 ? (
				<LeftArrowComponent onClick={prevItem} />
			) : null}
			{iterable.map((node, index) =>
				Array.isArray(node) ? (
					node.map((n) => (
						<CarouselItem
							key={shortid.generate()}
							active={itemsProps[index].active}
							direction={itemsProps[index].direction}
							component={n}
						/>
					))
				) : (
					<CarouselItem
						key={shortid.generate()}
						active={itemsProps[index].active}
						direction={itemsProps[index].direction}
						component={node}
					/>
				)
			)}
			{!infiniteLoop && currentIndex < iterable.length - 1 ? (
				<RightArrowComponent onClick={nextItem} />
			) : null}
		</div>
	);
};

Carousel.defaultProps = {
	groupLimit: 1,
	direction: "right",
	className: "flex justify-between width-100",
	rightArrowComponent: RightArrowComponent,
	leftArrowComponent: LeftArrowComponent,
	infiniteLoop: true,
	startAt: 0,
};

Carousel.propTypes = {
	groupLimit: PropTypes.number,
	className: PropTypes.string.isRequired,
	leftArrowOnClick: PropTypes.func,
	leftArrowComponent: PropTypes.func.isRequired,
	rightArrowOnClick: PropTypes.func,
	rightArrowComponent: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	direction: PropTypes.string.isRequired,
	infiniteLoop: PropTypes.bool.isRequired,
	startAt: PropTypes.number.isRequired,
};

export default Carousel;
