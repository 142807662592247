export const quoteFragment = `
key
accepted
client_phone
cost {
	question {
		parent_questionMongoID
	}
	answer {
		parent_answerMongoID
	}
	amount
}
final_price {
	unit
	total
}
paid {
	value
}
type
phone_clicked_by_pro
phone_clicked_by_client
provider {
	services {
		name
	}
}
`;

export const leadFragment = `
_id
created_at
boundary_base64_w420_h140
location {
	city
	state
	zip
}
answers {
	question
	description
	values
	answers
	subHeader
	sensitive
}
service {
	key
	name_proper
	name
}
`;
