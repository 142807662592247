import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";

import { Divider, Box, Button, Paper } from "@material-ui/core";

const ProfileInsurance = ({ provider }) => {
	const classes = useStyles("Profile");

	return (
		<React.Fragment>
			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<a className="anchor" id="location" />
			<Box my={6} component="section">
				<h2 className={classes.profileH2}>
					Accepted insurance & payment options
				</h2>
				<p className={classes.profileBodyText}>
					Insurance and self-pay is accepted as payment for visits and services
					at this clinic.
				</p>
				<div className={classes.profileBodyText}>
					While {provider.company_name.ucFirst()} partners with the below
					insurances, we recommend giving your insurance provider a call to
					confirm coverage and benefits.
				</div>

				<Box my={12}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.profileCredential}>
								<CheckIcon className={classes.profileCredentialIcon} />
								<div
									className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
								>
									Aetna
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.profileCredential}>
								<CheckIcon className={classes.profileCredentialIcon} />
								<div
									className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
								>
									Blue Cross Blue Shield
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.profileCredential}>
								<CheckIcon className={classes.profileCredentialIcon} />
								<div
									className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
								>
									Medicare
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.profileCredential}>
								<CheckIcon className={classes.profileCredentialIcon} />
								<div
									className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
								>
									TRICARE
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<div className={classes.profileCredential}>
								<CheckIcon className={classes.profileCredentialIcon} />
								<div
									className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
								>
									United Healthcare
								</div>
							</div>
						</Grid>
					</Grid>
				</Box>

				<div>
					<Box component={"h3"} fontWeight={600} fontSize={18} m={0} pb={3}>
						Self-pay
					</Box>
					<div className={classes.profileBodyText}>
						This clinic accepts payment without insurance. Self-pay prices
						include an office visit charge unless noted. For more details, call
						the clinic.
					</div>
				</div>
			</Box>
		</React.Fragment>
	);
};

ProfileInsurance.propTypes = {};

export default ProfileInsurance;
