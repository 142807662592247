import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

// MUI
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

// MUI Icons
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import StarIcon from "@material-ui/icons/Star";

// Language
import { adjective as proAdjective } from "@language/provider";

// Components
import ProfileLeadPanelPrices from "../ProfileLeadPanelPrices";

const DirectActionPanel = () => {
	const classes = useStyles("ProfileActionPanel");
	const { profileActionsPanelStore: store } = useStores();
	return (
		<aside className={`${classes.leadPanelBox}`}>
			<ProfileLeadPanelPrices />

			<Box py={4}>
				<Divider light={true} />
			</Box>

			<Box display={"flex"} alignItems={"flex-start"}>
				<div>
					<Box fontWeight={600} display={"flex"} alignItems={"center"}>
						{store.quote.last_message ? (
							"Latest message:"
						) : (
							<React.Fragment>
								<ScheduleIcon style={{ marginRight: 8 }} />
								{`Waiting on ${proAdjective.singular} to respond.`}
							</React.Fragment>
						)}
					</Box>
					{store.quote.last_message && (
						<Box
							className={`${classes.profileBodyText} ${classes.profileActionsText}`}
							mt={2}
						>
							{store.quote.last_message.from_client ? (
								<>
									<span style={{ fontWeight: "bold" }}>You: </span>{" "}
									{store.quote.last_message.message}
								</>
							) : (
								store.quote.last_message.message
							)}
						</Box>
					)}
				</div>
			</Box>

			{store.quote.last_message && (
				<Box pt={6}>
					<Button
						href={`/client/inbox/${store.leadKey}/${store.quote.key}`}
						fullWidth
						disableElevation
						variant="contained"
						color="primary"
						size="large"
						className={classes.buttonCTA}
						startIcon={<SmsOutlinedIcon />}
					>
						{`Message ${proAdjective.singular}`}
					</Button>
				</Box>
			)}

			{store.showReviewButton && (
				<React.Fragment>
					<Box py={6}>
						<Divider light={true} />
					</Box>
					<Box display={"flex"} alignItems={"flex-start"}>
						<Box mr={2}>
							<StarIcon className={classes.iconStar} />
						</Box>
						<Box fontWeight={700} fontSize={18} lineHeight={1.3}>
							{`How was working with this ${proAdjective.singular}?`}
						</Box>
					</Box>
					<Box mt={4}>
						<Button
							href={`/providers/${store.provider.key}/review?quote=${store.quote._id}`}
							target={"_blank"}
							fullWidth
							disableElevation
							variant="outlined"
							color="primary"
							size="large"
							className={classes.buttonCTA}
						>
							Write a review
						</Button>
					</Box>
				</React.Fragment>
			)}
		</aside>
	);
};

export default DirectActionPanel;
