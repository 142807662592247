import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";

// Utils
import UrlFormater from "@src/libs/UrlFormater";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

// MUI Icons
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ComputerOutlinedIcon from "@material-ui/icons/ComputerOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

// Components
import YelpIcon from "@components/Global/Custom/Icons/YelpIcon";
import GoogleIcon from "@components/Global/Custom/Icons/GoogleIcon";
import useStores from "@hooks/useStores";

// Config

const ProfileCredentials = ({ provider }) => {
	const { profileStore: store } = useStores();

	const classes = useStyles("Profile");
	const today = new Date();
	const founded = parseInt(provider.company_founded);
	const yearsActive = isNaN(founded) ? 0 : today.getFullYear() - founded;

	const [open, setOpen] = useState(true);
	const handleClick = () => setOpen(!open);
	const handleLinkClick = (description) => (e) => {
		window.analytics.track(`Client - Profile Interacted`, {
			provider_id: provider._id,
			location: "credentials",
			type: "social_media",
			description: description,
		});
	};

	const hasPaymentTypes = provider.accepted_payment_types.length > 0;
	const hasSocialMedia = Object.values(provider.social).some((el) => !!el);

	return (
		<React.Fragment>
			<div className={classes.profileDivider}>
				<Divider light={true} />
			</div>

			<Collapse id={"credentials"} in={open} collapsedSize={124} timeout="auto">
				<Box component="section" pb={4}>
					<h2 className={classes.profileH2}>Highlights</h2>

					<Box mt={6}>
						<Grid container spacing={3}>
							{provider.verified.badge && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<StarsIcon className={classes.profileCredentialIcon} />
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											Current Top Pro
										</div>
									</div>
								</Grid>
							)}

							{provider.metrics.hires > 0 && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<TrendingUpIcon className={classes.profileCredentialIcon} />
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											{`Hired ${provider.metrics.hires} time${
												provider.metrics.hires > 1 ? "s" : ""
											}`}
										</div>
									</div>
								</Grid>
							)}

							{provider.company_employees > 0 && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<PeopleAltOutlinedIcon
											className={classes.profileCredentialIcon}
										/>
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											{`${provider.company_employees} employee${
												provider.company_employees > 1 ? "s" : ""
											}`}
										</div>
									</div>
								</Grid>
							)}

							{provider.verified.background && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<VerifiedUserOutlinedIcon
											className={classes.profileCredentialIcon}
										/>
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											Background checked
										</div>
									</div>
								</Grid>
							)}

							{yearsActive > 0 && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<ScheduleIcon className={classes.profileCredentialIcon} />
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											{`${yearsActive} year${
												yearsActive > 1 ? "s" : ""
											} in business`}
										</div>
									</div>
								</Grid>
							)}

							<Grid item xs={12} sm={12} md={6}>
								<div className={classes.profileCredential}>
									<RoomOutlinedIcon className={classes.profileCredentialIcon} />
									<div
										className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
									>
										{`Serves ${store.serveLocation}`}
									</div>
								</div>
							</Grid>

							{provider.award.online && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<ComputerOutlinedIcon
											className={classes.profileCredentialIcon}
										/>
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											Offers online services
										</div>
									</div>
								</Grid>
							)}

							{/* 			{provider.verified.email && provider.verified.phone && (
								<Grid item xs={12} sm={12} md={6}>
									<div className={classes.profileCredential}>
										<LibraryAddCheckOutlinedIcon
											className={classes.profileCredentialIcon}
										/>
										<div
											className={`${classes.profileBodyText} ${classes.profileCredentialText}`}
										>
											Email and phone verified
										</div>
									</div>
								</Grid>
							)} */}
						</Grid>
					</Box>

					{(hasPaymentTypes || hasSocialMedia) && (
						<Box pt={8}>
							<Grid container spacing={2}>
								{hasPaymentTypes && (
									<Grid item xs={12} sm={12} md={6}>
										<Box component="h3" className={classes.profileH3} mt={0}>
											Payment methods
										</Box>

										<div className={classes.profileBodyText}>
											{provider.accepted_payment_types.map((el, index, arr) => {
												if (el === "cc") el = "Credit Card";
												return index !== arr.length - 1
													? el.ucFirst() + ", "
													: el.ucFirst();
											})}
										</div>
									</Grid>
								)}
								{hasSocialMedia && (
									<Grid item xs={12} sm={12} md={6}>
										<Box
											component="h3"
											className={classes.profileH3}
											mt={{ sm: 6, md: 0 }}
										>
											Social media
										</Box>

										<Box
											display={"flex"}
											alignItems={"center"}
											flexWrap={"wrap"}
											pr={{ xs: 0, sm: 4 }}
										>
											{provider.social.facebook && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(
															provider.social.facebook
														)}
														target={"_blank"}
														onClick={handleLinkClick("facebook")}
													>
														<FacebookIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.twitter && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(provider.social.twitter)}
														target={"_blank"}
														onClick={handleLinkClick("twitter")}
													>
														<TwitterIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.instagram && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(
															provider.social.instagram
														)}
														target={"_blank"}
														onClick={handleLinkClick("instagram")}
													>
														<InstagramIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.youtube && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(provider.social.youtube)}
														target={"_blank"}
														onClick={handleLinkClick("youTube")}
													>
														<YouTubeIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.linkedin && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(
															provider.social.linkedin
														)}
														target={"_blank"}
														onClick={handleLinkClick("linkedIn")}
													>
														<LinkedInIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.yelp && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(provider.social.yelp)}
														target={"_blank"}
														onClick={handleLinkClick("yelp")}
													>
														<YelpIcon />
													</IconButton>
												</Box>
											)}
											{provider.social.google && (
												<Box mr={3} mb={3}>
													<IconButton
														size="small"
														href={UrlFormater.withHttp(provider.social.google)}
														target={"_blank"}
														onClick={handleLinkClick("google")}
													>
														<GoogleIcon color={false} />
													</IconButton>
												</Box>
											)}
										</Box>
									</Grid>
								)}
							</Grid>
						</Box>
					)}
				</Box>
			</Collapse>

			{!open && (
				<Box display="flex" alignItems="center" mt={3}>
					<Button
						color="primary"
						endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={handleClick}
					>
						View all details
					</Button>
				</Box>
			)}
		</React.Fragment>
	);
};

ProfileCredentials.propTypes = {
	provider: PropTypes.shape({
		company_founded: PropTypes.number,
		company_employees: PropTypes.number,
		social: PropTypes.shape({
			yelp: PropTypes.string,
			google: PropTypes.string,
			youtube: PropTypes.string,
			facebook: PropTypes.string,
			instagram: PropTypes.string,
			linkedin: PropTypes.string,
			twitter: PropTypes.string,
		}),
		metrics: PropTypes.shape({
			hires: PropTypes.number,
		}),
		accepted_payment_types: PropTypes.arrayOf(PropTypes.string),
		location: PropTypes.shape({
			city: PropTypes.string,
			state: PropTypes.string,
		}),
		business_location: PropTypes.shape({
			city: PropTypes.string,
			state: PropTypes.string,
		}),
		award: PropTypes.shape({
			online: PropTypes.string,
		}),
		verified: PropTypes.shape({
			background: PropTypes.bool,
			email: PropTypes.bool,
			phone: PropTypes.bool,
			badge: PropTypes.bool,
		}).isRequired,
	}).isRequired,
};

export default ProfileCredentials;
