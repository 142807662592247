import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

// MUI
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

// MUI Icons
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import StarIcon from "@material-ui/icons/Star";

// Language
import { adjective as proAdjective } from "@language/provider";

// Config
const { BRAND_NAME } = process.env;

const ProfileBadges = ({}) => {
	const classes = useStyles("Profile");
	const { profileStore } = useStores();
	const { provider } = profileStore;

	return (
		<Box display={"flex"} justifyContent="flex-start" flexWrap="wrap" mt={1}>
			{provider.metrics.reviews_count === 0 && (
				<div className={classes.profileChip}>
					<Tooltip
						enterDelay={400}
						title={`This ${proAdjective.singular} just joined ${BRAND_NAME}. Reach out and give them a shot!`}
						arrow
						TransitionComponent={Zoom}
						placement={"top"}
					>
						<Chip
							size="small"
							icon={<StarIcon className={classes.iconGreen} />}
							label="New"
							className={classes.chipGreen}
						/>
					</Tooltip>
				</div>
			)}

			{/* <div className={classes.profileChip}>
				<Tooltip
					enterDelay={400}
					title={`This ${proAdjective.singular} offers discounts. Reach out to learn more.`}
					arrow
					TransitionComponent={Zoom}
					placement={"top"}
				>
					<Chip
						size="small"
						icon={<AttachMoneyIcon className={classes.iconGreen} />}
						label="Discounts available"
						className={classes.chipGreen}
					/>
				</Tooltip>
			</div> */}

			{provider.award.top_pro && provider.metrics.reviews_count > 0 && (
				<div className={classes.profileChip}>
					<Tooltip
						enterDelay={400}
						title={`Top Pros are the highest-rated ${proAdjective.plural} on ${BRAND_NAME}.`}
						arrow
						TransitionComponent={Zoom}
						placement={"top"}
					>
						<Chip
							size="small"
							icon={<StarsIcon className={classes.iconGreen} />}
							label="Top Pro"
							className={classes.chipGreen}
						/>
					</Tooltip>
				</div>
			)}

			{/* <div className={classes.profileChip}>
				<Tooltip
					enterDelay={400}
					title={`Lots of people are contacting this ${proAdjective.singular}, so message them soon!`}
					arrow
					TransitionComponent={Zoom}
					placement={"top"}
				>
					<Chip
						size="small"
						icon={<EmojiEventsOutlinedIcon className={classes.iconBlue} />}
						label="In high demand"
						className={classes.chipBlue}
					/>
				</Tooltip>
			</div> */}

			{provider.award.online && (
				<div className={classes.profileChip}>
					<Tooltip
						enterDelay={400}
						title={`Connect with this ${proAdjective.singular} face-to-face over online video.`}
						arrow
						TransitionComponent={Zoom}
						placement={"top"}
					>
						<Chip
							size="small"
							icon={<VideocamOutlinedIcon className={classes.iconPurple} />}
							label="Offers online services"
							className={classes.chipPurple}
						/>
					</Tooltip>
				</div>
			)}
		</Box>
	);
};

ProfileBadges.defaultProps = {};

ProfileBadges.propTypes = {};

export default ProfileBadges;
