import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useConstants from "@hooks/useConstants";

// MUI
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";

const ProfileNav = ({}) => {
	const classes = useStyles("Profile");
	const {
		profileStore,
		profilePhotosStore,
		profileResumeStore,
		profileLocationHoursStore,
		profileLicenseStore,
	} = useStores();
	const { showProfileLocationHours } = useConstants("ProfileViewModel");

	return (
		<React.Fragment>
			<Hidden only={"xs"}>
				<Divider light={true} />
			</Hidden>

			<a className="anchor" id="about" />

			<Box
				component={"nav"}
				height={65}
				display={"flex"}
				alignItems="center"
				justifyContent={"flex-start"}
				className={classes.profileNav}
			>
				<Link color="secondary" href="#about">
					About
				</Link>
				{profileStore.shouldShowDetails && (
					<Link color="secondary" href="#credentials">
						Details
					</Link>
				)}
				{!!profilePhotosStore.media.length && (
					<Link color="secondary" href="#photos">
						Photos
					</Link>
				)}
				{showProfileLocationHours && profileLocationHoursStore.proLocation && (
					<Link color="secondary" href="#location">
						Location & Hours
					</Link>
				)}
				<Link color="secondary" href="#reviews">
					Reviews
				</Link>
				{profileResumeStore.hasResumes && (
					<Link color="secondary" href="#resume">
						Resume
					</Link>
				)}
				{profileLicenseStore.hasLicenses && (
					<Link color="secondary" href="#license">
						License
					</Link>
				)}
				{!!profileStore.provider.qa?.length && (
					<Link color="secondary" href="#faq">
						FAQs
					</Link>
				)}
				<Link color="secondary" href="#services">
					Services
				</Link>
			</Box>
		</React.Fragment>
	);
};

ProfileNav.propTypes = {};

export default ProfileNav;
