import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

import useStores from "@hooks/useStores";

//import ReviewsDialog from "../../Shared/ReviewsDialog/ReviewsDialog";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "@hooks/useStyles";
import Rating from "@material-ui/lab/Rating";
import Link from "@material-ui/core/Link";

import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import Divider from "@material-ui/core/Divider";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import UrlFormater from "@src/libs/UrlFormater";
import PhoneNumberFormatter from "@src/libs/PhoneNumberFormater";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const ProfileDetails = ({
	first_name,
	last_name,
	company_phone,
	phone,
	social,
	company_employees,
	company_founded,
	company_name,
	company_website,
	business_location,
	image,
	metrics,
}) => {
	const classes = useStyles("Profile");
	const stores = useStores();

	const openDialog = () => {
		stores.reviewsDialogStore.showModal = true;
	};

	return (
		<React.Fragment>
			<Paper>
				<Box mb={6} overflow={"hidden"}>
					<Box p={6}>
						<Box position={"relative"}>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={{ xs: "center", sm: "flex-start" }}
								pb={8}
							>
								<Box
									component={"div"}
									display={{ xs: "block", sm: "flex" }}
									textAlign={"center"}
									//alignItems={"center"}
								>
									<Box
										mr={{ sm: 4 }}
										flexShrink={0}
										flexGrow={0}
										display={"inline-block"}
									>
										<Avatar
											variant={"rounded"}
											alt={company_name}
											src={image.value}
											className={classes.profileMainPicture}
										/>
									</Box>
									<Box
										textAlign={{ xs: "center", sm: "left" }}
										overflow={"hidden"}
										flex={"1 1 auto"}
										minWidth={0}
										minHeight={0}
									>
										<Box
											component={"h1"}
											fontWeight={600}
											fontSize={{ xs: 22, sm: 24 }}
											style={{ wordBreak: "break-all" }}
											mb={2}
											mt={{ xs: 2, sm: 0 }}
										>
											{company_name}
										</Box>

										<Box
											display="flex"
											alignItems="center"
											fontSize={14}
											mb={2}
										>
											<Box
												component="span"
												mr={1}
												color="#ff6474"
												fontWeight={800}
											>
												{metrics.reviews_avg.toFixed(1)}
											</Box>
											<Rating
												name="half-rating-read"
												value={metrics.reviews_avg}
												precision={0.5}
												readOnly
												size="small"
											/>
											<Box component="span" ml={1} color="text.secondary">
												{`(${metrics.reviews_count})`}
											</Box>
											<Box
												component="span"
												ml={2}
												fontWeight={600}
												color="text.secondary"
											>
												<Link
													style={{ cursor: "pointer" }}
													onClick={openDialog}
												>
													Ask for reviews
												</Link>
											</Box>
										</Box>
										<Box fontSize={14} color={"text.secondary"}>
											Hired {metrics.hires} time
											{metrics.hires.length !== 1 && "s"}
										</Box>
									</Box>
								</Box>
							</Box>
							<Box position={"absolute"} top={0} right={0}>
								<Button
									className={classes.profileEditButton}
									component={RouterLink}
									to={"/pros/profile/edit-info"}
									//size={"large"}
									color="primary"
								>
									Edit
								</Button>
							</Box>
							<div>
								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<PhoneOutlinedIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Mobile phone
											</Box>
											<div>
												{phone ? (
													<div>{PhoneNumberFormatter.format(phone)}</div>
												) : (
													<Box fontSize={14} color={"grey.700"}>
														Not answered
													</Box>
												)}
											</div>
										</Box>
									</Box>

									{!phone ? (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									) : (
										<>
											<Box display={"flex"} alignItems={"center"}>
												<LockOutlinedIcon fontSize={"small"} />
												<Box ml={1} fontSize={12}>
													Hidden from customers.
												</Box>
											</Box>
										</>
									)}
								</Box>

								<Box pt={4} pb={4}>
									<Divider light={true} />
								</Box>

								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<PhoneOutlinedIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Business phone
											</Box>
											<div>
												{company_phone ? (
													<div>
														{PhoneNumberFormatter.format(company_phone)}
													</div>
												) : (
													<Box fontSize={14} color={"grey.700"}>
														Not answered
													</Box>
												)}
											</div>
										</Box>
									</Box>
									{!company_phone ? (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									) : (
										<>
											<Box display={"flex"} alignItems={"center"}>
												<LockOutlinedIcon fontSize={"small"} />
												<Box ml={1} fontSize={12}>
													Hidden from customers.
												</Box>
											</Box>
										</>
									)}
								</Box>

								<Box pt={4} pb={4}>
									<Divider light={true} />
								</Box>

								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<WebOutlinedIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Website
											</Box>
											<div>
												{company_website ? (
													<Link
														href={UrlFormater.withHttp(company_website)}
														target={"_blank"}
													>
														{company_website}
													</Link>
												) : (
													<Box fontSize={14} color={"grey.700"}>
														Not answered
													</Box>
												)}
											</div>
										</Box>
									</Box>
									{!social.website && (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									)}
								</Box>

								<Box pt={4} pb={4}>
									<Divider light={true} />
								</Box>

								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<LocationOnOutlinedIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Business address
											</Box>

											{business_location.address ? (
												<div>
													<div>
														{business_location.address.ucFirst()},{" "}
														{business_location.address2.ucFirst()}
													</div>
													<div>
														{business_location.city.ucFirst()},{" "}
														{business_location.state.toUpperCase()}{" "}
														{business_location.zip}
													</div>
												</div>
											) : (
												<Box fontSize={14} color={"grey.700"}>
													Not answered
												</Box>
											)}
										</Box>
									</Box>
									{!business_location.address && (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									)}
								</Box>

								<Box pt={4} pb={4}>
									<Divider light={true} />
								</Box>

								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<ScheduleIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Year founded
											</Box>
											<div>
												{company_founded ? (
													<div>{company_founded}</div>
												) : (
													<Box fontSize={14} color={"grey.700"}>
														Not answered
													</Box>
												)}
											</div>
										</Box>
									</Box>
									{!company_founded && (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									)}
								</Box>

								<Box pt={4} pb={4}>
									<Divider light={true} />
								</Box>

								<Box
									display={"flex"}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box display={"flex"} alignItems={"flex-start"}>
										<PeopleAltOutlinedIcon fontSize={"small"} />
										<Box ml={2}>
											<Box fontWeight={600} mb={1}>
												Number of employees
											</Box>
											<div>
												{company_employees ? (
													<div>{company_employees}</div>
												) : (
													<Box fontSize={14} color={"grey.700"}>
														Not answered
													</Box>
												)}
											</div>
										</Box>
									</Box>
									{!company_employees && (
										<div>
											<Button
												component={RouterLink}
												disableElevation
												to={"/pros/profile/edit-info"}
												//size={"large"}
												color="primary"
												variant={"contained"}
											>
												Add
											</Button>
										</div>
									)}
								</Box>
							</div>
						</Box>
					</Box>
				</Box>
			</Paper>
		</React.Fragment>
	);
};

ProfileDetails.propTypes = {
	phone: PropTypes.string,
	first_name: PropTypes.string,
	last_name: PropTypes.string,
	company_name: PropTypes.string,
	company_employees: PropTypes.number,
	company_founded: PropTypes.number,
	image: PropTypes.shape({
		value: PropTypes.string,
	}),
	social: PropTypes.shape({
		website: PropTypes.string,
	}),
	business_location: PropTypes.shape({
		city: PropTypes.string,
		state: PropTypes.string,
		address: PropTypes.string,
		address2: PropTypes.string,
	}),
	metrics: PropTypes.shape({
		hires: PropTypes.number,
		reviews_avg: PropTypes.number,
		reviews_count: PropTypes.number,
	}),
};

export default ProfileDetails;
