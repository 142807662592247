import React from "react";
import PropTypes from "prop-types";

// Hooks
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

// MUI
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Tooltip";

// MUI Icons
import CloseIcon from "@material-ui/icons/Close";

// Language
import { adjective as proAdjective } from "@language/provider";

const DeclinedActionPanel = () => {
	const classes = useStyles("ProfileActionPanel");
	const { profileActionsPanelStore: store } = useStores();

	return (
		<aside className={`${classes.leadPanelBox}`}>
			<Box>
				<Tooltip
					enterDelay={400}
					title={`This ${proAdjective.singular} is not available. Reach out to other ${proAdjective.plural}`}
					arrow
					TransitionComponent={Zoom}
					placement={"top"}
				>
					<Chip
						size="small"
						icon={<CloseIcon className={classes.iconRed} />}
						label="Not available"
						className={classes.chipRed}
					/>
				</Tooltip>
			</Box>

			<Box py={4}>
				<Divider light={true} />
			</Box>

			<Box display={"flex"} alignItems={"flex-start"}>
				<div>
					<Box fontWeight={600} display={"flex"} alignItems={"center"}>
						{`This ${proAdjective.singular} is unavailable`}
					</Box>
				</div>
			</Box>

			<Box py={6}>
				<Divider light={true} />
			</Box>

			<Box>
				<Button
					href={`/client/projects/${store.leadKey}`}
					fullWidth
					disableElevation
					variant="contained"
					color="primary"
					size="large"
					className={classes.buttonCTA}
				>
					{`See other ${proAdjective.plural}`}
				</Button>
			</Box>
		</aside>
	);
};

export default DeclinedActionPanel;
