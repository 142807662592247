import React, { useEffect, useCallback } from "react";

import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

import TextField from "@components/Global/Custom/TextField";
import LoadingButton from "@components/Global/Custom/Button";

import { observer } from "mobx-react-lite";
import useStores from "@hooks/useStores";

import ReviewItem from "@components/Global/Custom/ReviewItem";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import Star from "@material-ui/icons/Star";
import useStyles from "@hooks/useStyles";
import Rating from "@material-ui/lab/Rating";
import Divider from "@material-ui/core/Divider";
import useLoading from "@hooks/useLoading";

const ReviewsDialog = observer(() => {
	const theme = useTheme();
	const classes = useStyles("Profile");
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const [LoadingTemplate, onLoading] = useLoading({
		show: true,
		_id: "reviewsList",
	});
	const stores = useStores();

	const {
		profileReviewsListStore: store,
		profileReviewsStore,
		reviewsMetricsStore,
		profileStore,
	} = stores;

	const handleLoadMore = async () => {
		await store.loadMore(profileStore.provider._id);
	};

	const init = useCallback(async () => {
		store._isLoading = true;
		await store.init({
			providerId: profileStore.provider._id,
			limit: 10,
		});
		store._isLoading = false;
	}, [store.ModalStore.show]);

	useEffect(() => {
		if (store.ModalStore.show) init();
	}, [init]);

	useEffect(() => {
		onLoading(store.isLoading);
	});

	return (
		<Dialog
			scroll={"paper"}
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={"md"}
			open={store.show}
			onClose={() => {
				store.onClose(true);
			}}
			aria-labelledby="Reviews list"
			aria-describedby="Reviews list"
		>
			<DialogTitle disableTypography style={{ minHeight: 60 }}>
				<Box position={"absolute"} top={6} right={6}>
					<IconButton
						aria-label="close"
						onClick={() => {
							store.onClose(true);
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent style={{ minHeight: "25vh" }}>
				<LoadingTemplate>
					<Box mb={5}>
						<Grid container spacing={6}>
							<Grid item xs={12} sm={12} md={4}>
								<Box
									fontSize={26}
									fontWeight={700}
									display="flex"
									flexDirection="row"
									alignItems="center"
								>
									<Box mr={2} display={"flex"}>
										<Rating
											name="half-rating-read"
											value={1}
											precision={0.5}
											max={1}
											readOnly
											size="large"
										/>
									</Box>
									{!!reviewsMetricsStore && reviewsMetricsStore.avgRating} (
									{profileReviewsStore.reviewsCount} reviews)
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={8}>
								{/* <Grid item xs={12}>
								<Box mb={3}>
									<TextField
										type={"text"}
										variant="outlined"
										fullWidth
										autoCorrect="off"
										id="search-reviews"
										placeholder="Search reviews..."
										InputProps={{
											autoCorrect: "off",
											spellCheck: "false",
											startAdornment: (
												<InputAdornment position="start">
													<SearchOutlined />
												</InputAdornment>
											),
										}}
										aria-label="Search for reviews"
										size={"small"}
										disableOnBlurValidation
									/>
								</Box>
							</Grid> */}

								{store.reviews.map((review, index, arr) => (
									<React.Fragment key={review._id}>
										<ReviewItem
											key={review._id}
											review={review}
											showReplyButton={false}
										/>
										{index !== arr.length - 1 && (
											<div className={classes.reviewDivider}>
												<Divider light={true} />
											</div>
										)}
									</React.Fragment>
								))}

								{store.hasMore && (
									<Box mt={12} display={"flex"} justifyContent={"center"}>
										<LoadingButton
											disableElevation
											size={"large"}
											variant={"outlined"}
											color={"default"}
											onClick={handleLoadMore}
											label={"See more"}
											style={{ backgroundColor: "#FFF" }}
										/>
									</Box>
								)}
							</Grid>
						</Grid>
					</Box>
				</LoadingTemplate>
			</DialogContent>
		</Dialog>
	);
});

ReviewsDialog.propTypes = {};
export default ReviewsDialog;
