import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import useStyles from "@hooks/useStyles";
import Divider from "@material-ui/core/Divider";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ProfileQA = ({ provider }) => {
	const classes = useStyles("Profile");

	return (
		<React.Fragment>
			<a className="anchor" id="faq" />

			{provider.qa?.length > 0 && provider.qa?.some((el) => !!el.answer) && (
				<React.Fragment>
					<div className={classes.profileDivider}>
						<Divider light={true} />
					</div>

					<a className="anchor" id="qa" />
					<Box mt={8} component="section">
						<h2 className={classes.profileH2}>Frequently asked questions</h2>

						<Box mt={8}>
							{provider.qa
								?.filter((qa) => !!qa.answer)
								.reverse()
								.map((qa) => {
									return (
										<React.Fragment key={shortid.generate()}>
											{/* <div className={classes.profileQABox}>
											<h3 className={classes.profileH3}>{qa.question}</h3>

											<div
												className={`${classes.profileBodyText} ${classes.profileTextGrey}`}
											>
												<span dangerouslySetInnerHTML={{ __html: qa.answer }} />
											</div>
										</div> */}
											<Accordion classes={{ root: classes.accordionRoot }}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon color="primary" />}
													aria-controls={shortid.generate()}
													id={shortid.generate()}
													classes={{
														root: classes.accordionSRoot,
														content: classes.accordionSContent,
													}}
												>
													<h3 className={classes.faqQuestion}>{qa.question}</h3>
												</AccordionSummary>
												<AccordionDetails
													classes={{ root: classes.accordionDetailsRoot }}
												>
													<div className={classes.faqAnswer}>
														<div
															dangerouslySetInnerHTML={{
																__html: qa.answer,
															}}
														/>
													</div>
												</AccordionDetails>
											</Accordion>
										</React.Fragment>
									);
								})}
						</Box>
					</Box>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

ProfileQA.propTypes = {
	provider: PropTypes.shape({
		qa: PropTypes.arrayOf(
			PropTypes.shape({
				question: PropTypes.string,
				answer: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
};

export default ProfileQA;
